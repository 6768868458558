import React, { useContext, useState, useEffect } from "react";
import CobrosContext from "../../context/cobros/cobrosContext";
import Loading from "../Loading";
import ModalDetalle from "./ModalDetalle";

function ResultadosRecibos() {
  const cobrosContext = useContext(CobrosContext);
  const {
    recibosPagadosCliente,
    facturasPendientesCliente,
    formatDate,
    formatImporte,
    nombreClienteRecibos,
    selectPersonaRecibos,
    selectPersonaFacturas,
    loadingInternoRecibos,
    modalDetalle,
    setModalDetalle,
  } = cobrosContext;

  useEffect(() => {}, [recibosPagadosCliente, facturasPendientesCliente]);

  const [selectedRecibos, setSelectedRecibos] = useState("PAGOS");
  const [datosDetalle, setDatosDetalle] = useState([]);

  useEffect(() => {
    if (datosDetalle.length > 0) {
      setModalDetalle(true);
    }
  }, [datosDetalle]);

  const calcularVencimiento = (fechaVencimiento) => {
    var ahora = new Date();
    var fecha = new Date(fechaVencimiento);
    if (ahora > fecha) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow-lg mb-3">
      <div className="xl:flex xl:justify-between xl:items-center mb-4">
        <div>
          <h1 className="text-xl font-bold text-gray-800">
            {nombreClienteRecibos.nombre}
          </h1>
          <p className="text-lg border border-blue-500 rounded py-1 px-2">
            Email de Comunicación:{" "}
            <b>
              {nombreClienteRecibos.contacto !== null
                ? nombreClienteRecibos.contacto
                : "No Posee"}
            </b>
          </p>
          <div className="flex flex-col lg:flex-row mb-2 mt-2">
            <button
              style={{ width: 122.31 }}
              className={
                selectedRecibos === "PAGOS"
                  ? "bg-green-400 text-white rounded py-1 mb-3 lg:mb-0 lg:mr-2"
                  : "bg-mainBlueLight text-white rounded py-1 mb-3 lg:mb-0 lg:mr-2"
              }
              onClick={() => {
                setSelectedRecibos("PAGOS");
                selectPersonaRecibos(nombreClienteRecibos.dni, true);
              }}
            >
              PAGADOS
            </button>
            <button
              style={{ width: 122.31 }}
              className={
                selectedRecibos === "PENDIENTES"
                  ? "bg-green-400 text-white rounded py-1 mb-3 lg:mb-0 lg:mr-2"
                  : "bg-mainBlueLight text-white rounded py-1 mb-3 lg:mb-0 lg:mr-2"
              }
              onClick={() => {
                setSelectedRecibos("PENDIENTES");
                selectPersonaFacturas(nombreClienteRecibos.dni);
                //getHomeData();
              }}
            >
              PENDIENTES
            </button>
          </div>
          {loadingInternoRecibos ? <Loading color="mainBlueLight" /> : null}
          {!loadingInternoRecibos ? (
            <p className="text-sm text-gray-500 mb-4 xl:mb-0">
              {selectedRecibos === "PAGOS"
                ? "Recibos Pagados"
                : "Facturas Pendientes"}
            </p>
          ) : null}
        </div>
      </div>
      {selectedRecibos === "PAGOS"
        ? recibosPagadosCliente.length > 0
          ? recibosPagadosCliente.map((r) => (
              <div
                className={
                  r.observaciones.toUpperCase().includes("ANULADO")
                    ? "border rounded  p-3 mb-3 lg:grid lg:grid-cols-6 lg:gap-4 border-red-400"
                    : "border rounded p-3 mb-3 lg:grid lg:grid-cols-6 lg:gap-4 border-green-400"
                }
                key={r.id}
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">NRO.</p>
                  <p className="text-sm text-gray-500">{r.numero}</p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">
                    PUNTO DE COBRO
                  </p>
                  <p className="text-sm text-gray-500">{r.puntoDeCobro}</p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                  <p className="text-sm text-gray-500">
                    {formatImporte(r.importe)}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">FECHA</p>
                  <p className="text-sm text-gray-500">
                    {formatDate(r.fecha) + " " + r.hora + "hs"}
                  </p>
                </div>
                <div className="lg:m-0 lg:border-r mb-2">
                  <p className="text-sm font-bold text-gray-800">ESTADO</p>
                  <p className="text-sm text-white">
                    {r.observaciones.toUpperCase().includes("ANULADO") ? (
                      <span className="px-4 py-0 bg-red-400 rounded">
                        Anulado
                      </span>
                    ) : (
                      <span className="px-4 py-0 bg-green-400 rounded">
                        Pagado
                      </span>
                    )}
                  </p>
                </div>
                <div className="lg:m-0 mb-2 flex lg:justify-center items-center">
                  <button
                    className="bg-mainBlueLight text-white rounded px-8 font-semibold tracking-wide py-1 hover:bg-blue-600 transition duration-300"
                    onClick={() => {
                      setDatosDetalle(r.facturas);
                    }}
                  >
                    Ver Detalle
                  </button>
                </div>
              </div>
            ))
          : null
        : null}
      {selectedRecibos === "PENDIENTES" ? (
        facturasPendientesCliente.length > 0 ? (
          <div>
            <div className="flex items-end justify-end">
              <span className="py-2 text-center bg-red-400 rounded border border-gray-600 mt-1 mb-2 w-full md:w-2/12 text-white flex flex-col justify-center">
                <p className="antialiased">MONTO ADEUDADO</p>
                <p className="font-bold">
                  {formatImporte(
                    facturasPendientesCliente
                      .map((factura) => factura.saldo)
                      .reduce((acc, factura) => factura + acc)
                  )}
                </p>
              </span>
            </div>
            <div>
              {facturasPendientesCliente.map((f) => (
                <div
                  className={
                    calcularVencimiento(f.fechavencimiento1)
                      ? "border rounded  p-3 mb-3 lg:grid lg:grid-cols-5 lg:gap-4 border-red-400"
                      : "border rounded p-3 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
                  }
                  key={f.id}
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      FECHA DE EMISIÓN
                    </p>
                    <p className="text-sm text-gray-500">
                      {formatDate(f.fecha)}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      DESCRIPCIÓN
                    </p>
                    <p className="text-sm text-gray-500">{f.descripcion}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      VENCIMIENTO
                    </p>
                    <p className="text-sm text-gray-500">
                      {formatDate(f.fechavencimiento1)}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">TOTAL</p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(f.importe)}
                    </p>
                  </div>
                  <div className="lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">SALDO</p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(f.saldo)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null
      ) : null}
      {modalDetalle ? (
        <ModalDetalle data={datosDetalle} setDatosDetalle={setDatosDetalle} />
      ) : null}
    </div>
  );
}

export default ResultadosRecibos;
