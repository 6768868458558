import { useContext } from "react";
import CobrosContext from "../../context/cobros/cobrosContext";

function ModalDetalle(props) {
  const cobrosContext = useContext(CobrosContext);
  const { formatImporte, setModalDetalle } = cobrosContext;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 my-8 lg:my-1 sm:p-6">
            <div className="flex justify-center">
              <svg
                aria-hidden="true"
                role="img"
                className="iconify iconify--tablerh-11 w-11 mb-1 text-blue-500"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <g
                  className="icon-tabler"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 21V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16l-3-2l-2 2l-2-2l-2 2l-2-2l-3 2"></path>
                  <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H10m2 0v1.5m0-9V8"></path>
                </g>
              </svg>
            </div>
            <div>
              <h2 className="font-bold text-xl text-center">
                Detalle del Pago
              </h2>
              <div className="flex flex-col justify-center mt-6 w-full">
                {props.data && props.data.length > 0
                  ? props.data.map((pago) => (
                      <div
                        className="border rounded p-3 mb-3 lg:grid lg:grid-cols-3 lg:gap-4 w-full"
                        key={
                          pago.descripcion +
                          pago.importe +
                          Math.floor(Math.random() * 9999).toString()
                        }
                      >
                        <div className="lg:border-r lg:m-0 mb-2">
                          <p className="text-sm font-bold text-gray-800">
                            DESCRIPCIÓN
                          </p>
                          <p className="text-sm text-gray-500">
                            {pago.descripcion}
                          </p>
                        </div>
                        <div className="lg:m-0 lg:border-r mb-2">
                          <p className="text-sm font-bold text-gray-800">
                            IMPORTE
                          </p>
                          <p className="text-sm text-gray-500">
                            {formatImporte(pago.importe)}
                          </p>
                        </div>
                        <div className="lg:m-0 mb-2">
                          <p className="text-sm font-bold text-gray-800">
                            TIPO DE PAGO
                          </p>
                          <div className="flex flex-row gap-2">
                            <p className="text-sm text-gray-500 mt-0.5">
                              {pago.pagoTotal === 1 ? (
                                <span className="py-0.5 px-2 rounded bg-green-400 text-white font-medium">
                                  TOTAL
                                </span>
                              ) : (
                                <span className="py-0.5 px-2 rounded bg-yellow-400 text-white font-medium">
                                  PARCIAL
                                </span>
                              )}
                            </p>
                            {parseFloat(pago.importe) === 0 ? (
                              <p className="text-sm text-gray-500 mt-0.5">
                                {" "}
                                <span className="py-0.5 px-2 rounded bg-red-400 text-white font-medium">
                                  ANULADO
                                </span>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
          <div className="bg-gray-100 flex justify-center p-4">
            <button
              className="px-4 py-1 font-bold text-white bg-red-400 transition duration-200 hover:bg-red-500 rounded flex items-center lg:mb-0 focus:outline-none"
              onClick={() => {
                props.setDatosDetalle([]);
                setModalDetalle(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDetalle;
