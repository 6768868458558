import React, { useReducer, useState, useContext } from "react";
import empresasContext from "./empresasContext";
import empresasReducer from "./empresasReducer";
import AuthContext from "../../auth/authContext";
import AlertContext from "../../alerts/alertContext";
import axiosClient from "../../../config/axios";

const EmpresasState = (props) => {
  const initialState = {};
  const [state, dispatch] = useReducer(empresasReducer, initialState);

  const authContext = useContext(AuthContext);
  const { header, logoutUser } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [mainLoading, setMainLoading] = useState(true);
  const [empresas, setEmpresas] = useState([]);
  const [loadingEmpresas, setLoadingEmpresas] = useState(true);
  const [mostrarModalAlta, setMostrarModalAlta] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [mostrarModalInfo, setMostrarModalInfo] = useState(false);
  const [mostrarModalEstado, setMostrarModalEstado] = useState(false);
  const [idEmpresa, setIdEmpresa] = useState(null);

  const resetState = () => {
    setMainLoading(true);
    setEmpresas([]);
    setLoadingEmpresas(true);
    setMostrarModalAlta(false);
    setMostrarModalEditar(false);
    setIdEmpresa(null);
  };

  const checkSelf = async () => {
    resetState();
    await axiosClient
      .get("v2/key/self", header)
      .then((response) => {
        if (
          response.data.data.grupos.some((roles) => roles === "ADMINISTRADOR")
        ) {
          setMainLoading(false);
        } else {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        resetState();
        logoutUser();
        console.log(err);
      });
  };

  const getEmpresa = async () => {
    await axiosClient
      .post("datos_empresa", { id: idEmpresa }, header)
      .then((response) => {
        console.log(response);
        return response.data.data;
      });
  };

  const getEmpresas = async () => {
    setLoadingEmpresas(true);
    await axiosClient
      .get("lista_empresas", header)
      .then((response) => {
        setEmpresas(response.data.data);
        setLoadingEmpresas(false);
      })
      .catch((err) => {
        resetState();
        console.log(err);
        logoutUser();
      });
  };

  const altaEmpresa = async (data) => {
    await axiosClient
      .post("alta_empresa", data, header)
      .then((response) => {
        if (response.data.error === false) {
          setAlert("Empresa dada de alta exitosamente", "green-300");
          setMostrarModalAlta(false);
          getEmpresas();
        } else {
          setAlert("Hubo un error dando de alta la empresa", "red-300");
          setMostrarModalAlta(false);
        }
      })
      .catch((err) => {
        console.log(err);
        resetState();
        logoutUser();
      });
  };

  const editarEmpresa = async (data) => {
    await axiosClient
      .post("modificar_empresa", data, header)
      .then((response) => {
        if (response.data.error === false) {
          setAlert("Empresa modificada exitosamente", "green-300");
          setIdEmpresa(null);
          setMostrarModalEditar(false);
          getEmpresas();
        } else {
          setAlert("Hubo un error modificando la empresa", "red-300");
          setMostrarModalEditar(false);
          setIdEmpresa(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resetState();
        logoutUser();
      });
  };

  const cambiarEstado = async (id) => {
    await axiosClient
      .post("cambiar_estado_empresa", { idEmpresa: id }, header)
      .then((response) => {
        if (response.data.error === false) {
          setAlert(
            "Se modificó el estado de la empresa exitosamente",
            "green-300"
          );
          setIdEmpresa(null);
          setMostrarModalEstado(false);
          getEmpresas();
        } else {
          setAlert(
            "Hubo un error modificando el estado de la empresa",
            "red-300"
          );
          setIdEmpresa(null);
          setMostrarModalEstado(false);
        }
      })
      .catch((err) => {
        console.log(err);
        resetState();
        logoutUser();
      });
  };

  return (
    <empresasContext.Provider
      value={{
        mainLoading,
        setMainLoading,
        empresas,
        setEmpresas,
        loadingEmpresas,
        setLoadingEmpresas,
        checkSelf,
        getEmpresas,
        mostrarModalAlta,
        setMostrarModalAlta,
        altaEmpresa,
        getEmpresa,
        mostrarModalEditar,
        setMostrarModalEditar,
        editarEmpresa,
        idEmpresa,
        setIdEmpresa,
        mostrarModalInfo,
        setMostrarModalInfo,
        cambiarEstado,
        mostrarModalEstado,
        setMostrarModalEstado,
      }}
    >
      {props.children}
    </empresasContext.Provider>
  );
};

export default EmpresasState;
