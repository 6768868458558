import React from "react";

import AuthState from "./context/auth/authState";
import AlertState from "./context/alerts/alertState";
import CobrosState from "./context/cobros/cobrosState";
import DepositosState from "./context/depositos/depositosState";
import ArchivosState from "./context/archivos/archivosState";
import AdminState from "./context/admin/usuarios/adminState";
import AdminEmpresasState from "./context/admin/empresas/empresasState";

import Routes from "./Routes";

function App() {
  return (
    <AlertState>
      <AuthState>
        <CobrosState>
          <DepositosState>
            <ArchivosState>
              <AdminState>
                <AdminEmpresasState>
                  <Routes />
                </AdminEmpresasState>
              </AdminState>
            </ArchivosState>
          </DepositosState>
        </CobrosState>
      </AuthState>
    </AlertState>
  );
}

export default App;
