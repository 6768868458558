import React, { useContext, useEffect } from "react";
import CobrosContext from "../../context/cobros/cobrosContext";

function ResultadoPersona() {
  const cobrosContext = useContext(CobrosContext);
  const {
    resultadoPersona,
    selectPersona,
    tipoBusqueda,
    selectPersonaRecibos,
    setNombreClienteRecibos,
    nombreClienteRecibos,
  } = cobrosContext;

  return (
    <div className="p-4 bg-white rounded shadow-lg mb-3">
      <h1 className="text-xl font-bold text-gray-800">Resultado de Búsqueda</h1>
      <p className="text-sm text-gray-500 mb-8">
        Seleccionar al socio correspondiente
      </p>
      {resultadoPersona.length > 0 &&
        resultadoPersona.map((p) => (
          <div
            className="border rounded p-4 mb-3 lg:grid lg:grid-cols-4 cursor-pointer lg:gap-4 hover:border-blue-500 transition ease-in-out duration-300"
            key={p.nroDoc}
            onClick={() => {
              if (tipoBusqueda === "cobros") {
                selectPersona(p.nroDoc);
              } else {
                var newData = { ...nombreClienteRecibos };
                newData.nombre = p.nombre;
                newData.dni = p.nroDoc;
                newData.contacto = p.contacto;
                setNombreClienteRecibos(newData);
                selectPersonaRecibos(p.nroDoc, null, p.contacto);
              }
            }}
          >
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">NOMBRE</p>
              <p className="text-sm text-gray-500">{p.nombre}</p>
            </div>
            <div>
              <p className="text-sm font-bold text-gray-800">DOCUMENTO</p>
              <p className="text-sm text-gray-500">{p.nroDoc}</p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ResultadoPersona;
