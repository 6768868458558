import React, { useEffect, useState, useContext } from "react";
import Loading from "../../../Loading";
import axiosClient from "../../../../config/axios";
import AdminContext from "../../../../context/admin/usuarios/adminContext";
import AuthContext from "../../../../context/auth/authContext";

function AsociadoA() {
  const adminContext = useContext(AdminContext);
  const { datosUsuario, setDatosUsuario } = adminContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const [loading, setLoading] = useState(true);
  const [opciones, setOpciones] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const updateAsociado = (e) => {
    if (e.target.value === "0") {
      setSelectedIndex(e.target.value);
      var newDatosUsuario = { ...datosUsuario };
      newDatosUsuario.asociadoANombre =
        e.target.options[e.target.selectedIndex].text;
      newDatosUsuario.asociadoA = "";
      setDatosUsuario(newDatosUsuario);
    } else {
      setSelectedIndex(e.target.value);
      var newDatosUsuario = { ...datosUsuario };
      newDatosUsuario.asociadoANombre =
        e.target.options[e.target.selectedIndex].text;
      newDatosUsuario.asociadoA = e.target.value;
      setDatosUsuario(newDatosUsuario);
    }
  };

  useEffect(() => {
    if (datosUsuario.tipoUsuario === "EMPRESA") {
      axiosClient
        .get("lista_empresas", header)
        .then((response) => {
          var array = response.data.data;
          array.unshift({ id: 0, nombre: "Seleccionar" });
          setOpciones(array);
          if (datosUsuario.asociadoA !== "") {
            setSelectedIndex(datosUsuario.asociadoA);
          }
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      axiosClient
        .get("lista_puntos_cobros", header)
        .then((response) => {
          var array = response.data.data;
          array.unshift({ id: 0, nombre: "Seleccionar" });
          setOpciones(array);
          if (datosUsuario.asociadoA !== "") {
            setSelectedIndex(datosUsuario.asociadoA);
          }
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <div>
      <div className="flex justify-center mt-2 lg:-mt-2">
        <p className="font-bold text-xl">
          Elegí{" "}
          {datosUsuario.tipoUsuario === "EMPRESA"
            ? "la empresa"
            : "el punto de cobro"}
        </p>
      </div>
      <div className="flex justify-center py-12">
        {loading ? (
          <Loading color="mainBlueLight" />
        ) : (
          <div className="flex flex-wrap flex-col">
            <select
              onChange={(e) => updateAsociado(e)}
              className="rounded border border-gray-400 border-4 w-full pr-10"
              value={selectedIndex}
            >
              {opciones.length > 0
                ? opciones.map((o) => <option value={o.id}>{o.nombre}</option>)
                : null}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

export default AsociadoA;
