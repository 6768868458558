import React, { useContext, useState, useEffect } from "react";
import EmpresasContext from "../../../context/admin/empresas/empresasContext";
import Loading from "../../Loading";

function ModalInfo() {
  const empresasContext = useContext(EmpresasContext);
  const { setMostrarModalInfo, idEmpresa, setIdEmpresa, empresas } =
    empresasContext;

  const [empresa, setEmpresa] = useState(false);

  useEffect(() => {
    var empresaActual = empresas.filter((emp) => emp.id === idEmpresa)[0];
    setEmpresa(empresaActual);
  }, []);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {empresa ? (
            <div>
              <div className="bg-white px-4 my-3 sm:p-6 sm:pb-4">
                <div className="flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 mb-1 text-mainBlueLight"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div>
                  <h2 className="font-bold text-xl text-center">
                    Estás viendo la información de la Empresa
                  </h2>
                  <h3 className="font-bold text-xl text-center text-mainBlueLight">
                    {empresa.nombre}
                  </h3>
                </div>
              </div>
              <div className="bg-gray-100 rounded grid-cols-1 p-4 m-4 lg:mt-0">
                <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="ml-1 text-white subpixel-antialiased">
                    Tipo de Documento
                  </p>
                </div>
                <p className="ml-7 font-medium mb-2 text-gray-600">
                  {empresa.tipoDoc}
                </p>
                <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="ml-1 text-white subpixel-antialiased">
                    Número de Documento
                  </p>
                </div>
                <p className="ml-7 font-medium mb-2 text-gray-600">
                  {empresa.nroDoc}
                </p>
                <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="ml-1 text-white subpixel-antialiased">
                    Dirección
                  </p>
                </div>
                <p className="ml-7 font-medium mb-2 text-gray-600">
                  {empresa.direccion}
                </p>
                <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="ml-1 text-white subpixel-antialiased">
                    Descripción
                  </p>
                </div>
                <p className="ml-7 font-medium mb-2 text-gray-600">
                  {empresa.descripcion}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Loading color="mainBlueLight" />
            </div>
          )}

          <div className="bg-gray-50 flex justify-between p-4">
            <button
              type="button"
              className="px-4 py-1font-bold text-white bg-mainBlueLight rounded flex items-center lg:mb-0"
              onClick={() => {
                setIdEmpresa(null);
                setMostrarModalInfo(false);
              }}
            >
              Entendido
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalInfo;
