import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import DepositosContext from "../../context/depositos/depositosContext";

function ImgUploader() {
  const depositosContext = useContext(DepositosContext);
  const { setInputs, inputs, setImageError, getImagesName } = depositosContext;

  useEffect(() => {
    checkImageData();
    getImagesName();
  }, [inputs]);

  useEffect(() => {
    setInputs([{ title: uuidv4(), file: "" }]);
  }, []);

  //funcion para corroborar que ningun campo de imagen esté vacio.
  const checkImageData = () => {
    var exists = Object.keys(inputs).some(function (k) {
      if (inputs[k].file === "" || inputs[k].file === null) {
        setImageError(true);
      } else {
        setImageError(false);
      }
    });
  };

  const handleChange = (e, index) => {
    const { name, files } = e.target;
    //clono el state con todos los inputs
    const list = [...inputs];
    //elijo con el index el que quiero cambiar
    list[index][name] = files[0];
    //agrego el objeto con los cambios al array
    setInputs(list);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { title: uuidv4(), file: "" }]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputs];
    list.splice(index, 1);
    setInputs(list);
  };

  return (
    <div>
      <label htmlFor="entrega" className="uppercase font-bold text-xs mb-2">
        Comprobantes de depósito
      </label>
      {inputs.map((item, index) => (
        <div key={index} className="border rounded bg-gray-200 my-2 p-4">
          {inputs.length !== 1 ? (
            <div className="flex justify-end mb-4">
              <button
                className="bg-red-500 rounded-lg p-2 font-bold text-sm text-white"
                onClick={() => handleRemoveInput(index)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          ) : null}
          {!item.file ? (
            <label className="w-full flex flex-col items-center px-4 py-6 text-white bg-mainBlueLight rounded-lg shadow-lg cursor-pointer">
              <svg
                className="w-8 h-8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>
              <span className="mt-2 font-bold">Seleccionar Imagen</span>
              <input
                type="file"
                name="file"
                className="hidden"
                onChange={(e) => handleChange(e, index)}
              />
            </label>
          ) : (
            <div className="w-full flex justify-between items-center px-4 py-4 text-white bg-green-500 rounded-lg shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-8 h-8 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              {item.file.name}
            </div>
          )}
        </div>
      ))}
      <div>
        <button
          onClick={handleAddInput}
          className="bg-mainBlueLight rounded px-4 py-1 text-white font-bold flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5 mr-3"
          >
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z"
              clipRule="evenodd"
            />
          </svg>
          Nuevo Comprobante
        </button>
      </div>
    </div>
  );
}

export default ImgUploader;
