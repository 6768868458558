import React, { Fragment, useState, useContext, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alerts/alertContext";
import { useHistory } from "react-router-dom";

import cardinalLogo from "../../assets/cardinal-full-white.svg";

function Login() {
  const authContext = useContext(AuthContext);
  const { loginUser, auth, getUser, loadingLogin, solicitarResetPassword } =
    authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  let history = useHistory();

  useEffect(() => {
    getUser();
    if (auth) {
      history.push("/");
    }
  }, [auth]);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [loadingReset, setLoadingReset] = useState(false);

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (user.email.trim() === "") {
      setAlert("Ingresar Email", "red-300");
      return;
    }
    if (user.password.trim() === "") {
      setAlert("Ingresar Contraseña", "red-300");
      return;
    }
    loginUser(user);
  };

  const solicitarReset = () => {
    if (user.email.trim() === "") {
      setAlert(
        "Por favor, ingresá tu email para solicitar la recuperación de tu contraseña",
        "red-300"
      );
      return;
    }
    setLoadingReset(true);
    solicitarResetPassword(user.email).then(() => setLoadingReset(false));
  };

  return (
    <Fragment>
      <div className="bg-mainBlue flex login">
        <div className="flex-col flex ml-auto mr-auto items-center pt-40 w-full lg:w-2/4 md:w-3/5">
          <div className="flex justify-center mb-10 px-6">
            <img
              src={cardinalLogo}
              className="lg:h-24 h-16"
              alt="Logo Frontini y asoc"
            />
          </div>
          <form
            onSubmit={onSubmit}
            className="mt-2 flex flex-col lg:w-1/2 w-full px-6"
          >
            <label
              className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <div className="flex flex-wrap items-stretch w-full mb-4 relative h-15 bg-white items-center rounded mb-6 shadow-lg">
              <div className="flex -mr-px justify-center w-15 px-1">
                <span className="flex items-center leading-normal bg-white px-2 border-0 text-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 text-mainBlue"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                </span>
              </div>
              <input
                type="email"
                name="email"
                id="email"
                className="flex-shrink flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                placeholder="email@email.com"
                onChange={onChange}
                value={user.email}
              />
            </div>
            <label
              className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
              htmlFor="password"
            >
              Contraseña
            </label>
            <div className="flex flex-wrap items-stretch w-full mb-4 relative h-15 bg-white items-center rounded mb-6 shadow-lg">
              <div className="flex -mr-px justify-center w-15 px-1">
                <span className="flex items-center leading-normal bg-white px-2 border-0 text-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 text-mainBlue"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <input
                type="password"
                name="password"
                id="password"
                className="flex-shrink flex-grow flex-auto leading-normal flex-1 border-0 rounded rounded-l-none h-12 px-3 self-center relative outline-none"
                placeholder="***********"
                onChange={onChange}
                value={user.password}
              />
            </div>

            <button
              type="submit"
              className="bg-mainBlueLight flex items-center justify-center shadow-lg uppercase font-medium py-4 text-center px-17 md:px-12 md:py-4 text-white rounded leading-tight text-lg mt-4 mb-2 transition duration-500 ease-in-out hover:bg-indigo-700"
            >
              Ingresar
              {loadingLogin ? (
                <svg
                  className="animate-spin ml-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
            </button>
          </form>
          <div className="mt-2 flex flex-col lg:w-1/2 w-full px-6">
            <button
              //type="submit"
              className="bg-mainBlueLight flex items-center justify-center shadow-lg uppercase font-medium py-4 text-center px-17 md:px-12 md:py-4 text-white rounded leading-tight text-lg mt-4 mb-20 transition duration-500 ease-in-out hover:bg-indigo-700"
              onClick={() => solicitarReset()}
            >
              Olvidé mi contraseña
              {loadingReset ? (
                <svg
                  className="animate-spin ml-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
