import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import DepositosContext from "../../context/depositos/depositosContext";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../assets/customTabTheme.css";

import axiosClient from "../../config/axios";

function VerRecibosModal() {
  const depositosContext = useContext(DepositosContext);
  const {
    setModalVerRecibos,
    setDataRecibos,
    dataRecibos,
    idDeposito,
  } = depositosContext;

  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);

  useEffect(() => {
    getRecibosDeposito();
  }, []);

  const getRecibosDeposito = async () => {
    setLoading(true);
    await axiosClient
      .get(`v2/deposito_recibos/${idDeposito}`, header)
      .then((response) => {
        if (response.data.data.includes("Ocurrio un error")) {
          setErrorAPI(true);
        } else {
          setDataRecibos(response.data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setErrorAPI(true);
        setLoading(false);
      });
  };

  const buscarEnTabla = () => {
    // Esta funcion permite buscar por cualquier campo de la tabla
    var input = document.getElementById("buscar");
    var filter = input.value.toUpperCase();
    var table = document.getElementById("tabla_recibos");
    var trs = table.tBodies[0].getElementsByTagName("tr");

    for (var i = 0; i < trs.length; i++) {
      var tds = trs[i].getElementsByTagName("td");
      trs[i].style.display = "none";
      for (var i2 = 0; i2 < tds.length; i2++) {
        if (tds[i2].innerHTML.toUpperCase().indexOf(filter) > -1) {
          trs[i].style.display = "";
          continue;
        }
      }
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full xl:w-3/4 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pb-4 pt-4 sm:p-6 sm:pb-4 flex flex-row flex-wrap justify-between">
            <div className="flex flex-row pt-1">
              <div className="bg-mainBlueLight shadow-md rounded-full h-10 w-10 flex items-center justify-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="text-white h-5 w-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
              <h2 className="font-bold text-xl text-left mt-2 ml-3">
                Recibos del depósito
              </h2>
            </div>
            <div className="mt-2 xl:mt-0 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
              <div className="flex -mr-px w-15 px-1">
                <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 "
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <input
                type="text"
                name="buscar"
                id="buscar"
                className="bg-gray-200 flex-shrink flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                placeholder="Buscar ..."
                disabled={errorAPI}
                onChange={buscarEnTabla}
              />
            </div>
          </div>
          {loading ? (
            <div className="pt-4 pb-4 flex justify-center border-t-2">
              <button
                type="button"
                className="px-4 py-4 bg-green-500 text-white text-xl font-medium rounded inline-flex items-center"
                disabled
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-white animate-spin h-8 w-8 mr-3"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd"
                  />
                </svg>
                Cargando
              </button>
            </div>
          ) : errorAPI ? (
            <div className="pt-4 pb-4 flex justify-center border-t-2">
              <button
                type="button"
                className="px-4 py-4 bg-red-700 text-white text-xl font-medium rounded inline-flex items-center"
                disabled
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-white h-8 w-8 mr-3"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
                ¡Ups! Hubo un error, intentalo más tarde
              </button>
            </div>
          ) : (
            <div className="px-4 pb-4 sm:p-6 sm:pb-4 border-t-2 shadow">
              <Table className="bg-white mb-5 mt-5" id="tabla_recibos">
                <Thead className="bg-mainBlueLight xl:border-l-2 xl:border-r-2 xl:border-mainBlueLight">
                  <Tr className="text-white antialiased">
                    <Th>Código</Th>
                    <Th>Número</Th>
                    <Th>Fecha</Th>
                    <Th>DNI</Th>
                    <Th>Nombre</Th>
                    <Th>Importe</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataRecibos.length > 0
                    ? dataRecibos.map((r) => (
                        <Tr
                          className="mt-1 lg:mt-0 border-2 border-t-0"
                          key={r.id}
                        >
                          <Td>{r.codigo}</Td>
                          <Td>{r.numero}</Td>
                          <Td>{r.fecha + " " + r.hora}</Td>
                          <Td>{r.dni}</Td>
                          <Td>{r.nombre}</Td>
                          <Td>{"$ " + r.importe}</Td>
                        </Tr>
                      ))
                    : null}
                </Tbody>
              </Table>
            </div>
          )}

          <div className="bg-gray-100 flex justify-center p-4">
            <button
              type="button"
              className="px-4 py-1font-bold text-white bg-red-500 rounded flex items-center lg:mb-0"
              onClick={() => setModalVerRecibos(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerRecibosModal;
