import React, { useEffect, useState, useContext } from "react";
import Header from "../../Header";
import Loading from "../../Loading";
import EmpresasContext from "../../../context/admin/empresas/empresasContext";
import ModalAlta from "./ModalAlta";
import ModalEditar from "./ModalEditar";
import ModalInfo from "./ModalInfo";
import ModalEstado from "./ModalConfirmarEstado";

function Listado() {
  const empresasContext = useContext(EmpresasContext);
  const {
    setIdEmpresa,
    loadingEmpresas,
    empresas,
    getEmpresas,
    mainLoading,
    checkSelf,
    mostrarModalAlta,
    setMostrarModalAlta,
    mostrarModalEditar,
    setMostrarModalEditar,
    mostrarModalInfo,
    setMostrarModalInfo,
    mostrarModalEstado,
    setMostrarModalEstado,
  } = empresasContext;

  useEffect(() => {
    checkSelf().then(() => {
      getEmpresas();
    });
  }, []);

  useEffect(() => {}, [empresas]);

  const [actionsDropdown, setActionsDropdown] = useState({});

  const handleActionsDropdown = (id) => {
    var newActionsDropdown = { ...actionsDropdown };
    newActionsDropdown[id] = !newActionsDropdown[id];
    for (var [key, value] of Object.entries(newActionsDropdown)) {
      if (key !== id) {
        newActionsDropdown[key] = false;
      }
    }
    setActionsDropdown(newActionsDropdown);
  };

  /* 
  const mostrarModalDeReset = (usuario) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.idUsuario = usuario.id;
    newDatosUsuario.nombreUsuario = usuario.username;
    newDatosUsuario.email = usuario.email;
    setDatosUsuario(newDatosUsuario);
    setMostrarModalReset(true);
  };

  const mostrarModalDeModificar = (usuario) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.idUsuario = usuario.id;
    newDatosUsuario.nombreUsuario = usuario.username;
    setDatosUsuario(newDatosUsuario);
    setMostrarModalModificar(true);
  };

  const mostrarModalDeInfo = (userID) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.idUsuario = userID;
    setDatosUsuario(newDatosUsuario);
    setMostrarModalInfo(true);
  }; */

  if (!mainLoading && !loadingEmpresas) {
    // loadingEmpresas
    return (
      <div className="p-4">
        <Header />
        <div className="grid grid-cols-1 gap-4">
          <div className="p-4 bg-white shadow-lg rounded">
            <div className="lg:flex lg:items-start lg:justify-between lg:mb-0 mb-4">
              <div>
                <h1 className="text-xl font-bold text-gray-800">Empresas</h1>
                <p className="text-sm text-gray-500 mb-8">
                  Listado de todas las empresas registradas
                </p>
              </div>
              <div className="mb-5 mt-0 lg:mb-0 lg:mt-2">
                <button
                  className={
                    "bg-mainBlueLight rounded py-1 px-6 font-bold text-white"
                  }
                  onClick={() => setMostrarModalAlta(true)}
                >
                  Nueva Empresa
                </button>
              </div>
            </div>
            {loadingEmpresas ? <Loading color="mainBlueLight" /> : null}
            {!loadingEmpresas && empresas.length > 0
              ? empresas.map((e) => (
                  <div key={e.id}>
                    <div
                      key={e.id}
                      className={
                        "border rounded p-4 mb-3 lg:grid lg:grid-cols-4 lg:gap-4"
                      }
                    >
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800 ">ID</p>
                        <p className="text-sm text-gray-500 overflow-ellipsis overflow-hidden">
                          {e.id}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          NOMBRE
                        </p>
                        <p className="text-sm text-gray-500">{e.nombre}</p>
                      </div>
                      <div className="lg:border-r  lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          ESTADO
                        </p>
                        <p className="text-sm text-white">
                          {e.estado === 1 ? (
                            <span className="px-4 py-0 bg-green-400 rounded">
                              Habilitada
                            </span>
                          ) : (
                            <span className="px-4 py-0 bg-red-400 rounded">
                              Deshabilitada
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 flex items-center xl:justify-center">
                        <div className="relative">
                          <button
                            className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center lg:m-0 mt-5"
                            onClick={() => handleActionsDropdown(String(e.id))}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-6 w-6 mr-2 "
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Acciones
                          </button>
                          <div
                            className={
                              actionsDropdown[e.id]
                                ? "transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-5 w-48 rounded-md shadow-lg py-1 bg-white z-10"
                                : "transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-5 w-48 rounded-md shadow-lg py-1 bg-white z-10"
                            }
                          >
                            <button
                              type="button"
                              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                              role="menuitem"
                              onClick={() => {
                                setIdEmpresa(e.id);
                                setMostrarModalInfo(true);
                                handleActionsDropdown(e.id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 mr-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              Ver Información
                            </button>

                            <button
                              type="button"
                              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                              role="menuitem"
                              onClick={() => {
                                setIdEmpresa(e.id);
                                setMostrarModalEditar(true);
                                handleActionsDropdown(e.id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 mr-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                              Editar
                            </button>
                            <button
                              type="button"
                              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                              role="menuitem"
                              onClick={() => {
                                setIdEmpresa(e.id);
                                setMostrarModalEstado(true);
                                handleActionsDropdown(e.id);
                              }}
                            >
                              {e.estado === 1 ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 mr-4"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 mr-4"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
                                </svg>
                              )}
                              {e.estado === 1 ? "Deshabilitar" : "Habilitar"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
        {mostrarModalAlta ? <ModalAlta /> : null}
        {mostrarModalEditar ? <ModalEditar /> : null}
        {mostrarModalInfo ? <ModalInfo /> : null}
        {mostrarModalEstado ? <ModalEstado /> : null}
      </div>
    );
  } else {
    return (
      <div className="p-4">
        <Header />
        <div className="rounded bg-white shadow-xl flex justify-center items-center p-4 m-4 mt-20">
          <Loading color="mainBlueLight" />
        </div>
      </div>
    );
  }
}

export default Listado;
