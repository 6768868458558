import React, { useContext, useState, useEffect } from "react";
import EmpresasContext from "../../../context/admin/empresas/empresasContext";
import Loading from "../../Loading";

function AnularReciboModal() {
  const empresasContext = useContext(EmpresasContext);
  const {
    setMostrarModalEstado,
    empresas,
    cambiarEstado,
    idEmpresa,
    setIdEmpresa,
  } = empresasContext;

  const [cambiandoEstado, setCambiandoEstado] = useState(false);
  const [empresa, setEmpresa] = useState(false);

  useEffect(() => {
    var empresaActual = empresas.filter((emp) => emp.id === idEmpresa)[0];
    setEmpresa(empresaActual);
  });

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {empresa ? (
            <div>
              <div className="bg-white px-4 my-12 pb-4 sm:p-6 sm:pb-4">
                <div className="flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-12 w-12 mb-1 text-red-400"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div>
                  <h2 className="font-bold text-2xl text-center">
                    Estás a punto de{" "}
                    {empresa.estado === 1 ? (
                      <span className="text-red-400">deshabilitar</span>
                    ) : (
                      <span className="text-green-400">habilitar</span>
                    )}{" "}
                    la Empresa
                  </h2>
                  <h3 className="text-center text-xl font-medium text-blue-600 mb-2 mt-2">
                    {empresa.nombre}
                  </h3>
                  <p className="text-lg text-center text-gray-600">
                    Si realmente querés hacerlo, hacé click en <b>Confirmar</b>
                  </p>
                </div>
              </div>
              <div className="bg-gray-50 flex justify-between p-4">
                <button
                  disabled={cambiandoEstado}
                  className={
                    cambiandoEstado
                      ? "px-4 py-1 text-white bg-gray-400 rounded flex items-center lg:m-0"
                      : "px-4 py-1 text-white bg-green-400 rounded flex items-center lg:m-0"
                  }
                  onClick={() => {
                    setCambiandoEstado(true);
                    cambiarEstado(idEmpresa).then(() =>
                      setCambiandoEstado(false)
                    );
                  }}
                >
                  {cambiandoEstado ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="text-white animate-spin h-6 w-6 mr-3"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 mr-2 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  )}

                  {cambiandoEstado ? "Procesando" : "Confirmar"}
                </button>
                <button
                  type="button"
                  className="px-4 py-1font-bold text-white bg-red-500 rounded flex items-center lg:mb-0"
                  onClick={() => {
                    setIdEmpresa(null);
                    setMostrarModalEstado(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 mr-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Cancelar
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <Loading color="mainBlueLight" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AnularReciboModal;
