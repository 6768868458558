import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../../context/auth/authContext";
import ArchivosContext from "../../context/archivos/archivosContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import ModalAceptar from "./ModalAceptar";
import ModalRechazar from "./ModalRechazar";
import { useHistory } from "react-router-dom";

import Header from "../Header";
import Loading from "../Loading";

function Archivos() {
  const authContext = useContext(AuthContext);
  const { auth, getUser, checkGrupo } = authContext;

  const archivosContext = useContext(ArchivosContext);
  const {
    getArchivosProcesados,
    loading,
    archivos,
    resetState,
    subiendoArchivo,
    altaArchivo,
    setMostrarModalAceptar,
    mostrarModalAceptar,
    setMostrarModalRechazar,
    mostrarModalRechazar,
    setArchivoPreProcesado,
    datosPaginado,
    setDatosPaginado,
  } = archivosContext;

  const cobrosContext = useContext(CobrosContext);
  const { formatDate, formatImporte } = cobrosContext;

  const inputArchivoMaestro = useRef(null);

  let history = useHistory();

  const [actionsDropdown, setActionsDropdown] = useState({});
  const [verDetalles, setVerDetalles] = useState({});

  // datos paginado
  const [paginationSelectOpen, setPaginationSelectOpen] = useState(false);

  useEffect(() => {
    getUser();
    if (!auth) {
      history.push("/login");
    }
    if (!checkGrupo("EMPRESA")) {
      history.push("/");
    }
    //Limpia el state inicial con el cambio de componente.
    resetState();
    getArchivosProcesados();
  }, [auth]);

  useEffect(() => {
    getArchivosProcesados();
  }, [datosPaginado.perPage, datosPaginado.page]);

  //funciones paginado

  const updatePerPage = (cant) => {
    setPaginationSelectOpen(false);
    var newDatosPaginado = { ...datosPaginado };
    newDatosPaginado.perPage = cant;
    if (cant > newDatosPaginado.totalRows) {
      newDatosPaginado.indexFinish = newDatosPaginado.totalRows;
    } else {
      newDatosPaginado.indexFinish = cant;
    }
    newDatosPaginado.page = 1;
    newDatosPaginado.indexInit = 1;
    setDatosPaginado(newDatosPaginado);
  };

  const paginaSiguiente = () => {
    var newDatosPaginado = { ...datosPaginado };
    newDatosPaginado.page = newDatosPaginado.page + 1;
    newDatosPaginado.indexInit = newDatosPaginado.indexFinish + 1;
    if (
      newDatosPaginado.indexFinish + datosPaginado.perPage >
      newDatosPaginado.totalRows
    ) {
      newDatosPaginado.indexFinish = newDatosPaginado.totalRows;
    } else {
      newDatosPaginado.indexFinish =
        newDatosPaginado.indexFinish + newDatosPaginado.perPage;
    }
    setDatosPaginado(newDatosPaginado);
  };

  const saltarAUltimaPagina = () => {
    var newDatosPaginado = { ...datosPaginado };
    newDatosPaginado.page = newDatosPaginado.totalPages;
    newDatosPaginado.indexFinish = newDatosPaginado.totalRows;
    newDatosPaginado.indexInit =
      newDatosPaginado.indexInit +
      (newDatosPaginado.totalPages - 1) * newDatosPaginado.perPage;
    setDatosPaginado(newDatosPaginado);
  };

  const paginaAnterior = () => {
    var newDatosPaginado = { ...datosPaginado };
    newDatosPaginado.page = newDatosPaginado.page - 1;
    var diferencia =
      newDatosPaginado.indexFinish - (newDatosPaginado.indexInit - 1);
    newDatosPaginado.indexInit =
      newDatosPaginado.indexInit - newDatosPaginado.perPage;
    if (newDatosPaginado.indexFinish === newDatosPaginado.totalRows) {
      newDatosPaginado.indexFinish = newDatosPaginado.indexFinish - diferencia;
    } else {
      newDatosPaginado.indexFinish =
        newDatosPaginado.indexFinish - newDatosPaginado.perPage;
    }
    setDatosPaginado(newDatosPaginado);
  };

  const saltarAPrimeraPagina = () => {
    var newDatosPaginado = { ...datosPaginado };
    newDatosPaginado.page = 1;
    newDatosPaginado.indexInit = 1;
    newDatosPaginado.indexFinish = newDatosPaginado.perPage;
    setDatosPaginado(newDatosPaginado);
  };

  useEffect(() => {
    setActionsDropdown({});
    setVerDetalles({});
  }, [archivos]);

  const handleActionsDropdown = (id) => {
    var newActionsDropdown = { ...actionsDropdown };
    newActionsDropdown[id] = !newActionsDropdown[id];
    for (var [key, value] of Object.entries(newActionsDropdown)) {
      if (key !== id) {
        newActionsDropdown[key] = false;
      }
    }
    setActionsDropdown(newActionsDropdown);
  };

  const handleDetallesModal = (id) => {
    var newVerDetalles = { ...verDetalles };
    newVerDetalles[id] = !newVerDetalles[id];
    for (var [key, value] of Object.entries(newVerDetalles)) {
      if (key !== id) {
        newVerDetalles[key] = false;
      }
    }
    var newActionsDropdown = { ...actionsDropdown };
    for (var [key, value] of Object.entries(newActionsDropdown)) {
      newActionsDropdown[key] = false;
    }
    setActionsDropdown(newActionsDropdown);
    setVerDetalles(newVerDetalles);
  };

  const handleProcesarArchivo = () => {
    var formData = new FormData();
    formData.append(
      "archivo",
      document.getElementById("inputArchivoMaestro").files[0]
    );
    altaArchivo(formData);
  };

  if (auth) {
    return (
      <div className="p-4">
        <Header />
        <div className="grid grid-cols-1 gap-4">
          <div className="p-4 bg-white shadow-lg rounded">
            <div className="lg:flex lg:items-start lg:justify-between lg:mb-0 mb-4">
              <div>
                <h1 className="text-xl font-bold text-gray-800">
                  Archivos Procesados
                </h1>
                <p className="text-sm text-gray-500 mb-8">
                  Listado de los últimos archivos procesados
                </p>
              </div>
              <div className="mb-5 mt-0 lg:mb-0 lg:mt-2">
                <button
                  onClick={() => inputArchivoMaestro.current.click()}
                  className={
                    subiendoArchivo
                      ? "bg-gray-500 rounded py-1 px-6 font-bold text-white cursor-wait"
                      : "bg-mainBlueLight rounded py-1 px-6 font-bold text-white"
                  }
                  disabled={subiendoArchivo}
                >
                  Procesar Nuevo Archivo
                </button>
              </div>
              <input
                type="file"
                id="inputArchivoMaestro"
                ref={inputArchivoMaestro}
                style={{ display: "none" }}
                accept="text/plain"
                onChange={() => handleProcesarArchivo()}
              ></input>
            </div>
            {loading ? <Loading color="mainBlueLight" /> : null}
            {!loading && archivos.length > 0
              ? archivos.map((a) => (
                  <div>
                    <div
                      key={a.id}
                      className={
                        verDetalles[a.id]
                          ? "border rounded p-4 rounded-b-none lg:grid lg:grid-cols-5 lg:gap-4"
                          : "border rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
                      }
                    >
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800 ">
                          NOMBRE
                        </p>
                        <p className="text-sm text-gray-500 overflow-ellipsis overflow-hidden">
                          {a.nombre}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA DE SUBIDA
                        </p>
                        <p className="text-sm text-gray-500">
                          {a.fecha}{" "}
                          <span className="text-xs font-medium text-gray-800">
                            {a.hora}hs
                          </span>
                        </p>
                      </div>
                      <div className="lg:border-r  lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          ESTADO
                        </p>
                        {a.estado === 2 ? (
                          <p className="text-sm text-white">
                            <span className="px-4 py-1 bg-green-400 rounded">
                              Aceptado
                            </span>
                          </p>
                        ) : a.estado === 1 ? (
                          <p className="text-sm text-white ">
                            <span className="px-4 py-1 bg-yellow-400 rounded">
                              Provisorio
                            </span>
                          </p>
                        ) : a.estado === 3 ? (
                          <p className="text-sm text-white ">
                            <span className="px-4 py-1 bg-green-400 rounded">
                              Aceptado con Errores
                            </span>
                          </p>
                        ) : a.estado === 4 ? (
                          <p className="text-sm text-white ">
                            <span className="px-4 py-1 bg-red-400 rounded">
                              Cancelado
                            </span>
                          </p>
                        ) : null}
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          OBSERVACIONES
                        </p>
                        <p className="text-sm text-gray-500">
                          {a.observaciones === ""
                            ? "No posee"
                            : a.observaciones}
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 flex items-center xl:justify-center">
                        <div className="relative">
                          <button
                            className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center lg:m-0 mt-5"
                            onClick={() => handleActionsDropdown(String(a.id))}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-6 w-6 mr-2 "
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Acciones
                          </button>
                          <div
                            className={
                              actionsDropdown[a.id]
                                ? "transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-5 w-48 rounded-md shadow-lg py-1 bg-white z-10"
                                : "transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-5 w-48 rounded-md shadow-lg py-1 bg-white z-10"
                            }
                          >
                            <button
                              type="button"
                              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                              role="menuitem"
                              onClick={() => handleDetallesModal(String(a.id))}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-5 w-5 mr-4"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                />
                              </svg>
                              Ver Detalle
                            </button>
                            {a.estado === 1 ? (
                              <div>
                                <button
                                  type="button"
                                  className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-green-100 flex justify-start"
                                  role="menuitem"
                                  onClick={() => {
                                    setArchivoPreProcesado(a);
                                    setMostrarModalAceptar(true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="h-5 w-5 mr-4"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                                    />
                                  </svg>
                                  Aceptar
                                </button>
                                <button
                                  type="button"
                                  className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-red-100 flex justify-start"
                                  role="menuitem"
                                  onClick={() => {
                                    setArchivoPreProcesado(a);
                                    setMostrarModalRechazar(true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-4"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  Rechazar
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        verDetalles[a.id]
                          ? "border border-t-0 rounded rounded-t-none p-4 mb-3 lg:gap-4 "
                          : "hidden opacity-0 border border-t-0 rounded rounded-t-none p-0 m-0 lg:gap-4"
                      }
                    >
                      <div className="lg:m-0">
                        <p className="text-sm font-bold text-gray-800 mb-2 ml-0.5">
                          DETALLE DEL ARCHIVO - {a.nombre.toUpperCase()}
                        </p>
                      </div>
                      {a.detalle.length > 0
                        ? a.detalle.map((detalle) => (
                            <div
                              key={
                                String(detalle.identificacion) +
                                String(detalle.id_referencia) +
                                String(Math.floor(Math.random() * 9999))
                              }
                              className={
                                detalle.estado === 3
                                  ? "border border-green-300 rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
                                  : detalle.estado === 4
                                  ? "border border-red-400 rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
                                  : detalle.estado === 5
                                  ? "border border-red-400 rounded p-4 mb-3 lg:grid lg:grid-cols-6 lg:gap-4"
                                  : "border rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4 "
                              }
                            >
                              <div className="lg:border-r lg:m-0 mb-2">
                                <p className="text-sm font-bold text-gray-800">
                                  REFERENCIA
                                </p>
                                <p className="text-sm text-gray-500">
                                  {detalle.id_referencia}
                                </p>
                              </div>
                              <div className="lg:border-r lg:m-0 mb-2">
                                <p className="text-sm font-bold text-gray-800">
                                  NRO. SOCIO
                                </p>
                                <p className="text-sm text-gray-500">
                                  {detalle.identificacion}
                                </p>
                              </div>
                              <div className="lg:border-r lg:m-0 mb-2">
                                <p className="text-sm font-bold text-gray-800">
                                  FECHA DE PAGO
                                </p>
                                <p className="text-sm text-gray-500">
                                  {detalle.fecha}
                                </p>
                              </div>
                              <div className="lg:border-r lg:m-0 mb-2">
                                <p className="text-sm font-bold text-gray-800">
                                  IMPORTE
                                </p>
                                <p className="text-sm text-gray-500">
                                  {formatImporte(detalle.importe)}
                                </p>
                              </div>
                              <div className=" lg:m-0 mb-2">
                                <p className="text-sm font-bold text-gray-800">
                                  ESTADO
                                </p>
                                {detalle.estado === 3 ? (
                                  <p className="text-sm text-white">
                                    <span className="px-4 py-1 bg-green-400 rounded">
                                      Aceptado
                                    </span>
                                  </p>
                                ) : detalle.estado === 2 ? (
                                  <p className="text-sm text-white ">
                                    <span className="px-4 py-1 bg-yellow-400 rounded">
                                      Disponible
                                    </span>
                                  </p>
                                ) : detalle.estado === 5 ? (
                                  <div className="flex flex-row items-center">
                                    <p className="text-sm text-white ">
                                      <span className="px-4 py-1 bg-red-400 mr-2 rounded">
                                        Erróneo
                                      </span>
                                    </p>
                                    {/*                                     <div
                                      className="flex bg-red-400 pl-2 pr-2 rounded items-center"
                                      style={{ height: 25 }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-white mb-0.5 cursor-pointer"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                    </div> */}
                                  </div>
                                ) : (
                                  <p className="text-sm text-white ">
                                    <span className="px-4 py-1 bg-red-400 rounded">
                                      Cancelado
                                    </span>
                                  </p>
                                )}
                              </div>
                              {detalle.estado === 5 ? (
                                <div className=" lg:m-0 mb-2">
                                  <p
                                    style={{ whiteSpace: "pre-line" }}
                                    className="text-sm font-bold text-gray-800"
                                  >
                                    OBSERVACIONES
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {detalle.observaciones}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                ))
              : null}
            <div className="flex flex-row flex-wrap justify-left lg:justify-end items-center">
              <div className="flex flex-row flex-wrap items-center mt-0.5">
                <p className="mr-1 text-sm text-gray-500 select-none">
                  {"Archivos por página:  " + datosPaginado.perPage}
                </p>
                <div className="relative">
                  <button
                    className="mt-1.5"
                    onClick={() =>
                      setPaginationSelectOpen(!paginationSelectOpen)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-6 w-6 mr-2 "
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <div
                    className={
                      paginationSelectOpen
                        ? "transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-1.5  rounded-md shadow-lg py-1 bg-white z-10"
                        : "transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-1.5 rounded-md shadow-lg py-1 bg-white z-10"
                    }
                  >
                    <button
                      type="button"
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                      role="menuitem"
                      onClick={() => updatePerPage(5)}
                    >
                      5
                    </button>
                    <button
                      type="button"
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                      role="menuitem"
                      onClick={() => updatePerPage(10)}
                    >
                      10
                    </button>
                    <button
                      type="button"
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                      role="menuitem"
                      onClick={() => updatePerPage(20)}
                    >
                      20
                    </button>
                  </div>
                </div>
                <p className="mr-2 text-sm text-gray-500 select-none">
                  {`Mostrando ${datosPaginado.indexInit} - ${datosPaginado.indexFinish} de ${datosPaginado.totalRows}`}
                </p>
              </div>
              <div className="grid grid-cols-4 gap-2 mt-2 lg:mt-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    datosPaginado.page - 1 < 1
                      ? "h-5 w-5 text-gray-300 cursor-not-allowed"
                      : "h-5 w-5 cursor-pointer"
                  }
                  onClick={
                    datosPaginado.page - 1 < 1
                      ? null
                      : () => saltarAPrimeraPagina()
                  }
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    datosPaginado.page - 1 < 1
                      ? "h-5 w-5 text-gray-300 cursor-not-allowed"
                      : "h-5 w-5 cursor-pointer"
                  }
                  onClick={
                    datosPaginado.page - 1 < 1 ? null : () => paginaAnterior()
                  }
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    datosPaginado.page + 1 > datosPaginado.totalPages
                      ? "h-5 w-5 text-gray-300 cursor-not-allowed"
                      : "h-5 w-5 cursor-pointer"
                  }
                  onClick={
                    datosPaginado.page + 1 > datosPaginado.totalPages
                      ? null
                      : () => paginaSiguiente()
                  }
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    datosPaginado.page + 1 > datosPaginado.totalPages
                      ? "h-5 w-5 text-gray-300 cursor-not-allowed"
                      : "h-5 w-5 cursor-pointer"
                  }
                  onClick={
                    datosPaginado.page + 1 > datosPaginado.totalPages
                      ? null
                      : () => saltarAUltimaPagina()
                  }
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {mostrarModalAceptar ? <ModalAceptar /> : null}
        {mostrarModalRechazar ? <ModalRechazar /> : null}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Archivos;
