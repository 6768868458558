import React, { useContext } from "react";
import AdminContext from "../../../../context/admin/usuarios/adminContext";

function Confirmar() {
  const adminContext = useContext(AdminContext);
  const { datosUsuario } = adminContext;

  return (
    <div className="bg-gray-100 rounded grid-cols-1 p-4 m-4 lg:mt-0">
      <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        <p className="ml-1 text-white subpixel-antialiased">Tipo de Usuario</p>
      </div>
      <p className="ml-7 font-medium mb-2 text-gray-600">
        {datosUsuario.tipoUsuario}
      </p>
      <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        <p className="ml-1 text-white subpixel-antialiased">Nombre Formal</p>
      </div>
      <p className="ml-7 font-medium mb-2 text-gray-600">
        {datosUsuario.nombreUsuario}
      </p>
      <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        <p className="ml-1 text-white subpixel-antialiased">
          Nombre de Usuario
        </p>
      </div>
      <p className="ml-7 font-medium mb-2 text-gray-600">
        {datosUsuario.username}
      </p>
      <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        <p className="ml-1 text-white subpixel-antialiased">Contraseña</p>
      </div>
      <p className="ml-7 font-medium mb-2 text-gray-600">
        {datosUsuario.password}
      </p>
      <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        <p className="ml-1 text-white subpixel-antialiased">Email</p>
      </div>
      <p className="ml-7 font-medium mb-2 text-gray-600">
        {datosUsuario.email}
      </p>
      {datosUsuario.tipoUsuario !== "EMPRESA" ? (
        <div>
          <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <p className="ml-1 text-white subpixel-antialiased">Descripción</p>
          </div>
          <p className="ml-7 font-medium mb-2 text-gray-600">
            {datosUsuario.descripcion}
          </p>
        </div>
      ) : null}
      {datosUsuario.tipoUsuario !== "EMPRESA" ? (
        <div>
          <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <p className="ml-1 text-white subpixel-antialiased">
              {datosUsuario.tipoUsuario === "COBRADOR"
                ? "Punto de Cobro"
                : "Empresa"}
            </p>
          </div>
          <p className="ml-7 font-medium mb-2 text-gray-600">
            {datosUsuario.asociadoANombre}
          </p>
          <div className="flex flex-row items-center mb-1 mt-1 bg-mainBlueLight rounded">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <p className="ml-1 text-white subpixel-antialiased">Editar Fecha</p>
          </div>
          <p className="ml-7 font-medium mb-2 text-gray-600">
            {datosUsuario.canEditDate === "1"
              ? "Puede editar Fecha"
              : "No puede editar Fecha"}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default Confirmar;
