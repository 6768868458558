import React, { useContext } from "react";
import AdminContext from "../../../../context/admin/usuarios/adminContext";

function Formulario() {
  const adminContext = useContext(AdminContext);
  const { datosUsuario, setDatosUsuario } = adminContext;

  const updateValues = (e) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario[e.target.name] = e.target.value;
    setDatosUsuario(newDatosUsuario);
  };

  const updateSelect = (e) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.canEditDate = e.target.value;
    setDatosUsuario(newDatosUsuario);
  };

  return (
    <div>
      <div className="flex justify-center mt-2 mb-2 lg:-mt-2">
        <p className="font-bold text-xl">Completá los datos del Usuario</p>
      </div>
      <div className="flex flex-wrap lg:grid lg:grid-cols-2 lg:gap-4 pt-4 sm:pt-0 pb-4">
        <div className="w-full mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
          <div className="flex -mr-px w-15 px-1">
            <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
          <input
            type="text"
            name="username"
            id="username"
            value={datosUsuario.username}
            className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
            placeholder="Nombre de Usuario"
            onChange={(e) => updateValues(e)}
          />
        </div>
        <div className="w-full mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
          <div className="flex -mr-px w-15 px-1">
            <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
          <input
            type="text"
            name="nombreUsuario"
            id="nombreUsuario"
            value={datosUsuario.nombreUsuario}
            className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
            placeholder="Nombre"
            onChange={(e) => updateValues(e)}
          />
        </div>
        <div className="w-full mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
          <div className="flex -mr-px w-15 px-1">
            <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
          <input
            type="password"
            name="password"
            id="password"
            value={datosUsuario.password}
            className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
            placeholder="Contraseña"
            onChange={(e) => updateValues(e)}
          />
        </div>
        <div className=" w-full mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
          <div className="flex -mr-px w-15 px-1">
            <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
          <input
            type="email"
            name="email"
            id="email"
            value={datosUsuario.email}
            className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
            placeholder="Email"
            onChange={(e) => updateValues(e)}
          />
        </div>
        {datosUsuario.tipoUsuario !== "EMPRESA" ? (
          <div className=" w-full mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
            <div className="flex -mr-px w-15 px-1">
              <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                  <path
                    fill-rule="evenodd"
                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </div>
            <input
              type="text"
              name="descripcion"
              id="descripcion"
              value={datosUsuario.descripcion}
              className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
              placeholder="Descripción"
              onChange={(e) => updateValues(e)}
            />
          </div>
        ) : null}
        {datosUsuario.tipoUsuario !== "EMPRESA" ? (
          <div className="w-full">
            {" "}
            <div className="w-full mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
              <div className="flex -mr-px w-15 px-1">
                <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <select
                name="canEditDate"
                id="canEditDate"
                className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                value={datosUsuario.canEditDate}
                onChange={(e) => updateSelect(e)}
              >
                <option value="1">Puede editar Fecha</option>
                <option value="0">No puede editar Fecha</option>
              </select>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Formulario;
