import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import DepositosContext from "../../context/depositos/depositosContext";

import { useHistory } from "react-router-dom";

import axiosClient from "../../config/axios";

import Header from "../Header";
import Loading from "../Loading";

import DepositosAltaModal from "./DepositosAltaModal";
import VerRecibosModal from "./VerRecibosModal";
import VerImagenModal from "./VerImagenModal";

function Empresa() {
  const authContext = useContext(AuthContext);
  const { getUser, header, checkGrupo, userID } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const { formatDate, formatImporte } = cobrosContext;

  const depositosContext = useContext(DepositosContext);
  const {
    resetState,
    setModalAltaDeposito,
    modalAltaDeposito,
    setDataAltaModalDeposito,
    modalVerRecibos,
    setModalVerRecibos,
    setIdDeposito,
    setDataImagen,
    modalVerImagen,
    setModalVerImagen,
  } = depositosContext;

  let history = useHistory();

  const [homeData, setHomeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionsModalOpen, setActionsModalOpen] = useState({});
  const [attachmentsOpen, setAttachmentsOpen] = useState({});
  const [selectedDepositos, setSelectedDepositos] = useState("Realizados");

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      if (!checkGrupo("EMPRESA")) {
        history.push("/");
      }
      getHomeData();
      resetState();
    }
    return function cleanup() {
      mounted = false;
    };
  }, [modalAltaDeposito]);

  //obtengo los datos de la página principal
  const getHomeData = async () => {
    setLoading(true);
    await axiosClient
      .get(`v2/depositos_realizados/empresa/${userID}`, header)
      .then((response) => {
        setHomeData(response.data.data);
        const newActionsModal = { ...actionsModalOpen };
        const newAttachmentsOpen = { ...attachmentsOpen };
        for (var i = 0; i < response.data.data.length; i++) {
          newAttachmentsOpen[response.data.data[i].id] = false;
          newActionsModal[response.data.data[i].id] = false;
        }
        setAttachmentsOpen(newAttachmentsOpen);
        setActionsModalOpen(newActionsModal);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getDepositosAceptados = () => {
    setLoading(true);
    setActionsModalOpen({});
    setAttachmentsOpen({});
    axiosClient
      .get(`v2/depositos_aceptados/empresa/${userID}`, header)
      .then((response) => {
        setHomeData(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleFailSafe = (id) => {
    setActionsModalOpen(false);
    setModalAltaDeposito(true);
    setDataAltaModalDeposito(id);
  };

  const handleActionsModal = (id) => {
    const newActionsModal = { ...actionsModalOpen };
    newActionsModal[id] = !newActionsModal[id];
    setActionsModalOpen(newActionsModal);
  };

  const handleAttachmentsOpen = (id) => {
    setActionsModalOpen(false);
    const newAttachmentsOpen = { ...attachmentsOpen };
    newAttachmentsOpen[id] = !newAttachmentsOpen[id];
    setAttachmentsOpen(newAttachmentsOpen);
  };

  const handleImagenModal = (imageID, url) => {
    setDataImagen([imageID, url]);
    setModalVerImagen(true);
  };

  const handleRecibosModal = (id) => {
    setActionsModalOpen(false);
    setModalVerRecibos(true);
    setIdDeposito(id);
  };

  return (
    <div className="p-4">
      <Header />
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 bg-white shadow-lg rounded">
          <div className="lg:flex lg:items-start lg:justify-between mb-4">
            <div>
              <h1 className="text-xl font-bold text-gray-800">Depósitos</h1>
            </div>
          </div>
          <div className="ml-0.5 flex flex-col lg:flex-row justify-start items-start">
            <button
              style={{ width: 122.31 }}
              className={
                selectedDepositos === "Realizados"
                  ? "bg-green-400 text-white rounded py-1 mb-3 lg:mb-0 lg:mr-2"
                  : "bg-mainBlueLight text-white rounded py-1 mb-3 lg:mb-0 lg:mr-2"
              }
              onClick={() => {
                setSelectedDepositos("Realizados");
                getHomeData();
              }}
            >
              RECIBIDOS
            </button>
            <button
              className={
                selectedDepositos === "Aceptados"
                  ? "bg-green-400 text-white rounded px-4 py-1"
                  : "bg-mainBlueLight text-white rounded px-4 py-1"
              }
              onClick={() => {
                setSelectedDepositos("Aceptados");
                getDepositosAceptados();
              }}
            >
              ACEPTADOS
            </button>
          </div>
          <div className="mt-3 ml-0.5">
            {selectedDepositos === "Realizados" ? (
              <p className="text-sm text-gray-500 ">
                Listado de los últimos depósitos recibidos a la empresa.
              </p>
            ) : (
              <p className="text-sm text-gray-500 ">
                Listado de los últimos depósitos aceptados por la empresa.
              </p>
            )}
          </div>

          {loading ? <Loading color="mainBlueLight" /> : null}
          {homeData && homeData.length > 0
            ? homeData.map((d) => (
                <div>
                  <div
                    key={d.id}
                    className={
                      attachmentsOpen[d.id]
                        ? "border rounded rounded-b-none mt-3 p-4 lg:grid lg:grid-cols-8 lg:gap-4"
                        : "border rounded mt-3 p-4 lg:grid lg:grid-cols-8 lg:gap-4"
                    }
                  >
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">FECHA</p>
                      <p className="text-sm text-gray-500">
                        {formatDate(d.fecha)}{" "}
                        <span className="text-xs font-medium text-gray-800">
                          {d.hora}hs
                        </span>
                      </p>
                    </div>
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">
                        PUNTO DE COBRO
                      </p>
                      <p className="text-sm text-gray-500">{d.empresa}</p>
                    </div>
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">TOTAL</p>
                      <p className="text-sm text-gray-500">
                        {formatImporte(d.importeTotal)}
                      </p>
                    </div>
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">
                        COMISION
                      </p>
                      <p className="text-sm text-gray-500">
                        {formatImporte(d.importeComision)}
                      </p>
                    </div>
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">
                        DEPOSITADO
                      </p>
                      <p className="text-sm text-gray-500">
                        {formatImporte(d.importeDepositado)}
                      </p>
                    </div>
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">
                        OBSERVACIONES
                      </p>
                      <p className="text-sm text-gray-500 overflow-ellipsis overflow-hidden">
                        {d.detalle.length > 0 ? d.detalle : "No posee"}
                      </p>
                    </div>
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">ESTADO</p>
                      {d.estado === "Aceptado" ? (
                        <p className="text-sm text-white">
                          <span className="px-4  bg-green-400 rounded">
                            {d.estado}
                          </span>
                        </p>
                      ) : (
                        <p className="text-sm text-white ">
                          <span className="px-3 bg-yellow-400 rounded">
                            {d.estado}
                          </span>
                        </p>
                      )}
                    </div>
                    <div className="lg:m-0 mb-2 flex items-center xl:justify-center">
                      <div className="relative">
                        <button
                          className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center lg:m-0 mt-5"
                          onClick={() => handleActionsModal(d.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-6 w-6 mr-2 "
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Acciones
                        </button>
                        <div
                          className={
                            actionsModalOpen[d.id]
                              ? "transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-5 w-48 rounded-md shadow-lg py-1 bg-white z-10"
                              : "transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-5 w-48 rounded-md shadow-lg py-1 bg-white z-10"
                          }
                        >
                          <button
                            type="button"
                            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                            role="menuitem"
                            onClick={() => handleRecibosModal(d.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="h-5 w-5 mr-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                              />
                            </svg>
                            Ver Recibos
                          </button>
                          <button
                            type="button"
                            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                            role="menuitem"
                            onClick={() => handleAttachmentsOpen(d.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="h-5 w-5 mr-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                              />
                            </svg>
                            Ver Imágenes
                          </button>
                          {selectedDepositos === "Realizados" ? (
                            <button
                              type="button"
                              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-green-100 flex justify-start"
                              role="menuitem"
                              onClick={() => handleFailSafe(d.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-5 w-5 mr-4"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                                />
                              </svg>
                              Aceptar
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {attachmentsOpen[d.id] ? (
                    <div className="border border-t-0 rounded rounded-t-none p-4 mb-3 lg:gap-4">
                      <div className="lg:m-0">
                        <p className="text-sm font-bold text-gray-800">
                          IMÁGENES ADJUNTAS
                        </p>
                      </div>
                      {d.imagenes.length > 0 ? (
                        d.imagenes.map((img) => (
                          <div className="flex flex-column lg:mt-2">
                            <button
                              className="px-2 py-0 font-medium text-white bg-green-500 rounded flex items-center lg:m-0 mt-5"
                              onClick={() =>
                                handleImagenModal(
                                  d.imagenes.indexOf(img) + 1,
                                  img["imagen"]
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="h-4 w-4 mr-2 "
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              Imagen {d.imagenes.indexOf(img) + 1}
                            </button>
                          </div>
                        ))
                      ) : (
                        <div className="flex flex-column lg:mt-2">
                          <span className="px-2 py-1 font-medium text-white bg-yellow-500 rounded flex items-center lg:m-0 mt-5">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-5 w-5 mr-2"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Este depósito no contiene imágenes adjuntas
                          </span>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              ))
            : null}
        </div>
      </div>

      {modalAltaDeposito ? <DepositosAltaModal /> : null}
      {modalVerRecibos ? <VerRecibosModal /> : null}
      {modalVerImagen ? <VerImagenModal /> : null}
    </div>
  );
}

export default Empresa;
