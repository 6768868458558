import React, { useState, useContext } from "react";
import WidgetHeader from "./WidgetHeader";
import WidgetForm from "./WidgetForm";
import WidgetButton from "./WidgetButton";
import "./Styles.css"
import AuthContext from "../../context/auth/authContext";

function Widget() {

  const authContext = useContext(AuthContext);
  const { name } = authContext;

  const sistema = "Cardinal";

  const [valueAbrir, setValueAbrir] = useState(false);
  return (
    <div>
      <div
        className={
          valueAbrir ? "Widget abrir bg-white rounded-lg shadow-md" : "Widget"
        }
      >
        <WidgetHeader sistema={sistema} />
        <WidgetForm usuario={name} sistema={sistema}/>
      </div>
      <WidgetButton valueAbrir={valueAbrir} setValueAbrir={setValueAbrir} />
    </div>
  );
}

export default Widget;
