import React, { useEffect, useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";

function FacturasCliente() {
  const authContext = useContext(AuthContext);
  const { fecha } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const {
    facturasCliente,
    nombreCliente,
    formatDate,
    formatImporte,
    calcularVencimiento,
    filtrarFacturasSeleccionadas,
    vencimientoCliente,
    addFactura,
    totalCliente,
    selectPersona,
    dniPersona,
  } = cobrosContext;

  const facturasPropias = [];
  const grupoPago = [];
  var propiasVacio = true;
  var grupoVacio = true;
  facturasCliente.map((f) => {
    if (f.nroDoc === dniPersona) {
      propiasVacio = false;
      facturasPropias.push(f);
    } else {
      grupoVacio = false;
      grupoPago.push(f);
    }
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      filtrarFacturasSeleccionadas();
      window.scrollTo(0, 0);
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="p-4 bg-white rounded shadow-lg mb-3">
      <div className="xl:flex xl:justify-between xl:items-center mb-4">
        <div>
          <h1 className="text-xl font-bold text-gray-800">{nombreCliente}</h1>
          <p className="text-sm text-gray-500 mb-4 xl:mb-0">Pagos pendientes</p>
        </div>
        <div className="flex items-center">
          <div className="p-4 rounded bg-gray-200 text-gray-800 mr-4">
            <p>Deuda Total</p>
            <h3 className="font-bold">{formatImporte(totalCliente)}</h3>
          </div>
          <div className="p-4 rounded bg-red-400 text-white">
            <p>Deuda vencida</p>
            <h3 className="font-bold">{formatImporte(vencimientoCliente)}</h3>
          </div>
        </div>
      </div>

      {facturasPropias.length > 0 ? (
        facturasPropias.map((f) => (
          <div
            className={
              calcularVencimiento(fecha, f.fechaVencimiento1)
                ? "rounded border-2 border-red-400 p-3 mb-2 lg:grid lg:grid-cols-6 cursor-pointer lg:gap-4 hover:border-red-600 transition ease-in-out duration-300 "
                : "border rounded p-3 mb-3 lg:grid lg:grid-cols-6 cursor-pointer lg:gap-4 hover:border-blue-500 transition ease-in-out duration-300 "
            }
            key={f.id}
            onClick={() => addFactura(f)}
          >
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">EMPRESA</p>
              <p className="text-sm text-gray-500">{f.nombreempresa}</p>
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">SERVICIO</p>
              <p className="text-sm text-gray-500">{f.descripcion}</p>
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">
                FECHA DE EMISIÓN
              </p>
              <p className="text-sm text-gray-500">{formatDate(f.fecha)}</p>
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">VENCIMIENTO</p>
              {calcularVencimiento(fecha, f.fechaVencimiento1) ? (
                <p className="text-sm text-red-500">
                  {formatDate(f.fechaVencimiento1)} + {formatImporte(f.interes)}{" "}
                  INTERÉS
                </p>
              ) : (
                <p className="text-sm text-gray-500">
                  {formatDate(f.fechaVencimiento1)}
                </p>
              )}
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">
                PUEDE PAGAR HASTA
              </p>
              <p className="text-sm text-gray-500">
                {formatDate(f.fechalimite)}
              </p>
            </div>
            <div>
              <p className="text-sm font-bold text-gray-800">DEUDA</p>

              {calcularVencimiento(fecha, f.fechaVencimiento1) ? (
                <p className="text-sm text-gray-500">
                  {formatImporte(f.saldo + f.interes)}
                </p>
              ) : (
                <p className="text-sm text-gray-500">
                  {formatImporte(f.saldo)}
                </p>
              )}
            </div>
          </div>
        ))
      ) : propiasVacio ? (
        <div className="rounded w-full px-2 border border-blue-500 text-center font-bold bg-blue-300 text-lg text-gray-700 py-4">
          {nombreCliente} no tiene facturas pendientes
        </div>
      ) : null}

      <div className="mt-8 mb-8">
        <h1 className="text-xl font-bold text-gray-800">
          {"Grupo de Pago de " + nombreCliente}
        </h1>
        <p className="text-sm text-gray-500 mb-4 xl:mb-0">
          Pagos pendientes del Grupo de Pago
        </p>
      </div>

      {grupoPago.length > 0 ? (
        grupoPago.map((f) => (
          <div
            className={
              calcularVencimiento(fecha, f.fechaVencimiento1)
                ? "rounded border-2 border-red-400 p-3 mb-2 lg:grid lg:grid-cols-7 cursor-pointer lg:gap-4 hover:border-red-600 transition ease-in-out duration-300 "
                : "border rounded p-3 mb-3 lg:grid lg:grid-cols-7 cursor-pointer lg:gap-4 hover:border-blue-500 transition ease-in-out duration-300 "
            }
            key={f.id}
            onClick={() => addFactura(f)}
          >
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">EMPRESA</p>
              <p className="text-sm text-gray-500">{f.nombreempresa}</p>
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">NOMBRE</p>
              <p className="text-sm text-gray-500">{f.nombre}</p>
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">SERVICIO</p>
              <p className="text-sm text-gray-500">{f.descripcion}</p>
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">
                FECHA DE EMISIÓN
              </p>
              <p className="text-sm text-gray-500">{formatDate(f.fecha)}</p>
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">VENCIMIENTO {f.fechaVencimiento1}</p>
              {!calcularVencimiento(fecha, f.fechaVencimiento1) ? (
                <p className="text-sm text-red-500">
                  {formatDate(f.fechaVencimiento1)} + {formatImporte(f.interes)}{" "}
                  INTERÉS
                </p>
              ) : (
                <p className="text-sm text-gray-500">
                  {formatDate(f.fechaVencimiento1)}
                </p>
              )}
            </div>
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">
                PUEDE PAGAR HASTA
              </p>
              <p className="text-sm text-gray-500">
                {formatDate(f.fechalimite)}
              </p>
            </div>
            <div>
              <p className="text-sm font-bold text-gray-800">DEUDA</p>

              {calcularVencimiento(fecha, f.fechaVencimiento1) ? (
                <p className="text-sm text-gray-500">
                  {formatImporte(f.saldo + f.interes)}
                </p>
              ) : (
                <p className="text-sm text-gray-500">
                  {formatImporte(f.saldo)}
                </p>
              )}
            </div>
          </div>
        ))
      ) : grupoVacio ? (
        <div className="rounded w-full px-2 border border-blue-500 text-center font-bold bg-blue-300 text-lg text-gray-700 py-4">
          El grupo de pago de {nombreCliente} no tiene facturas pendientes
        </div>
      ) : null}
    </div>
  );
}

export default FacturasCliente;
