import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import { Link } from "react-router-dom";

import axiosClient from "../../config/axios";

import Header from "../Header";
import Loading from "../Loading";
import ModalAnularRecibo from "./AnularReciboModal.js";

function Cobros() {
  const authContext = useContext(AuthContext);
  const { getUser, userID, header, fecha } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const {
    formatDate,
    formatImporte,
    resetState,
    consultarImprimirRecibo,
    modalAnularRecibo,
    setModalAnularRecibo,
    setIdRecibo,
    recibosUpdated,
    setRecibosUpdated,
  } = cobrosContext;

  const [loading, setLoading] = useState(false);

  const [homeData, setHomeData] = useState([]);
  const [actionsModalOpen, setActionsModalOpen] = useState({});

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      //Limpia el state inicial con el cambio de componente.
      getHomeData();
      resetState();
    }
    return () => {
      mounted = false;
    };
  }, []);

  //obtengo los datos de la página principal
  const getHomeData = async () => {
    setLoading(true);
    const data = {
      fecha: fecha,
      id_cobrador: userID,
    };
    await axiosClient
      .post(`v2/recibos_cobrador/${userID}`, data, header)
      .then((response) => {
        setHomeData(response.data.data);
        const newActionsModal = { ...actionsModalOpen };
        for (var i = 0; i < response.data.data.length; i++) {
          newActionsModal[response.data.data[i].id] = false;
        }
        setActionsModalOpen(newActionsModal);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleActionsModal = (id) => {
    const newActionsModal = { ...actionsModalOpen };
    newActionsModal[id] = !newActionsModal[id];
    setActionsModalOpen(newActionsModal);
  };

  const handleAnularReciboModal = (id) => {
    const newActionsModal = { ...actionsModalOpen };
    newActionsModal[id] = false;
    setActionsModalOpen(newActionsModal);
    setIdRecibo(id);
    setModalAnularRecibo(true);
  };

  return (
    <div className="p-4">
      <Header />
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 bg-white shadow-lg rounded">
          <div className="lg:flex lg:items-start lg:justify-between">
            <div>
              <h1 className="text-xl font-bold text-gray-800">
                Cobros realizados
              </h1>
              <p className="text-sm text-gray-500 mb-4">
                Listado de los últimos diez cobros realizados.
              </p>
            </div>
            <div className="mb-5 mt-0 lg:mb-0 lg:mt-2">
              <Link
                to={"/nuevo-cobro"}
                className="bg-mainBlueLight rounded py-1 px-6 font-bold text-white"
              >
                Nuevo Cobro
              </Link>
            </div>
          </div>
          {loading ? <Loading color="mainBlueLight" /> : null}

          {homeData && homeData.length > 0
            ? homeData.map((i) => (
                <div
                  key={i.id}
                  className="border rounded p-3 mb-3 lg:grid lg:grid-cols-6 lg:gap-4"
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">PERSONA</p>
                    <p className="text-sm text-gray-500">{i.nombre}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">FECHA</p>
                    <p className="text-sm text-gray-500">
                      {formatDate(i.fecha)}{" "}
                      <span className="text-xs font-medium text-gray-800">
                        {i.hora}hs
                      </span>
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">NUMERO</p>
                    <p className="text-sm text-gray-500">{i.numero}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(i.importe)}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">EMPRESA</p>
                    <p className="text-sm text-gray-500">{i.empresa.nombre}</p>
                  </div>
                  <div className="lg:m-0 mb-2 flex items-center xl:justify-end">
                    <div className="relative">
                      <button
                        className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center lg:m-0 mt-5"
                        onClick={() => handleActionsModal([i.id])}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-4 w-4 mr-2 "
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Acciones
                      </button>
                      <div
                        className={
                          actionsModalOpen[i.id]
                            ? "transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute w-48 rounded-md shadow-lg py-1 bg-white z-10"
                            : "transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute w-48 rounded-md shadow-lg py-1 bg-white z-10"
                        }
                      >
                        <button
                          type="button"
                          className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                          role="menuitem"
                          onClick={() => consultarImprimirRecibo([i.id])}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-5 w-5 mr-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Imprimir
                        </button>
                        <button
                          type="button"
                          className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-red-200 flex justify-start"
                          role="menuitem"
                          onClick={() => handleAnularReciboModal(i.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-5 w-5 mr-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Anular Recibo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      {modalAnularRecibo ? <ModalAnularRecibo /> : null}
    </div>
  );
}

export default Cobros;
