import React from "react";
import cardinalLogo from "../assets/cardinal-full-white.svg";
import frontiniLogo from "../assets/frontini-logo.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="">
      <div className=" bg-mainBlue p-10">
        <div className="xl:flex xl:justify-between grid justify-items-center">
          <div className="mb-6">
            <img src={cardinalLogo} className="h-14" alt="Cardinal logo" />
          </div>
          <div className="mb-4">
            <img src={frontiniLogo} className="h-14 mb-4" alt="Cardinal logo" />
            <p className="text-white font-bold text-sm">Frontini S.R.L</p>
            <p className="text-white text-sm">Dirección Calle 14 N° 622 1/2</p>
            <p className="text-white text-sm">La Plata, Bs. As. – Argentina</p>
          </div>
        </div>
      </div>
      <div className="bg-mainBlueLight text-center p-4">
        <a
          className="text-gray-300 font-bold"
          href="https://frontiniyasoc.com.ar/"
          target="_blank"
        >
          Desarrollado por Frontini Soluciones Informáticas
        </a>
      </div>
    </div>
  );
}

export default Footer;
