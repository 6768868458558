import { useEffect, useState, useContext } from "react";
import Header from "../Header";
import Loading from "../Loading";
import axiosClient from "../../config/axios";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";

function PuntosDeCobro() {
  const authContext = useContext(AuthContext);
  const { header, userID } = authContext;
  const cobrosContext = useContext(CobrosContext);
  const { formatDate, formatImporte } = cobrosContext;
  const [loadingPuntos, setLoadingPuntos] = useState(true);
  const [puntos, setPuntos] = useState([]);

  useEffect(() => {
    axiosClient
      .post("get_estado_puntoscobro", { idEmpresa: userID }, header)
      .then((response) => {
        setPuntos(response.data.data);
        setLoadingPuntos(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingPuntos(false);
      });
  }, []);

  return (
    <div className="p-4">
      <Header />
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 bg-white shadow-lg rounded">
          <div className="lg:flex lg:items-start lg:justify-between mb-2">
            <div>
              <h1 className="text-xl font-bold text-gray-800">
                Puntos de Cobro
              </h1>
            </div>
          </div>
          <div className="mt-0 mb-3">
            <p className="text-sm text-gray-500 ">
              Listado de los Puntos de Cobro de la Empresa
            </p>
          </div>
          {loadingPuntos ? <Loading color="mainBlueLight" /> : null}
          {puntos && puntos.length > 0
            ? puntos.map((p) => (
                <div
                  className={
                    "border rounded p-3 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
                  }
                  key={p.PuntoCobro}
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      PUNTO DE COBRO
                    </p>
                    <p className="text-sm text-gray-500">{p.PuntoCobro}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">RECIBOS</p>
                    <p className="text-sm text-gray-500">{p.Recibos}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">SOCIOS</p>
                    <p className="text-sm text-gray-500">{p.Socios}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(p.Importe)}
                    </p>
                  </div>
                  <div className="lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      FECHA DESDE
                    </p>
                    <p className="text-sm text-gray-500">
                      {formatDate(p.FechaDesde)}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default PuntosDeCobro;
