import React, { useContext, useState } from "react";
import AdminContext from "../../../context/admin/usuarios/adminContext";

function ModalResetPass() {
  const adminContext = useContext(AdminContext);
  const {
    datosUsuario,
    setDatosUsuario,
    setMostrarModalReset,
    reseteandoPass,
    resetearPassword,
  } = adminContext;
  const [repetirPass, setRepetirPass] = useState("");
  const [validado, setValidado] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [textoAlerta, setTextoAlerta] = useState("");

  const updatePass = (e) => {
    if (e.target.name === "repetirPass") {
      setRepetirPass(e.target.value);
    } else {
      var newDatosUsuario = { ...datosUsuario };
      newDatosUsuario.password = e.target.value;
      setDatosUsuario(newDatosUsuario);
    }
  };

  const validarDatos = () => {
    setMostrarAlerta(false);
    if (datosUsuario.password === "" || repetirPass === "") {
      setTextoAlerta("Por favor, completá los dos campos");
      setMostrarAlerta(true);
      setValidado(false);
      return false;
    }
    if (datosUsuario.password !== repetirPass) {
      setTextoAlerta("Las contraseñas no coinciden");
      setMostrarAlerta(true);
      setValidado(false);
      return false;
    }
    setValidado(true);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 my-3 sm:p-6 sm:pb-4">
            {mostrarAlerta ? (
              <div className="flex justify-start p-2 mb-4 text-white bg-yellow-500 rounded py-1">
                <div className="flex flex-row items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <p className="mt-0.5 text-md">{textoAlerta}</p>
                </div>
              </div>
            ) : null}

            <div className="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-12 w-12 mb-1 text-red-400"
              >
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div>
              <h2 className="font-bold text-xl text-center">
                Estás por resetear la contraseña de
              </h2>
              <h3 className="font-bold text-xl text-center text-red-500">
                {datosUsuario.nombreUsuario}
              </h3>
              <p className="text-lg text-center text-gray-600">
                <br></br>
                Si realmente querés hacerlo, completá los campos, validalos y
                hacé click en
                <br></br>
                <b>Resetear Contraseña</b>
              </p>
            </div>
          </div>
          <div className="flex flex-col flex-wrap pt-0 px-8 lg:px-20 py-4 pb-6">
            <div className="w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
              <div className="flex -mr-px w-15 px-1">
                <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <input
                type="password"
                name="password"
                id="password"
                value={datosUsuario.password}
                className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                placeholder="Contraseña"
                onChange={(e) => updatePass(e)}
              />
            </div>
            <div className="w-full mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
              <div className="flex -mr-px w-15 px-1">
                <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <input
                type="password"
                name="repetirPass"
                id="repetirPass"
                value={repetirPass}
                className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                placeholder="Repetir Contraseña"
                onChange={(e) => updatePass(e)}
              />
            </div>
          </div>
          <div className="bg-gray-50 flex justify-between p-4">
            <button
              className={
                reseteandoPass // Resetando password
                  ? "px-4 py-1 text-white bg-gray-500 rounded flex items-center lg:m-0"
                  : validado
                  ? "px-4 py-1 text-white bg-green-400 rounded flex items-center lg:m-0"
                  : "px-4 py-1 text-white bg-mainBlueLight rounded flex items-center lg:m-0"
              }
              onClick={
                validado
                  ? () => {
                      resetearPassword();
                    }
                  : () => {
                      validarDatos();
                    }
              }
            >
              {reseteandoPass ? ( //Reseteando password
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-white animate-spin h-6 w-6 mr-3"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : validado ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 mr-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : null}
              {reseteandoPass
                ? "Procesando"
                : validado
                ? "Resetear Contraseña"
                : "Validar Datos"}{" "}
              {/* Resteando password */}
            </button>
            <button
              type="button"
              className="px-4 py-1font-bold text-white bg-red-500 rounded flex items-center lg:mb-0"
              /*               onClick={() => {
                setObservacionesArchivo("");
                setArchivoPreProcesado([]);
                setMostrarModalAceptar(false);
              }} */
              onClick={() => {
                setDatosUsuario({
                  idUsuario: null,
                  nombreUsuario: "",
                  tipoUsuario: "",
                  asociadoA: "",
                  asociadoANombre: "",
                  //formulario
                  username: "",
                  password: "",
                  email: "",
                  canEditDate: "1",
                  descripcion: "",
                });
                setMostrarModalReset(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalResetPass;
