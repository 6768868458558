import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";

import Header from "../Header";
import Loading from "../Loading";
import BuscadorCobros from "./BuscadorCobros";
import ResultadoPersona from "./ResultadoPersona";
import FacturasCliente from "./FacturasCliente";
import SeleccionadasPagar from "./SeleccionadasPagar";
import PagoProcesado from "./PagoProcesado";

function NuevoCobro() {
  const authContext = useContext(AuthContext);
  const { getUser } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const {
    resultadoPersona,
    facturasCliente,
    filtrarFacturas,
    facturaSeleccionada,
    seleccionadasPagar,
    recibosCliente,
    loadingState,
    setLoadingState,
    setTipoBusqueda
  } = cobrosContext;

  useEffect(() => setTipoBusqueda("cobros"));

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      //Ejecuta el filtro con cada seleccion de factura para sacarla de la lista.
      filtrarFacturas();
      setLoadingState(false);
    }
    return () => {
      mounted = false;
    };
  }, [facturaSeleccionada]);

  return (
    <div className="p-4">
      <Header />

      {recibosCliente.length > 0 ? <PagoProcesado /> : null}
      <div className="lg:grid lg:grid-cols-4 lg:gap-4">
        <BuscadorCobros />
        <div className="col-span-3">
          {loadingState ? <Loading color="mainBlueLight" /> : null}
          {resultadoPersona.length > 0 ? <ResultadoPersona /> : null}
          {facturasCliente.length > 0 ? <FacturasCliente /> : null}
          {seleccionadasPagar.length > 0 ? <SeleccionadasPagar /> : null}
        </div>
      </div>
    </div>
  );
}

export default NuevoCobro;
