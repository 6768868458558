import React, { useContext, useEffect, useState } from "react";
import EmpresasContext from "../../../context/admin/empresas/empresasContext";
import Loading from "../../Loading";
import { Formik } from "formik";

function ModalEditar() {
  const empresasContext = useContext(EmpresasContext);
  const {
    setMostrarModalEditar,
    editarEmpresa,
    idEmpresa,
    empresas,
    setIdEmpresa,
  } = empresasContext;

  const [empresa, setEmpresa] = useState(false);

  useEffect(() => {
    var empresaActual = empresas.filter((emp) => emp.id === idEmpresa)[0];
    setEmpresa(empresaActual);
  }, []);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {empresa ? (
            <div>
              <div className="bg-white px-4 my-3 sm:p-6 sm:pb-4">
                <div className="flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 mb-1 text-mainBlueLight"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </div>
                <div>
                  <h2 className="font-bold text-xl text-center">
                    Estás modificando a la Empresa
                  </h2>
                  <h3 className="font-bold text-xl text-center text-mainBlueLight">
                    {empresa.nombre}
                  </h3>
                  <p className="text-lg text-center text-gray-600">
                    <br></br>
                    Cuando termines de hacerlo, hacé click en{" "}
                    <b>Modificar Empresa</b>
                  </p>
                </div>
              </div>
              <Formik
                initialValues={{
                  idEmpresa: empresa.id,
                  nombre: empresa.nombre,
                  tipoDoc: empresa.tipoDoc,
                  nroDoc: empresa.nroDoc,
                  direccion: empresa.direccion,
                  descripcion: empresa.descripcion,
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.nombre) {
                    errors.nombre = "Requerido";
                  }
                  if (!values.tipoDoc) {
                    errors.tipoDoc = "Requerido";
                  }
                  if (!values.nroDoc) {
                    errors.nroDoc = "Requerido";
                  }
                  if (!values.direccion) {
                    errors.direccion = "Requerido";
                  }
                  if (!values.descripcion) {
                    errors.descripcion = "Requerido";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  editarEmpresa(values).then(() => setSubmitting(false));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,

                  /* and other goodies */
                }) => (
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div className="flex flex-col flex-wrap lg:grid lg:grid-cols-2 lg:gap-4 pt-0 px-8 lg:px-20 py-4 pb-6">
                        <div className="flex flex-col">
                          <div
                            className={
                              errors.nombre && touched.nombre
                                ? "ring ring-red-500 w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                                : "w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                            }
                          >
                            <div className="flex -mr-px w-15 px-1">
                              <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              type="text"
                              name="nombre"
                              id="nombre"
                              value={values.nombre}
                              className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                              placeholder="Nombre"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <span className="text-red-600 mt-0.5">
                            {errors.nombre && touched.nombre && errors.nombre}
                          </span>
                        </div>

                        <input
                          type="hidden"
                          name="idEmpresa"
                          id="idEmpresa"
                          value={values.idEmpresa}
                        />

                        <div className="flex flex-col">
                          <div
                            className={
                              errors.tipoDoc && touched.tipoDoc
                                ? "ring ring-red-500 w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                                : "w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                            }
                          >
                            <div className="flex -mr-px w-15 px-1">
                              <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              type="text"
                              name="tipoDoc"
                              id="tipoDoc"
                              value={values.tipoDoc}
                              className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                              placeholder="Tipo de Documento"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <span className="text-red-600 mt-0.5">
                            {errors.tipoDoc &&
                              touched.tipoDoc &&
                              errors.tipoDoc}
                          </span>
                        </div>

                        <div className="flex flex-col">
                          <div
                            className={
                              errors.nroDoc && touched.nroDoc
                                ? "ring ring-red-500 w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                                : "w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                            }
                          >
                            <div className="flex -mr-px w-15 px-1">
                              <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M9.243 3.03a1 1 0 01.727 1.213L9.53 6h2.94l.56-2.243a1 1 0 111.94.486L14.53 6H17a1 1 0 110 2h-2.97l-1 4H15a1 1 0 110 2h-2.47l-.56 2.242a1 1 0 11-1.94-.485L10.47 14H7.53l-.56 2.242a1 1 0 11-1.94-.485L5.47 14H3a1 1 0 110-2h2.97l1-4H5a1 1 0 110-2h2.47l.56-2.243a1 1 0 011.213-.727zM9.03 8l-1 4h2.938l1-4H9.031z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              type="number"
                              name="nroDoc"
                              id="nroDoc"
                              value={values.nroDoc}
                              className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                              placeholder="Número de Documento"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <span className="text-red-600 mt-0.5">
                            {errors.nroDoc && touched.nroDoc && errors.nroDoc}
                          </span>
                        </div>

                        <div className="flex flex-col">
                          <div
                            className={
                              errors.direccion && touched.direccion
                                ? "ring ring-red-500 w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                                : "w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                            }
                          >
                            <div className="flex -mr-px w-15 px-1">
                              <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              type="text"
                              name="direccion"
                              id="direccion"
                              value={values.direccion}
                              className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                              placeholder="Dirección"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>

                          <span className="text-red-600 mt-0.5">
                            {errors.direccion &&
                              touched.direccion &&
                              errors.direccion}
                          </span>
                        </div>

                        <div className="flex flex-col">
                          <div
                            className={
                              errors.descripcion && touched.descripcion
                                ? "ring ring-red-500 w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                                : "w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded"
                            }
                          >
                            <div className="flex -mr-px w-15 px-1">
                              <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              type="text"
                              name="descripcion"
                              id="descripcion"
                              value={values.descripcion}
                              className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                              placeholder="Descripción"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>

                          <span className="text-red-600 mt-0.5">
                            {errors.descripcion &&
                              touched.descripcion &&
                              errors.descripcion}
                          </span>
                        </div>
                      </div>
                      <div className="bg-gray-50 flex justify-between p-4">
                        <button
                          type="submit"
                          className={
                            isSubmitting // Dando de alta
                              ? "px-4 py-1 text-white bg-gray-500 rounded flex items-center lg:m-0"
                              : "px-4 py-1 text-white bg-green-400 rounded flex items-center lg:m-0"
                          }
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? ( // Dando de alta
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="text-white animate-spin h-5 w-5 mr-3"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="text-white h-6 w-6 mr-3"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          )}
                          {isSubmitting ? "Procesando" : "Modificar Empresa"}{" "}
                          {/* // Dando de alta */}
                        </button>
                        <button
                          type="button"
                          className="px-4 py-1font-bold text-white bg-red-500 rounded flex items-center lg:mb-0"
                          onClick={() => {
                            setIdEmpresa(null);
                            setMostrarModalEditar(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-5 w-5 mr-3"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Cancelar
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </Formik>
            </div>
          ) : (
            <div className="flex justify-center py-4">
              <Loading color="mainBlueLight" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalEditar;
