import { useContext, useEffect } from "react";
import BuscadorCobros from "../Cobros/BuscadorCobros";
import ResultadoPersona from "../Cobros/ResultadoPersona";
import ResultadosRecibos from "./ResultadosRecibos";
import CobrosContext from "../../context/cobros/cobrosContext";

import Header from "../Header";
import Loading from "../Loading";

function RecibosPagados() {
  const cobrosContext = useContext(CobrosContext);
  const {
    resultadoPersona,
    setRecibosPagadosCliente,
    recibosPagadosCliente,
    setTipoBusqueda,
    setLoadingState,
    loadingState,
    loadingRecibos,
    resetState,
    showAlertRecibos,
    setShowAlertRecibos,
    datosAlertRecibos,
    nombreClienteRecibos,
    setNombreClienteRecibos,
  } = cobrosContext;

  useEffect(() => {
    resetState();
    setLoadingState(false);
    setRecibosPagadosCliente([]);
    setNombreClienteRecibos({});
    setShowAlertRecibos(false);
    setTipoBusqueda("consultas");
  }, []);

  return (
    <div className="p-4">
      <Header />

      {/* {recibosCliente.length > 0 ? <PagoProcesado /> : null} */}
      <div className="lg:grid lg:grid-cols-4 lg:gap-4">
        <BuscadorCobros />
        <div className="col-span-3">
          {loadingState ? <Loading color="mainBlueLight" /> : null}
          {resultadoPersona.length > 0 ? <ResultadoPersona /> : null}
          {showAlertRecibos ? (
            <div
              className={`border border-r-0 border-t-0 border-b-0 border-l-8 border-${datosAlertRecibos.color}-${datosAlertRecibos.accentColor} flex items-center justify-between py-3 px-4 mb-4 bg-${datosAlertRecibos.color}-${datosAlertRecibos.bgColor} rounded shadow-md`}
            >
              <div>
                <h1 className="flex items-center text-left text-gray-800 font-bold">
                  {datosAlertRecibos.type === "warning" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 mr-2 text-yellow-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 mr-2 text-red-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}

                  <p className="pl-2 pr-2 lg:pl-0 lg:pr-0">
                    {datosAlertRecibos.message}
                  </p>
                </h1>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 cursor-pointer"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  onClick={() => setShowAlertRecibos(false)}
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          ) : null}
          {loadingRecibos ? (
            <Loading color="mainBlueLight" />
          ) : nombreClienteRecibos.nombre !== undefined ? (
            <ResultadosRecibos />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default RecibosPagados;
