import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import AlertContext from "../../context/alerts/alertContext";

import axiosClient from "../../config/axios";

function AnularReciboModal() {
  const cobrosContext = useContext(CobrosContext);
  const { setModalAnularRecibo, idRecibo, recibosUpdated, setRecibosUpdated } =
    cobrosContext;

  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [textoMotivo, setTextoMotivo] = useState("");
  const [procesandoAnulando, setProcesandoAnulado] = useState(false);

  const actualizarMotivo = (e) => {
    setTextoMotivo(e.target.value);
  };

  const anularReciboAPI = async () => {
    const data = {
      motivo: textoMotivo,
    };

    setProcesandoAnulado(true);

    await axiosClient
      .post(`v2/recibo_eliminar/${idRecibo}`, data, header)
      .then((response) => {
        setAlert("El recibo se anuló exitosamente", "green-300");
        setModalAnularRecibo(false);
        setProcesandoAnulado(false);
        setRecibosUpdated(!recibosUpdated);
      })
      .catch((err) => {
        setAlert("Error al anular el recibo", "red-300");
        setModalAnularRecibo(false);
        setProcesandoAnulado(false);
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 my-12 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-12 w-12 mb-1 text-red-400"
              >
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div>
              <h2 className="font-bold text-xl text-center">
                Estás por anular un recibo
              </h2>
              <p className="text-lg text-center text-gray-600">
                <b>Esta acción es permanente y no se puede deshacer</b>
                <br></br>
                Si realmente querés anular el recibo, completá el motivo y hacé
                click en <b>Anular Recibo</b>
              </p>
            </div>
            <div className="mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
              <div className="flex -mr-px w-15 px-1">
                <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </span>
              </div>
              <input
                type="text"
                name="buscar"
                id="buscar"
                className="w-100 bg-gray-200 flex-shrink flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                placeholder="Motivo *"
                onChange={(e) => actualizarMotivo(e)}
                disabled={procesandoAnulando}
              />
            </div>
          </div>
          <div className="bg-gray-50 flex justify-between p-4">
            <button
              disabled={textoMotivo === ""}
              className={
                textoMotivo === ""
                  ? "px-4 py-1 font-bold text-white cursor-not-allowed bg-gray-400 rounded flex items-center lg:m-0"
                  : "px-4 py-1 font-bold text-gray-800 bg-yellow-400 rounded flex items-center lg:m-0"
              }
              onClick={() => {
                anularReciboAPI();
              }}
            >
              {procesandoAnulando ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-white animate-spin h-5 w-5 mr-3"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className={
                    textoMotivo === ""
                      ? "h-6 w-6 mr-2 text-white"
                      : "h-6 w-6 mr-2 text-red-500"
                  }
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              )}

              {procesandoAnulando ? "Procesando" : "Anular Recibo"}
            </button>
            <button
              type="button"
              className="px-4 py-1font-bold text-white bg-red-500 rounded flex items-center lg:mb-0"
              onClick={() => setModalAnularRecibo(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnularReciboModal;
