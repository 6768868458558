import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import DepositosContext from "../../context/depositos/depositosContext";
import AlertContext from "../../context/alerts/alertContext";

import axiosClient from "../../config/axios";

function DepositosAltaModal() {
  const depositosContext = useContext(DepositosContext);
  const { setModalAltaDeposito, dataAltaModalDeposito } = depositosContext;

  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  console.log(dataAltaModalDeposito);

  const cambiarEstadoDeposito = async () => {

    const data = {
      id_deposito: dataAltaModalDeposito
    }

    await axiosClient
      .post(`v2/cambiar_estado_deposito/${dataAltaModalDeposito}`, data, header)
      .then((response) => {
        setAlert("Depósito dado de alta correctamente", "green-300");
        setModalAltaDeposito(false);
      })
      .catch((err) => {
        setAlert("Error al dar de alta el depósito", "red-300");
        setModalAltaDeposito(false)
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 my-12 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-12 w-12 mb-1 text-red-400"
              >
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div>
              <h2 className="font-bold text-xl text-center">
                Estás por cambiar de estado un depósito
              </h2>
              <p className="text-lg text-center text-gray-600">
                Si estás seguro de querer continuar, y dejar el depósito como
                aceptado clickea en "Aceptar Depósito"
              </p>
            </div>
          </div>
          <div className="bg-gray-50 flex justify-between p-4">
            <button className="px-4 py-1 font-bold text-white bg-green-400 rounded flex items-center lg:m-0" onClick={cambiarEstadoDeposito}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Aceptar Depósito
            </button>
            <button
              type="button"
              className="px-4 py-1font-bold text-white bg-red-500 rounded flex items-center lg:mb-0"
              onClick={() => setModalAltaDeposito(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositosAltaModal;
