import React, { useReducer, useState, useContext } from "react";
import adminContext from "./adminContext";
import adminReducer from "./adminReducer";
import AuthContext from "../../auth/authContext";
import AlertContext from "../../alerts/alertContext";
import axiosClient from "../../../config/axios";

const AdminState = (props) => {
  const initialState = {};
  const [state, dispatch] = useReducer(adminReducer, initialState);

  const authContext = useContext(AuthContext);
  const { header, logoutUser } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [mainLoading, setMainLoading] = useState(true);
  const [loadingUsuarios, setLoadingUsuarios] = useState(true);
  const [reseteandoPass, setReseteandoPass] = useState(false);
  const [modificandoUsuario, setModificandoUsuario] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [mostrarModalInfo, setMostrarModalInfo] = useState(false);
  const [mostrarModalAlta, setMostrarModalAlta] = useState(false);
  const [mostrarModalReset, setMostrarModalReset] = useState(false);
  const [mostrarModalModificar, setMostrarModalModificar] = useState(false);
  const [datosUsuario, setDatosUsuario] = useState({
    idUsuario: null,
    nombreUsuario: "",
    tipoUsuario: "",
    asociadoA: "",
    asociadoANombre: "",
    //formulario
    username: "",
    password: "",
    email: "",
    canEditDate: "1",
    descripcion: "",
  });
  const [dandoDeAlta, setDandoDeAlta] = useState(false);

  const resetDatosUsuario = () => {
    setDatosUsuario({
      idUsuario: null,
      nombreUsuario: "",
      tipoUsuario: "",
      asociadoA: "",
      asociadoANombre: "",
      //formulario
      username: "",
      password: "",
      email: "",
      canEditDate: "1",
      descripcion: "",
    });
  };

  const resetState = () => {
    resetDatosUsuario();
    setMainLoading(true);
    setLoadingUsuarios(true);
    setReseteandoPass(false);
    setModificandoUsuario(false);
    setUsuarios([]);
    setMostrarModalAlta(false);
    setMostrarModalReset(false);
    setMostrarModalModificar(false);
  };

  const checkSelf = async () => {
    await axiosClient
      .get("v2/key/self", header)
      .then((response) => {
        if (
          response.data.data.grupos.some((roles) => roles === "ADMINISTRADOR")
        ) {
          setMainLoading(false);
        } else {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        resetState();
        logoutUser();
        console.log(err);
      });
  };

  const getUsuarios = async () => {
    setLoadingUsuarios(true);
    await axiosClient
      .get("lista_usuarios", header)
      .then((response) => {
        setUsuarios(response.data.data);
        setLoadingUsuarios(false);
      })
      .catch((err) => {
        resetState();
        console.log(err);
        logoutUser();
      });
  };

  const getUsuario = async () => {
    await axiosClient
      .get("get_usuario/" + datosUsuario.idUsuario, header)
      .then((response) => {
        var newDatosUsuario = { ...datosUsuario };
        newDatosUsuario.idUsuario = response.data.data.id;
        newDatosUsuario.email = response.data.data.email;
        newDatosUsuario.username = response.data.data.username;
        newDatosUsuario.tipoUsuario = response.data.data.grupo;
        if (response.data.data.grupo !== "EMPRESA") {
          newDatosUsuario.descripcion = response.data.data.descripcion;
          newDatosUsuario.canEditDate = "1";
          newDatosUsuario.nombreUsuario = response.data.data.nombre;
        }
        setDatosUsuario(newDatosUsuario);
        console.log(response.data.data);
      })
      .catch((err) => {
        resetState();
        logoutUser();
        console.log(err);
      });
  };

  const altaUsuario = async () => {
    setDandoDeAlta(true);
    var payload = {
      email: datosUsuario.email,
      username: datosUsuario.username,
      password: datosUsuario.password,
      grupos: datosUsuario.tipoUsuario,
      tipoUsuario: datosUsuario.tipoUsuario,
    };
    if (datosUsuario.tipoUsuario !== "EMPRESA") {
      payload.canEditDate = datosUsuario.canEditDate;
      payload.idPunto = datosUsuario.asociadoA;
      payload.nombreCobrador = datosUsuario.nombreUsuario;
      payload.descripcionCobrador = datosUsuario.descripcion;
    } else {
      payload.idEmpresa = datosUsuario.asociadoA;
    }
    axiosClient
      .post("alta_usuario", payload, header)
      .then((response) => {
        if (response.data.error === false) {
          resetDatosUsuario();
          setDandoDeAlta(false);
          setMostrarModalAlta(false);
          setAlert("Usuario dado de alta exitosamente", "green-300");
          getUsuarios();
        } else {
          resetDatosUsuario();
          setDandoDeAlta(false);
          setMostrarModalAlta(false);
          setAlert("Hubo un error dando de alta el Usuario", "red-300");
        }
      })
      .catch((err) => {
        resetState();
        console.log(err);
        logoutUser();
      });
  };

  const modificarUsuario = async () => {
    setModificandoUsuario(true);
    var payload = {
      idUsuario: datosUsuario.idUsuario,
      descripcion: datosUsuario.descripcion,
      canEditDate: datosUsuario.canEditDate,
    };
    await axiosClient
      .put("modificar_datos_cobrador", payload, header)
      .then((response) => {
        if (response.data.error === false) {
          resetDatosUsuario();
          setModificandoUsuario(false);
          setMostrarModalModificar(false);
          setAlert("El usuario se modificó correctamente", "green-300");
        } else {
          resetDatosUsuario();
          setModificandoUsuario(false);
          setMostrarModalModificar(false);
          setAlert(
            "Se produjo un error modificando al usuario: " +
              response.data.message,
            "red-300"
          );
        }
      })
      .catch((err) => {
        resetState();
        console.log(err);
        logoutUser();
      });
  };

  const resetearPassword = () => {
    setReseteandoPass(true);
    var payload = {
      idUser: datosUsuario.idUsuario,
      email: datosUsuario.email,
      username: datosUsuario.nombreUsuario,
      password: datosUsuario.password,
    };
    axiosClient
      .post("reset_password", payload, header)
      .then((response) => {
        if (response.data.error === false) {
          resetDatosUsuario();
          setReseteandoPass(false);
          setMostrarModalReset(false);
          setAlert("La Contraseña se reseteó correctamente", "green-300");
        } else {
          resetDatosUsuario();
          setReseteandoPass(false);
          setMostrarModalReset(false);
          setAlert("Hubo un problema reseteando la contraseña", "red-300");
        }
      })
      .catch((err) => {
        resetState();
        console.log(err);
        logoutUser();
      });
  };

  return (
    <adminContext.Provider
      value={{
        mainLoading,
        setMainLoading,
        checkSelf,
        mostrarModalAlta,
        setMostrarModalAlta,
        datosUsuario,
        setDatosUsuario,
        altaUsuario,
        dandoDeAlta,
        setDandoDeAlta,
        getUsuarios,
        usuarios,
        setUsuarios,
        loadingUsuarios,
        setLoadingUsuarios,
        mostrarModalReset,
        setMostrarModalReset,
        reseteandoPass,
        setReseteandoPass,
        resetearPassword,
        modificandoUsuario,
        setModificandoUsuario,
        resetDatosUsuario,
        mostrarModalModificar,
        setMostrarModalModificar,
        getUsuario,
        modificarUsuario,
        mostrarModalInfo,
        setMostrarModalInfo,
      }}
    >
      {props.children}
    </adminContext.Provider>
  );
};

export default AdminState;
