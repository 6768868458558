import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";

import axiosClient from "../../config/axios";
import { v4 as uuidv4 } from "uuid";

import Loading from "../Loading";

function RendicionesDiarias() {
  const authContext = useContext(AuthContext);
  const { userID, header, fecha } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const { formatImporte } = cobrosContext;

  const [rendicionesDiarias, setRendicionesDiarias] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getRendicionesDiarias();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const getRendicionesDiarias = async () => {
    setLoading(true);
    const data = {
      idCobrador: userID,
      fecha: fecha,
    };
    await axiosClient
      .post(`v2/rendicion_diaria_cobrador`, data, header)
      .then((response) => {
        setRendicionesDiarias(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="p-4">
        <Loading color="mainBlueLight" />
      </div>
    );
  } else {
    return (
      <div className="p-4 bg-white shadow-lg rounded my-4">
        <h1 className="text-xl font-bold text-gray-800">
          Totales por medio de pago
        </h1>
        <p className="text-sm text-gray-500 mb-8">
          Totales por medio de pago de los cobros realizados hoy
        </p>
        <div className="grid grid-cols-4 gap-4">
          {rendicionesDiarias.length > 0
            ? rendicionesDiarias.map((r) => {
                switch (r.metodo) {
                  case "Efectivo":
                    return (
                      <div
                        key={uuidv4()}
                        className="bg-green-400 rounded p-4 flex text-white items-center"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-8 w-8 mr-6 text-green-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold text-xl">{r.metodo}</p>
                          <p className="text-lg">{formatImporte(r.total)}</p>
                        </div>
                      </div>
                    );
                  case "T.Debito":
                    return (
                      <div
                        key={uuidv4()}
                        className="bg-green-400 rounded p-4 flex text-white items-center"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-8 w-8 mr-6 text-green-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold text-xl">{r.metodo}</p>
                          <p className="text-lg">{formatImporte(r.total)}</p>
                        </div>
                      </div>
                    );
                  case "T.Credito":
                    return (
                      <div
                        key={uuidv4()}
                        className="bg-green-400 rounded p-4 flex text-white items-center"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-8 w-8 mr-6 text-green-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold text-xl">{r.metodo}</p>
                          <p className="text-lg">{formatImporte(r.total)}</p>
                        </div>
                      </div>
                    );
                  case "Mercado Pago":
                    return (
                      <div
                        key={uuidv4()}
                        className="bg-green-400 rounded p-4 flex text-white items-center"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-8 w-8 mr-6 text-green-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold text-xl">{r.metodo}</p>
                          <p className="text-lg">{formatImporte(r.total)}</p>
                        </div>
                      </div>
                    );
                  default:
                    return (
                      <div
                        key={uuidv4()}
                        className="bg-green-400 rounded p-4 flex text-white items-center"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-8 w-8 mr-6 text-green-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold text-xl">{r.metodo}</p>
                          <p className="text-lg">{formatImporte(r.total)}</p>
                        </div>
                      </div>
                    );
                }
              })
            : null}
        </div>
      </div>
    );
  }
}

export default RendicionesDiarias;
