import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";

import Header from "../Header";
import NotificacionItem from "./NotificacionItem";

function Home() {
  const authContext = useContext(AuthContext);
  const { getUser } = authContext;

  const [notificacionesEmp, setNotificacionesEmp] = useState([]);
  const [notificacionesFronti, setNotificacionesFronti] = useState([]);

  useEffect(() => {
    getUser();
    getNotificaciones("api1");
    getNotificaciones("api2");
  }, []);

  const getNotificaciones = (url) => {
    if (url === "api1") {
      setNotificacionesEmp([
        {
          id: 1,
          tipo: "Anuncio",
          texto: "Cambió una modalidad específica de los metodos de pago.",
          fecha: "26/09 13:36hs",
        },
        {
          id: 2,
          tipo: "Alerta",
          texto: "Su rendición no fue efectuada correctamente",
          fecha: "10/10 14:38hs",
        },
        {
          id: 3,
          tipo: "Completado",
          texto: "Ya se pueden realizar operaciones normalmente",
          fecha: "28/10 17:36hs",
        },
      ]);
    }

    if (url === "api2") {
      setNotificacionesFronti([
        {
          id: 1,
          tipo: "Anuncio",
          texto: "Se lanzó la nueva versión de la aplicación web",
          fecha: "20/09 11:54hs",
        },
        {
          id: 2,
          tipo: "Alerta",
          texto: "Estamos haciendo un mantenimiento del servidor.",
          fecha: "08/09 17:18hs",
        },
        {
          id: 3,
          tipo: "Completado",
          texto:
            "Se actualizó la aplicación, ya se pueden realizar operaciones normalmente",
          fecha: "30/11 09:17hs",
        },
      ]);
    }
  };

  return (
    <div className="p-4">
      <Header />
      <div className="bg-white rounded shadow-lg px-4 py-12 flex justify-center">
        <div>
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-10 h-10 text-yellow-400 mb-2"
            >
              <path
                fillRule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-center">Bienvenidos</h2>
          <p className="text-gray-600 text-lg">
            Ésta es la nueva y mejorada versión del sistema de cobros
          </p>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="p-4 bg-white shadow-lg rounded">
            <h1 className="text-xl font-bold text-gray-800">La Empresa</h1>
            <p className="text-sm text-gray-500 mb-8">
              Alertas y notificaciones de la empresa.
            </p>
            {notificacionesEmp.length > 0
              ? notificacionesEmp.map((n) => (
                  <NotificacionItem
                    key={n.id}
                    tipo={n.tipo}
                    texto={n.texto}
                    fecha={n.fecha}
                  />
                ))
              : null}
          </div>
          <div className="p-4 bg-white shadow-lg rounded">
            <h1 className="text-xl font-bold text-gray-800">Frontini</h1>
            <p className="text-sm text-gray-500 mb-8">
              Alertas y notificaciones de estado de la aplicación.
            </p>
            {notificacionesFronti.length > 0
              ? notificacionesFronti.map((n) => (
                  <NotificacionItem
                    key={n.id}
                    tipo={n.tipo}
                    texto={n.texto}
                    fecha={n.fecha}
                  />
                ))
              : null}
          </div>
        </div> */}
    </div>
  );
}

export default Home;
