import React, { useContext } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";

import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import RecuperarPass from "./components/Login/RecuperarPass";

import Cobros from "./components/Cobros/Cobros";
import NuevoCobro from "./components/Cobros/NuevoCobro";

import CierreCaja from "./components/CierreCaja/CierreCaja";
import GenerarCierre from "./components/CierreCaja/GenerarCierre";

import Depositos from "./components/Depositos/Depositos";
import NuevoDeposito from "./components/Depositos/NuevoDeposito";
import Empresa from "./components/Empresa/Empresa";
import Archivos from "./components/Archivos/Archivos";
import RecibosPagados from "./components/Consultas/RecibosPagados";
import PuntosDeCobro from "./components/Consultas/PuntosDeCobro";
import FacturasAdeudadas from "./components/Consultas/FacturasAdeudadas";

// Admin Routes
import ListadoUsuarios from "./components/Admin/Usuarios/Listado";
import ListadoEmpresas from "./components/Admin/Empresas/Listado";

import E404 from "./components/E404";
import Alert from "./components/Alert";

import AuthContext from "./context/auth/authContext";

function Routes() {
  const authContext = useContext(AuthContext);
  const { auth } = authContext;
  return (
    <Router>
      <div className="fixed right-0 top-0 mx-4 my-24">
        <Alert />
      </div>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/recuperar_password">
          <RecuperarPass />
        </Route>
        <ProtectedRoute path="/" component={Home} auth={auth} exact />
        <ProtectedRoute path="/cobros" component={Cobros} auth={auth} />
        <ProtectedRoute
          path="/nuevo-cobro"
          component={NuevoCobro}
          auth={auth}
        />
        <ProtectedRoute
          path="/cierre-de-caja"
          component={CierreCaja}
          auth={auth}
        />
        <ProtectedRoute
          path="/generar-cierre"
          component={GenerarCierre}
          auth={auth}
        />
        <ProtectedRoute path="/depositos" component={Depositos} auth={auth} />
        <ProtectedRoute
          path="/depositos-empresa"
          component={Empresa}
          auth={auth}
        />
        <ProtectedRoute
          path="/puntos-de-cobro"
          component={PuntosDeCobro}
          auth={auth}
        />
        <ProtectedRoute path="/socios" component={RecibosPagados} auth={auth} />
        <ProtectedRoute path="/archivos" component={Archivos} auth={auth} />
        <ProtectedRoute
          path="/nuevo-deposito"
          component={NuevoDeposito}
          auth={auth}
        />
        <ProtectedRoute
          path="/listado-empresas"
          component={ListadoEmpresas}
          auth={auth}
        />
        <ProtectedRoute
          path="/listado-usuarios"
          component={ListadoUsuarios}
          auth={auth}
        />
        <ProtectedRoute
          path="/facturas-adeudadas"
          component={FacturasAdeudadas}
          auth={auth}
        />
        <Route component={E404} />
      </Switch>
    </Router>
  );
}

export default Routes;
