import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import AlertContext from "../../context/alerts/alertContext";
import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";

import axiosClient from "../../config/axios";

import Header from "../Header";
import Loading from "../Loading";
import ModalAnularRecibo from "../Cobros/AnularReciboModal";

function GenerarCierre() {
  const authContext = useContext(AuthContext);
  const { getUser, userID, header, fecha, hora } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const {
    formatDate,
    formatImporte,
    resetState,
    modalAnularRecibo,
    setModalAnularRecibo,
    setIdRecibo,
    recibosUpdated,
  } = cobrosContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  let history = useHistory();

  const [rendicionesData, setRendicionesData] = useState([]);
  //Boton que activa/desactiva el form de cambio de fecha
  const [activarFecha, setActivarFecha] = useState(false);
  //state que trae la fecha del server o la modifica segun el form.
  const [fechaCierre, setFechaCierre] = useState(fecha);
  //data para el input de fecha que necesita formato especial
  const [fechaForm, setFechaForm] = useState("");
  //state que trae la hora del server o la modifica segun el form.
  const [horaCierre, setHoraCierre] = useState(hora);
  //total de los recibos a cerrar
  const [total, setTotal] = useState(0);

  const [observaciones, setObservaciones] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCierre, setLoadingCierre] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      //Limpia el state inicial con el cambio de componente.
      resetState();
      setFechaForm(formatFechaForm(fechaCierre));
      getRendicionesData();
    }
    return () => {
      mounted = false;
    };
  }, [fechaCierre, horaCierre, recibosUpdated]);

  //obtengo los datos de la página principal
  const getRendicionesData = async () => {
    setLoading(true);
    const data = {
      idCobrador: userID,
      fecha: fechaCierre,
      hora: horaCierre,
    };
    await axiosClient
      .post(`v2/rendicionProvisoria`, data, header)
      .then((response) => {
        setRendicionesData(response.data.data.recibos);
        setTotal(response.data.data.total);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //cambia con lo seleccionado en el form
  const cambiarFechaCierre = (e) => {
    setFechaCierre(formatFechaCierre(e.target.value));
  };

  //cambia con lo seleccionado en el form
  const cambiarHoraCierre = (e) => {
    setHoraCierre(e.target.value);
  };

  //modifica el campo de observaciones
  const changeObservaciones = (e) => {
    setObservaciones(e.target.value);
  };

  //formatea la fecha que se envia a la api
  const formatFechaCierre = (data) => {
    var date = new Date(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        date.getFullYear() +
        "/" +
        (month[1] ? month : "0" + month[0]) +
        "/" +
        (day[1] ? day : "0" + day[0]);
      return fecha;
    }
  };

  //formatea la data para el input de fecha.
  const formatFechaForm = (data) => {
    var date = new Date(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        date.getFullYear() +
        "-" +
        (month[1] ? month : "0" + month[0]) +
        "-" +
        (day[1] ? day : "0" + day[0]);
      return fecha;
    }
  };

  //generar cierre de caja
  const generarCierre = () => {
    setLoadingCierre(true);
    const data = {
      cobrador: userID,
      fecha: fechaCierre,
      hora: horaCierre,
      importe: total,
      observaciones: observaciones,
      recibos: rendicionesData,
    };
    axiosClient
      .post(`v2/nueva_rendicion`, data, header)
      .then((response) => {
        if (response.data.error === false) {
          setAlert(response.data.data, "green-300");
          history.push("/cierre-de-caja");
          setLoadingCierre(false);
        } else {
          setAlert(response.data.data, "red-300");
          setLoadingCierre(false);
        }
      })
      .catch((err) => {
        if (err.response.data.message === undefined) {
          setAlert(err.response.data.data, "red-300");
        } else {
          setAlert(err.response.data.message, "red-300");
        }
        console.log(err.message);
        setLoadingCierre(false);
      });
  };

  return (
    <div className="p-4">
      <Header />
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 bg-mainBlue shadow-lg rounded">
          <div className="lg:flex lg:items-start lg:justify-between lg:mb-0 mb-4">
            <div>
              <h1 className="text-xl font-bold text-white">
                Rendiciones a Cerrar
              </h1>
              <p className="text-sm text-gray-400 mb-8">
                Listado de las rendiciones a cerrar hasta el día de la fecha.
              </p>
            </div>
            <div>
              <Link
                to={"/cierre-de-caja"}
                className="bg-mainBlueLight rounded py-1 px-6 font-bold text-white"
              >
                Volver
              </Link>
            </div>
          </div>

          {loading ? (
            <Loading color="white" />
          ) : rendicionesData.length > 0 ? (
            rendicionesData.map((i) => (
              <div
                key={i.id}
                className={
                  i.importe !== 0
                    ? "bg-white shadow-lg rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
                    : "ring ring-red-500 bg-white shadow-lg rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
                }
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">PERSONA</p>
                  <p className="text-sm text-gray-500">{i.nombre}</p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">FECHA</p>
                  <p className="text-sm text-gray-500">
                    {formatDate(i.fecha)}{" "}
                    <span className="text-xs font-medium text-gray-800">
                      {i.hora}hs
                    </span>
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                  <p className="text-sm text-gray-500">
                    {formatImporte(i.importe)}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">EMPRESA</p>
                  <p className="text-sm text-gray-500">{i.empresa.nombre}</p>
                </div>
                {i.importe !== 0 ? (
                  <div className="lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                    <button
                      className="px-4 py-1 font-bold text-white bg-red-400 rounded flex items-center mr-2 mb-3"
                      onClick={() => {
                        setIdRecibo(i.id);
                        setModalAnularRecibo(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Anular Recibo
                    </button>
                  </div>
                ) : (
                  <div className="lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">ESTADO</p>
                    <span className="font-medium px-2 w-3/12 py-1 text-white bg-red-400 rounded flex justify-center items-center mr-2 mb-3">
                      Anulado
                    </span>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="rounded bg-green-400 p-4 shadow-lg">
              <p className="text-white text-md flex items-center justify-center font-bold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 mr-2"
                >
                  <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                  <path
                    fillRule="evenodd"
                    d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                Tus rendiciones están al día.
              </p>
            </div>
          )}

          {rendicionesData.length > 0 ? (
            <div>
              {activarFecha ? (
                <div className="lg:grid lg:grid-cols-2 lg:gap-4">
                  <div>
                    <label
                      htmlFor="entrega"
                      className="text-white uppercase font-bold text-xs"
                    >
                      Fecha de Cierre
                    </label>
                    <input
                      type="date"
                      name="fecha"
                      id="fecha"
                      className="block w-full rounded bg-white text-gray-500 p-2 shadow mb-2 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Ingresar Nueva Fecha"
                      value={fechaForm}
                      onChange={cambiarFechaCierre}
                    />
                    <p className="text-white text-sm font-light mb-4">
                      Cambiar la fecha si se necesita hacer un cierre antiguo.
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="entrega"
                      className="text-white uppercase font-bold text-xs"
                    >
                      Hora de Cierre
                    </label>
                    <input
                      type="time"
                      name="hora"
                      id="hora"
                      className="block w-full rounded bg-white text-gray-500 p-2 shadow mb-2 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Ingresar Nueva Fecha"
                      value={horaCierre}
                      onChange={cambiarHoraCierre}
                    />
                  </div>
                </div>
              ) : null}
              <div>
                <label
                  htmlFor="observaciones"
                  className="text-white uppercase font-bold text-xs"
                >
                  Observaciones
                </label>
                <input
                  type="text"
                  name="observaciones"
                  id="observaciones"
                  className="block w-full rounded bg-white text-gray-500 p-2 shadow mb-4 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Observaciones del cierre"
                  value={observaciones}
                  onChange={changeObservaciones}
                />
              </div>
              <div className="bg-mainBlueLight p-4 text-white rounded shadow-xl">
                <p className="text-sm uppercase font-light">TOTAL</p>
                <h3 className="text-2xl">{formatImporte(total)}</h3>
              </div>
              <div className="lg:flex lg:items-center mt-6">
                <button
                  className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center mr-2 mb-3"
                  onClick={() => setActivarFecha(!activarFecha)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 mr-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Modificar Fecha
                </button>
                <button
                  className="px-4 py-1 font-bold text-white bg-green-400 rounded flex items-center mr-2 mb-3"
                  onClick={generarCierre}
                  disabled={loadingCierre}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 mr-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Generar Cierre
                  {loadingCierre ? (
                    <svg
                      className="animate-spin ml-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                </button>
              </div>
            </div>
          ) : null}
          {modalAnularRecibo ? <ModalAnularRecibo /> : null}
        </div>
      </div>
    </div>
  );
}

export default GenerarCierre;
