import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth/authContext";

import frontiniLogo from "../assets/cardinal-color.svg";

function Nav() {
  const authContext = useContext(AuthContext);
  const { logoutUser, checkPermission, puntoCobro, checkGrupo } = authContext;
  const menuItems = [
    { nombre: "Inicio", link: "/", permiso: "VER_INICIO" },
    { nombre: "Cobros", link: "/cobros", permiso: "VER_COBROS" },
    {
      nombre: "Cierre de Caja",
      link: "/cierre-de-caja",
      permiso: "VER_COBROS",
    },
    { nombre: "Depósitos", link: "/depositos", permiso: "VER_RENDICION" },
    {
      nombre: "Depósitos",
      link: "/depositos-empresa",
      permiso: "VER_DEPOSITOS_EMPRESA",
    },
    { nombre: "Archivos", link: "/archivos", permiso: "VER_DEPOSITOS_EMPRESA" },
    {
      nombre: "Socios",
      link: "/socios",
      permiso: "VER_DEPOSITOS_EMPRESA",
    },
    {
      nombre: "Puntos de Cobro",
      link: "/puntos-de-cobro",
      permiso: "VER_DEPOSITOS_EMPRESA",
    },
    {
      nombre: "Facturas Adeudadas",
      link: "/facturas-adeudadas",
      permiso: "VER_DEPOSITOS_EMPRESA",
    },
    {
      nombre: "Usuarios",
      link: "/listado-usuarios",
      permiso: "ADMINISTRADOR",
    },
    {
      nombre: "Empresas",
      link: "/listado-empresas",
      permiso: "ADMINISTRADOR",
    },
    {
      nombre: "Ayuda",
      link: "http://tutorial.cardinal.frontiniyasoc.com.ar/docs/intro/",
      permiso: "VER_INICIO",
    },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const menuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const userMenuToggle = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  return (
    <div className="p-4">
      <nav className="bg-white shadow-md xl:rounded rounded-t">
        <div className="mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-mainBlueLight focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
                onClick={menuToggle}
              >
                <span className="sr-only">Abrir menu</span>
                {menuOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <img src={frontiniLogo} className="h-9" alt="frontini logo" />
              </div>
              <div className="hidden sm:block sm:ml-6 flex-wrap content-center">
                <div className="flex space-x-4 m-main-menu">
                  {menuItems.map((item) =>
                    checkPermission(item.permiso) ? (
                      <Link
                        key={item.nombre}
                        className="px-3 py-2 rounded-md font-medium text-gray-500 hover:text-white hover:bg-mainBlueLight transition duration-500 ease-in-out"
                        to={
                          item.nombre === "Ayuda"
                            ? {
                                pathname: item.link,
                              }
                            : item.link
                        }
                        target={item.nombre === "Ayuda" ? "_blank" : null}
                        rel={
                          item.nombre === "Ayuda" ? "noopener noreferrer" : null
                        }
                      >
                        {item.nombre}
                      </Link>
                    ) : null
                  )}
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="xl:block hidden">
                <div
                  className={
                    checkGrupo("EMPRESA")
                      ? "bg-green-400 shadow-md text-white text-sm font-medium px-4 py-1 rounded-full flex items-center"
                      : checkGrupo("ADMINISTRADOR")
                      ? "bg-yellow-500 shadow-md text-white text-sm font-medium px-4 py-1 rounded-full flex items-center"
                      : "bg-purple-400 shadow-md text-white text-sm font-medium px-4 py-1 rounded-full flex items-center"
                  }
                >
                  {checkGrupo("ADMINISTRADOR") ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className={
                        checkGrupo("EMPRESA")
                          ? "h-5 w-5 mr-2 text-green-700"
                          : "h-5 w-5 mr-2 text-purple-700"
                      }
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}

                  {puntoCobro !== "undefined" ? puntoCobro : "Administrador"}
                </div>
              </div>
              <div className="ml-3 relative">
                <div>
                  <button
                    type="button"
                    onClick={userMenuToggle}
                    className="inline-flex justify-center w-full rounded-full shadow-md p-3 bg-mainBlueLight text-sm font-medium text-white focus:outline-none"
                    id="options-menu"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <span className="sr-only">Menu de usuario</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={
                    userMenuOpen
                      ? "transition ease-out duration-100 transform opacity-100 scale-100"
                      : "transition ease-in duration-75 transform opacity-0 scale-0"
                  }
                >
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      type="button"
                      onClick={logoutUser}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                      role="menuitem"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-5 w-5 mr-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      Desconectar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={menuOpen ? "block sm:hidden" : "hidden sm:hidden"}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {menuItems.map((item) =>
              checkPermission(item.permiso) ? (
                <Link
                  key={item.nombre}
                  className="block px-3 py-2 rounded-md font-medium text-gray-500 hover:text-white hover:bg-mainBlueLight"
                  to={item.link}
                >
                  {item.nombre}
                </Link>
              ) : null
            )}
          </div>
        </div>
      </nav>

      <div className="xl:hidden block">
        <div
          className={
            checkGrupo("EMPRESA")
              ? "bg-green-400 shadow-md text-white text-sm font-medium px-4 py-3 rounded-b flex items-center"
              : checkGrupo("ADMINISTRADOR")
              ? "bg-yellow-500 shadow-md text-white text-sm font-medium px-4 py-3 rounded-b flex items-center"
              : "bg-purple-400 shadow-md text-white text-sm font-medium px-4 py-3 rounded-b flex items-center"
          }
        >
          {checkGrupo("ADMINISTRADOR") ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={
                checkGrupo("EMPRESA")
                  ? "h-5 w-5 mr-2 text-green-700"
                  : "h-5 w-5 mr-2 text-purple-700"
              }
            >
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {puntoCobro !== "undefined" ? puntoCobro : "Administrador"}
        </div>
      </div>
    </div>
  );
}

export default Nav;
