import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import DepositosContext from "../../context/depositos/depositosContext";
import { Link } from "react-router-dom";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { useHistory } from "react-router-dom";

import axiosClient from "../../config/axios";

import Header from "../Header";
import Loading from "../Loading";
import DepositosModal from "./DepositosModal";

function Depositos() {
  const authContext = useContext(AuthContext);
  const { getUser, header, checkGrupo, userID, auth } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const { formatDate, formatImporte } = cobrosContext;

  const depositosContext = useContext(DepositosContext);
  const {
    resetState,
    setModalAltaDeposito,
    setDataAltaModalDeposito,
    setDataModalDeposito,
    setModalDeposito,
    modalDeposito,
    confirmarSubida,
    setInputs,
  } = depositosContext;

  let history = useHistory();

  const [homeData, setHomeData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    let mounted = true;
    window.scrollTo(0, 0);
    if (mounted) {
      if (confirmarSubida) {
        getUser();
        if (!checkGrupo("ENCARGADO")) {
          history.push("/");
        }
        resetState();
        getHomeData(source);
      }
    }
    return () => {
      mounted = false;
    };
  }, [confirmarSubida]);

  //obtengo los datos de la página principal
  const getHomeData = async (source) => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `depositos_realizados/${userID}`,
        header,
        {
          cancelToken: source.token,
        }
      );
      setHomeData(response.data.data);
      setLoading(false);
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("respuesta cancelada");
      } else {
        setLoading(false);
      }
    }
  };

  const handleFailSafe = (id) => {
    setModalAltaDeposito(true);
    setDataAltaModalDeposito(id);
  };

  const abrirModalDeposito = (data) => {
    //resetState()
    setDataModalDeposito(data);
    setModalDeposito(!modalDeposito);
  };

  return (
    <div className="p-4">
      <Header />
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 bg-white shadow-lg rounded">
          <div className="lg:flex lg:items-start lg:justify-between lg:mb-0 mb-4">
            <div>
              <h1 className="text-xl font-bold text-gray-800">
                Depositos Realizados
              </h1>
              <p className="text-sm text-gray-500 mb-8">
                Listado de los últimos depositos realizados.
              </p>
            </div>
            <div className="mb-5 mt-0 lg:mb-0 lg:mt-2">
              <Link
                to={"/nuevo-deposito"}
                className="bg-mainBlueLight rounded py-1 px-6 font-bold text-white"
              >
                Nuevo Depósito
              </Link>
            </div>
          </div>
          {loading ? <Loading color="mainBlueLight" /> : null}
          {homeData && homeData.length > 0
            ? homeData.map((d) => (
                <div
                  key={d.id}
                  className="border rounded p-4 mb-3 lg:grid lg:grid-cols-8 lg:gap-4"
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">FECHA</p>
                    <p className="text-sm text-gray-500">
                      {formatDate(d.fecha)}{" "}
                      <span className="text-xs font-medium text-gray-800">
                        {d.hora}hs
                      </span>
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">EMPRESA</p>
                    <p className="text-sm text-gray-500">{d.empresa}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">TOTAL</p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(d.importeTotal)}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">COMISION</p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(d.importeComision)}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      DEPOSITADO
                    </p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(d.importeDepositado)}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      OBSERVACIONES
                    </p>
                    <p className="text-sm text-gray-500">
                      {d.detalle !== null ? d.detalle : "No posee"}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">ESTADO</p>
                    {d.estado === "Aceptado" ? (
                      <p className="text-sm text-white">
                        <span className="px-4 py-1 bg-green-400 rounded">
                          {d.estado}
                        </span>
                      </p>
                    ) : d.estado === "Provisorio" ? (
                      <p className="text-sm text-white ">
                        <span className="px-3 bg-yellow-600 rounded">
                          {d.estado}
                        </span>
                      </p>
                    ) : (
                      <p className="text-sm text-white ">
                        <span className="px-3 bg-yellow-400 rounded">
                          {d.estado}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="lg:m-0 mb-2 flex items-center justify-end">
                    <button
                      className={
                        d.estado === "Provisorio"
                          ? "inline-flex rounded font-bold text-white px-4 py-1 bg-green-400"
                          : "inline-flex rounded font-bold text-white px-4 py-1 bg-gray-400 cursor-not-allowed"
                      }
                      disabled={d.estado !== "Provisorio"}
                      onClick={() => abrirModalDeposito(d)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5 mr-3 mt-0.5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Enviar Depósito
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      {modalDeposito ? <DepositosModal /> : null}
    </div>
  );
}

export default Depositos;
