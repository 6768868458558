import React, { useContext, useEffect } from "react";
import CobrosContext from "../../context/cobros/cobrosContext";

function PagoProcesado() {
  const cobrosContext = useContext(CobrosContext);
  const {
    formatDate,
    formatImporte,
    recibosCliente,
    pagoTerminado,
    imprimirTicket,
    imprimirTodosTickets,
  } = cobrosContext;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      //cuando se ejecuta el modulo mueve el scroll a 0
      window.scrollTo(0, 0);
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="p-4 bg-white rounded shadow-lg mb-3">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div>
          <h1 className="text-xl font-bold text-gray-800 flex justify-start items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 text-green-500 mr-1"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Pago Exitoso
          </h1>
          <p className="text-sm text-gray-500 mb-8">
            Listado de recibos generados
          </p>
        </div>
        <div className="lg:flex lg:items-center">
          <button
            className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center mr-2 mb-3"
            onClick={() => pagoTerminado()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 mr-3"
            >
              <path
                fillRule="evenodd"
                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Finalizar
          </button>
          <button
            className="px-4 py-1 font-bold text-white bg-green-400 rounded flex items-center mr-2 mb-3"
            onClick={() => imprimirTodosTickets()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 mr-3"
            >
              <path
                fillRule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clipRule="evenodd"
              />
            </svg>
            Imprimir Todos
          </button>
        </div>
      </div>
      {recibosCliente.map((r) => (
        <div
          className="border rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
          key={r.id}
        >
          <div className="lg:border-r lg:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">EMPRESA</p>
            <p className="text-sm text-gray-500">{r.empresa.nombre}</p>
          </div>
          <div className="lg:border-r lg:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">PERSONA</p>
            <p className="text-sm text-gray-500">{r.nombre}</p>
          </div>
          <div className="lg:border-r lg:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">FECHA DE PAGO</p>
            <p className="text-sm text-gray-500">{formatDate(r.fecha)}</p>
          </div>
          <div className="lg:border-r lg:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">PAGADO</p>
            <p className="text-sm text-gray-500">{formatImporte(r.importe)}</p>
          </div>
          {/* { r.cliente.contacto !== null && r.cliente.contacto !== null  ? (
            <div className="lg:border-r lg:m-0 mb-2">
              <p className="text-sm font-bold text-gray-800">RECIBO ENVIADO</p>
              <p className="text-sm text-gray-500">
                <span className="bg-mainBlueLight text-white rounded font-bold px-3">
                  Mail:
                </span>
                {r.cliente.contacto}
              </p>
            </div>
          ) : null } */}
          <div className="lg:flex items-center lg:justify-end">
            <button
              className="px-4 py-1 font-bold text-white bg-green-400 rounded flex items-center lg:m-0 mt-5"
              onClick={() => imprimirTicket(r, false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                  clipRule="evenodd"
                />
              </svg>
              Imprimir
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PagoProcesado;
