import React, { useContext, useEffect, useState, Fragment } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import DepositosContext from "../../context/depositos/depositosContext";
import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";

import Header from "../Header";
import DepositosPunto from "./DepositosPunto";
import SeleccionadosDepositar from "./SeleccionadosDepositar";
import DepositosModal from "./DepositosModal";

function NuevoDeposito() {
  const authContext = useContext(AuthContext);
  const { getUser, checkGrupo } = authContext;

  const depositosContext = useContext(DepositosContext);
  const {
    filtrarCierres,
    cierreSeleccionado,
    seleccionadosDepositar,
    modalDeposito,
  } = depositosContext;

  let history = useHistory();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      if (!checkGrupo("ENCARGADO")) {
        history.push("/");
      }
      filtrarCierres();
    }
    return () => {
      mounted = false;
    };
  }, [cierreSeleccionado]);

  return (
    <div className="p-4">
      <Header />
      <div className="grid grid-cols-1 gap-4 mb-4">
        <div className="p-4 bg-white shadow-lg rounded ">
          <div className="lg:flex lg:items-start lg:justify-between lg:mb-0 mb-4">
            <div>
              <h1 className="text-xl font-bold text-gray-800">
                Nuevo depósito
              </h1>
              <p className="text-sm text-gray-400 mb-8">
                Listado de los cierres de caja listos para depositar.
              </p>
            </div>
            <div>
              <Link
                to={"/depositos"}
                className="bg-mainBlueLight rounded py-1 px-6 font-bold text-white"
              >
                Volver
              </Link>
            </div>
          </div>
          <DepositosPunto />
        </div>
      </div>
      {seleccionadosDepositar.length > 0 ? <SeleccionadosDepositar /> : null}
      {modalDeposito ? <DepositosModal /> : null}
    </div>
  );
}

export default NuevoDeposito;
