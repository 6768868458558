import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import DepositosContext from "../../context/depositos/depositosContext";

function VerImagenModal() {
  const authContext = useContext(AuthContext);
  const depositosContext = useContext(DepositosContext);
  const { dataImagen, setModalVerImagen } = depositosContext;
  const { header } = authContext;

  const [loading, setLoading] = useState(true);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full xl:w-3/4 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white border-b-2 px-4 pb-2 pt-4 sm:p-6 sm:pb-4 flex flex-row flex-wrap justify-between">
            <div className="flex flex-row pt-1">
              <div className="bg-mainBlueLight shadow-md rounded-full h-10 w-10 flex items-center justify-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="text-white h-5 w-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <h2 className="font-bold text-xl text-left mt-2 ml-3">
                {"Imagen " + dataImagen[0]}
              </h2>
            </div>
          </div>
          <div className="flex justify-center p-4">
            <img
              className="w-3/4"
              src={process.env.REACT_APP_AWS_URL +'depositos/'+dataImagen[1]}
            ></img>
          </div>
          <div className="bg-gray-100 flex justify-center p-4">
            <button
              type="button"
              className="px-4 py-1font-bold text-white bg-red-500 rounded flex items-center lg:mb-0"
              onClick={() => setModalVerImagen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerImagenModal;
