import React, { useContext, useState, useEffect } from "react";
import AdminContext from "../../../context/admin/usuarios/adminContext";
import Loading from "../../Loading";

function ModalModificar() {
  const adminContext = useContext(AdminContext);
  const {
    datosUsuario,
    setDatosUsuario,
    setMostrarModalModificar,
    modificandoUsuario,
    resetDatosUsuario,
    getUsuario,
    modificarUsuario,
  } = adminContext;
  const [validado, setValidado] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [textoAlerta, setTextoAlerta] = useState("");
  const [loading, setLoading] = useState(true);

  const updateValue = (e) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario[e.target.name] = e.target.value;
    setDatosUsuario(newDatosUsuario);
  };

  useEffect(() => {
    getUsuario().then(() => setLoading(false));
  }, []);

  const validarDatos = () => {
    if (datosUsuario.descripcion === "") {
      setTextoAlerta("La descripción no puede estar vacía");
      setMostrarAlerta(true);
      setValidado(false);
      return false;
    } else {
      setValidado(true);
      return true;
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 my-3 sm:p-6 sm:pb-4">
            {mostrarAlerta ? (
              <div className="flex justify-start p-2 mb-4 text-white bg-yellow-500 rounded py-1">
                <div className="flex flex-row items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <p className="mt-0.5 text-md">{textoAlerta}</p>
                </div>
              </div>
            ) : null}

            <div className="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 mb-1 text-mainBlueLight"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </div>
            <div>
              <h2 className="font-bold text-xl text-center">
                Estás modificando al Usuario
              </h2>
              <h3 className="font-bold text-xl text-center text-mainBlueLight">
                {datosUsuario.nombreUsuario}
              </h3>
              <p className="text-lg text-center text-gray-600">
                <br></br>
                Cuando termines de hacerlo, validá los datos y hacé click en
                <br></br>
                <b>Modificar Usuario</b>
              </p>
            </div>
          </div>
          {!loading ? (
            <div className="flex flex-col flex-wrap pt-0 px-8 lg:px-20 py-4 pb-6">
              <div className="w-full flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
                <div className="flex -mr-px w-15 px-1">
                  <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                      <path
                        fill-rule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <input
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  value={datosUsuario.descripcion}
                  className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                  placeholder="Descripción"
                  onChange={(e) => updateValue(e)}
                />
              </div>
              <div className="w-full mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100 items-center rounded">
                <div className="flex -mr-px w-15 px-1">
                  <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <select
                  name="canEditDate"
                  id="canEditDate"
                  className="w-100 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                  value={datosUsuario.canEditDate}
                  onChange={(e) => updateValue(e)}
                >
                  <option value="1">Puede editar Fecha</option>
                  <option value="0">No puede editar Fecha</option>
                </select>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Loading color="mainBlueLight" />
            </div>
          )}

          <div className="bg-gray-50 flex justify-between p-4">
            <button
              className={
                modificandoUsuario // Modificando Usuario
                  ? "px-4 py-1 text-white bg-gray-500 rounded flex items-center lg:m-0"
                  : validado
                  ? "px-4 py-1 text-white bg-green-400 rounded flex items-center lg:m-0"
                  : "px-4 py-1 text-white bg-mainBlueLight rounded flex items-center lg:m-0"
              }
              onClick={
                validado
                  ? () => {
                      modificarUsuario();
                    }
                  : () => {
                      validarDatos();
                    }
              }
            >
              {modificandoUsuario ? ( // Modificando Usuario
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-white animate-spin h-6 w-6 mr-3"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : validado ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 mr-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : null}
              {modificandoUsuario
                ? "Procesando"
                : validado
                ? "Modificar Usuario"
                : "Validar Datos"}{" "}
            </button>
            <button
              type="button"
              className="px-4 py-1font-bold text-white bg-red-500 rounded flex items-center lg:mb-0"
              onClick={() => {
                resetDatosUsuario();
                setMostrarModalModificar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalModificar;
