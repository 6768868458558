import React, { useState, useContext } from "react";
import AdminContext from "../../../../context/admin/usuarios/adminContext";
function SelectorTipo() {
  const adminContext = useContext(AdminContext);
  const { datosUsuario, setDatosUsuario } = adminContext;

  const updateTipo = (tipo) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.tipoUsuario = tipo;
    newDatosUsuario.asociadoA = "";
    newDatosUsuario.asociadoANombre = "";
    setDatosUsuario(newDatosUsuario);
  };

  return (
    <div>
      <div className="flex justify-center mt-2 lg:-mt-2">
        <p className="font-bold text-xl">Elegí el tipo de Usuario</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-center py-12">
        <button
          className={
            datosUsuario.tipoUsuario === "EMPRESA"
              ? "bg-green-400 text-white rounded px-4 py-1 mb-2 lg:mb-0 lg:mr-4 lg:ml-4"
              : "bg-mainBlueLight text-white rounded px-4 py-1 mb-2 lg:mb-0 lg:mr-4 lg:ml-4"
          }
          onClick={() => {
            updateTipo("EMPRESA");
          }}
        >
          EMPRESA
        </button>
        <button
          className={
            datosUsuario.tipoUsuario === "ENCARGADO"
              ? "bg-green-400 text-white rounded px-4 py-1 mb-2 lg:mb-0 lg:mr-4 lg:ml-4"
              : "bg-mainBlueLight text-white rounded px-4 py-1 mb-2 lg:mb-0 lg:mr-4 lg:ml-4"
          }
          onClick={() => {
            updateTipo("ENCARGADO");
          }}
        >
          ENCARGADO
        </button>
        <button
          className={
            datosUsuario.tipoUsuario === "COBRADOR"
              ? "bg-green-400 text-white rounded px-4 py-1 lg:mr-4 lg:ml-4"
              : "bg-mainBlueLight text-white rounded px-4 py-1 lg:mr-4 lg:ml-4"
          }
          onClick={() => {
            updateTipo("COBRADOR");
          }}
        >
          COBRADOR
        </button>
      </div>
    </div>
  );
}

export default SelectorTipo;
