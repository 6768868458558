import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";

function SeleccionadasPagar() {
  const authContext = useContext(AuthContext);
  const { fecha, datosCobrador, setFecha, checkPermission } = authContext;

  // const [errorMetodoPago, setErrorMetodoPago] = useState(false);

  const cobrosContext = useContext(CobrosContext);
  const {
    formatDate,
    formatImporte,
    calcularVencimiento,
    seleccionadasPagar,
    setPagoParcial,
    setPagoVerificado,
    pagoParcial,
    pagoVerificado,
    eliminarFacturasSelecionadas,
    eliminarFactura,
    updateImporteTotalVencimientos,
    importeTotal,
    seleccionadasParcialTotal,
    procesarPagoParcial,
    checkEstadoFactura,
    importeCliente,
    setImporteCliente,
    procesarPago,
    pagoCobrador,
    loadingState,
    setPagoCobrador,
    observaciones,
    setDataObservaciones,
    dataObservaciones,
    fechaInput,
    setMetodoPago,
  } = cobrosContext;

  const [activarParcial, setActivarParcial] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      var selectMetodoPago = document.getElementById("metodoPago");
      selectMetodoPago.selectedIndex = 0;
      setPagoCobrador(selectMetodoPago.options[0].text);
      setMetodoPago(selectMetodoPago.options[0].text);
      setPagoVerificado(true);
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setImporteCliente(parseFloat(e.target.value));
    } else {
      setImporteCliente("");
    }
  };

  const handleMetodoChange = (e) => {
    let filtrar = datosCobrador.filter((f) => f.descripcion === e.target.value);
    setPagoCobrador(filtrar);
    setMetodoPago(e.target.value);
  };

  const handlePagoParcial = (e) => {
    if (e.target.value !== "") {
      setPagoParcial(parseFloat(e.target.value));
      setPagoVerificado(false);
    } else {
      setPagoParcial("");
    }
  };

  const handleObservaciones = (e) => {
    setDataObservaciones(e.target.value);
  };

  const handleFecha = (e) => {
    setFecha(e.target.value);
    var nuevoImporte = 0;
    seleccionadasPagar.forEach((factura) => {
      if (Date.parse(e.target.value) > Date.parse(factura.fechaVencimiento1)) {
        nuevoImporte = parseFloat(nuevoImporte) + (parseFloat(factura.saldo) + parseFloat(factura.interes));
      } else {
        nuevoImporte = parseFloat(nuevoImporte) + (parseFloat(factura.saldo) + parseFloat(factura.interes));
      }
    });
    updateImporteTotalVencimientos(nuevoImporte);
  };

  return (
    <div className="p-4 bg-mainBlue rounded shadow-lg mb-3">
      <div>
        <h1 className="text-xl font-bold text-white">Procesar Pago</h1>
        <p className="text-sm text-gray-400 mb-8">
          Facturas seleccionadas para pagar
        </p>
      </div>
      {seleccionadasPagar.map((pago) => (
        <div className="shadow-xl" key={pago.id}>
          <div className="bg-gray-200 p-4 rounded-t flex items-center justify-between">
            <div>
              <p className="mb-1 font-bold text-gray-800">{pago.nombre}</p>
              <p className="text-xs font-bold text-white">
                <span className="bg-mainBlueLight rounded px-4 py-1 mr-1">
                  {pago.nombreempresa}
                </span>
                {pago.estado && pago.estado !== ""
                  ? checkEstadoFactura(pago.estado)
                  : null}
              </p>
            </div>
            <div>
              <button
                onClick={() => eliminarFactura(pago)}
                className="bg-red-500 rounded-lg p-2 font-bold text-sm text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="bg-white rounded-b p-4 mb-3" key={pago.numero}>
            <div className="lg:grid lg:grid-cols-5 lg:gap-4">
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">SERVICIO</p>
                <p className="text-sm text-gray-500">{pago.descripcion}</p>
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">VENCIMIENTO</p>
                {calcularVencimiento(fecha, pago.fechaVencimiento1) ? (
                  <p className="text-sm text-red-500">
                    {formatDate(pago.fechaVencimiento1)} +{" "}
                    {formatImporte(pago.interes)} INTERÉS
                  </p>
                ) : (
                  <p className="text-sm text-gray-500">
                    {formatDate(pago.fechaVencimiento1)}
                  </p>
                )}
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">
                  PUEDE PAGAR HASTA
                </p>
                <p className="text-sm text-gray-500">
                  {formatDate(pago.fechalimite)}
                </p>
              </div>

              <div className="mb-2">
                <p className="text-sm font-bold text-gray-800">DEUDA</p>
                {calcularVencimiento(fecha, pago.fechaVencimiento1) ? (
                  <p className="text-sm text-gray-500">
                    {formatImporte(pago.saldo + pago.interes)}
                  </p>
                ) : (
                  <p className="text-sm text-gray-500">
                    {formatImporte(pago.saldo)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="mt-6">
        <label
          htmlFor="metodoPago"
          className="text-white uppercase font-bold text-xs"
        >
          Método de pago
        </label>
        <select
          name="metodoPago"
          id="metodoPago"
          className="block w-full rounded bg-white text-gray-500 p-2 shadow mb-4 mt-1 focus:outline-none transition ease-in-out duration-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          onChange={handleMetodoChange}
          disabled={datosCobrador.length === 1}
        >
          {datosCobrador
            ? datosCobrador.map((dato) => (
                <option key={dato.descripcion} value={dato.descripcion}>
                  {dato.descripcion}
                </option>
              ))
            : null}
        </select>
      </div>

      <div className="mt-6">
        <label
          htmlFor="entrega"
          className="text-white uppercase font-bold text-xs"
        >
          Importe Entregado (*)
        </label>
        <input
          type="number"
          name="vuelto"
          id="vuelto"
          className="block w-full rounded bg-white text-gray-500 p-2 shadow mb-4 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          value={importeCliente}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
          min={0}
        />
        <p className="text-sm text-white mb-2">
          * El monto ingresado es solo para calcular el vuelto, no altera ningun
          valor.
        </p>
      </div>

      {observaciones ? (
        <div className="mt-6">
          <label
            htmlFor="entrega"
            className="text-white uppercase font-bold text-xs"
          >
            Observaciones
          </label>
          <textarea
            name="observaciones"
            id="observaciones"
            className="block w-full rounded bg-white text-gray-500 p-2 shadow mb-4 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            value={dataObservaciones}
            onChange={handleObservaciones}
            placeholder="Escribir las observaciones del pago"
          />
        </div>
      ) : null}

      <div className="my-4">
        <div className="py-2 px-3 rounded bg-gray-300 w-60 flex items-center">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-red-500"
              value={activarParcial}
              onChange={() => setActivarParcial(!activarParcial)}
            />
            <span class="text-gray-800 uppercase font-bold text-xs ml-2">
              Efectuar pago parcial
            </span>
          </label>
        </div>

        <div className="lg:grid lg:grid-cols-2 lg:gap-4">
          {checkPermission("EDITA_FECHA") ? (
            <div className="my-4">
              <label
                htmlFor="entrega"
                className="text-white uppercase font-bold text-xs"
              >
                Fecha de Pago
              </label>
              <input
                type="date"
                name="fecha"
                id="fecha"
                className="block w-full rounded bg-white text-gray-500 p-2 shadow mb-2 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                value={fechaInput}
                onChange={handleFecha}
                placeholder="Ingresar Nueva Fecha"
              />
              <p className="text-white text-sm font-light mb-4">
                Cambiar la fecha altera el vencimiento de las facturas.
              </p>
            </div>
          ) : null}

          {activarParcial ? (
            <div className="my-4">
              <label
                htmlFor="entrega"
                className="text-white uppercase font-bold text-xs"
              >
                Monto del pago parcial (*)
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="number"
                  name="parcial"
                  id="parcial"
                  value={pagoParcial}
                  onChange={handlePagoParcial}
                  //onFocus={() => setPagoVerificado(false)}
                  onWheel={(e) => e.target.blur()}
                  min={0}
                  className="block w-full rounded bg-gray-300 text-gray-500 p-2 shadow mb-4 mt-1 focus:outline-none transition ease-in-out duration-300 focus:bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <button
                    onClick={() => procesarPagoParcial(seleccionadasPagar)}
                    className="bg-mainBlueLight font-bold text-white py-2 px-4 rounded-r flex items-center"
                  >
                    Verificar Pago Parcial
                  </button>
                </div>
              </div>
              <p className="text-sm text-white mb-2">
                * Solo utilizar y verificar si se va a hacer un pago parcial
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {importeTotal > 0 ? (
        <div className="bg-mainBlueLight p-4 text-white rounded shadow-xl">
          <p className="text-sm uppercase font-light">TOTAL</p>
          <h3 className="text-2xl">{formatImporte(importeTotal)}</h3>

          <div>
            <div className="border-b border-white my-2"></div>
            <p className="text-sm uppercase font-light">VUELTO</p>
            {importeCliente < importeTotal ? (
              <h3 className="text-2xl">$ 0,00</h3>
            ) : (
              <h3 className="text-2xl">
                {formatImporte(importeCliente - importeTotal)}
              </h3>
            )}
          </div>
        </div>
      ) : null}

      <div className="lg:flex lg:items-center mt-6">
        {pagoVerificado ? (
          <button
            className="px-4 py-1 font-bold text-white bg-green-400 rounded flex items-center mr-2 mb-3"
            onClick={() => procesarPago()}
            disabled={loadingState}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 mr-3"
            >
              <path
                fillRule="evenodd"
                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Procesar Pago
            {loadingState ? (
              <svg
                className="animate-spin ml-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : null}
          </button>
        ) : (
          <button
            className="px-4 py-1 font-bold text-white bg-gray-400 rounded flex items-center mr-2 mb-3"
            disabled
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 mr-3"
            >
              <path
                fillRule="evenodd"
                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Procesar Pago
          </button>
        )}

        <button
          onClick={() => eliminarFacturasSelecionadas()}
          className="px-4 py-1 font-bold text-white bg-red-500 rounded flex items-center mr-2 mb-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5 mr-3"
          >
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          Eliminar Todo
        </button>
      </div>
    </div>
  );
}

export default SeleccionadasPagar;
