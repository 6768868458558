import React, { Fragment, useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import cardinalLogo from "../../assets/cardinal-full-white.svg";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alerts/alertContext";
import axiosClient from "../../config/axios";
import { Formik } from "formik";

function RecuperarPass() {
  const authContext = useContext(AuthContext);
  const { resetPassword } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  let location = useLocation();
  const codigoUnico = new URLSearchParams(location.search).get("codigoUnico");
  const username = new URLSearchParams(location.search).get("username");
  const sitio = new URLSearchParams(location.search).get("sitio");
  const nombrePersona = new URLSearchParams(location.search).get(
    "nombrePersona"
  );

  const [mainLoading, setMainLoading] = useState(true);
  const [requestCompletado, setRequestCompletado] = useState(false);

  useEffect(() => {
    if (codigoUnico === null || username === null || sitio === null) {
      window.location.href = "/";
    } else {
      setMainLoading(false);
    }
  });

  const strongPasswordCheck = (plain_password) => {
    if (plain_password.length < 9) {
      return [false, "La contraseña debe contener al menos 9 caracteres"];
    }
    if (/\s/g.test(plain_password)) {
      return [false, "La contraseña no puede contener espacios"];
    }
    if (!/\d/.test(plain_password)) {
      return [false, "La contraseña debe contener al menos 1 número"];
    }
    var test_string = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!test_string.test(plain_password)) {
      return [false, "La contraseña debe contener al menos 1 símbolo especial"];
    }
    return [true, ""];
  };

  if (!mainLoading) {
    return (
      <Fragment>
        <div className="bg-indigo-900 flex login">
          <div className="flex-col flex ml-auto mr-auto items-center pt-40 w-full lg:w-2/4 md:w-3/5">
            <div className="flex justify-center mb-10 px-6">
              <img
                src={cardinalLogo}
                className="lg:h-24 h-16"
                alt="Logo Frontini y asoc"
              />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-2xl text-white mt-3 mb-6">
                Recuperación de Contraseña
              </h1>
              <h2 className="text-xl text-white mb-6 mt-1">
                ¡Hola, {nombrePersona === null ? username : nombrePersona}!
              </h2>
              <p className="text-md text-white mb-6 text-base w-3/4 text-center">
                Por favor completá los datos solicitados para recuperar tu
                contraseña
              </p>
            </div>
            <Formik
              initialValues={{ password: "", confirmar_password: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.password) {
                  errors.password = "Por favor, completá la contraseña";
                } else if (!strongPasswordCheck(values.password)[0]) {
                  errors.password = strongPasswordCheck(values.password)[1];
                }
                if (!values.confirmar_password) {
                  errors.confirmar_password =
                    "Por favor, completá la confirmación de la contraseña";
                }
                if (values.password && values.confirmar_password) {
                  if (values.password !== values.confirmar_password) {
                    errors.password = "Las contraseñas no coinciden";
                    errors.confirmar_password = "Las contraseñas no coinciden";
                  }
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                var payload = {
                  codigo: codigoUnico,
                  password: values.password,
                };
                await axiosClient
                  .post("reset_password_user", payload)
                  .then(() => {
                    setRequestCompletado(true);
                    setSubmitting(false);
                    setAlert(
                      "Contraseña restablecida exitosamente, se te redirigirá al login en 5 segundos",
                      "green-300"
                    );
                    setTimeout(() => {
                      if (sitio === "C") {
                        window.location.href = "/";
                      } else {
                        if (sitio === "CU") {
                          window.location.href =
                            "http://cardinal.usuarios.frontiniyasoc.com.ar/login";
                        }
                      }
                    }, 5000);
                  })
                  .catch(() => {
                    setSubmitting(false);
                    setAlert(
                      "Hubo un error procesando la solicitud",
                      "red-300"
                    );
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  className="mt-2 flex flex-col lg:w-1/2 w-full px-6"
                  onSubmit={handleSubmit}
                >
                  <div className="bg-yellow-500 rounded text-white p-2 mb-8 text-left text-base">
                    <p className="font-medium font-md">
                      La contraseña debe respetar el siguiente formato:
                    </p>
                    <div></div>
                    <div>• Longitud mínima de 9 caracteres</div>
                    <div>• No debe contener espacios</div>
                    <div>• Debe contener al menos un dígito del 0 al 9</div>
                    <div>
                      • Debe contener al menos uno de los siguientes símbolos
                      <div className="2xl:ml-3">
                        !@#$%^()_+\-=\[\]{}
                        ;':"\\|,.\/?~{" "}
                      </div>
                    </div>
                  </div>
                  <label
                    className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                    htmlFor="email"
                  >
                    Nueva Contraseña
                  </label>
                  <div
                    className={
                      errors.password && touched.password
                        ? "ring ring-red-600 flex flex-wrap items-stretch w-full relative h-15 bg-white items-center rounded mb-1.5 shadow-lg"
                        : "flex flex-wrap items-stretch w-full relative h-15 bg-white items-center rounded mb-1.5 shadow-lg"
                    }
                  >
                    <div className="flex -mr-px justify-center w-15 px-1 rounded">
                      <span className="flex items-center leading-normal bg-white px-2 border-0 text-2xl">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-5 w-5 text-mainBlue"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="flex-shrink flex-grow flex-auto leading-normal flex-1 border-0 rounded rounded-l-none h-12 px-3 self-center relative outline-none"
                      placeholder="Contraseña"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      disabled={isSubmitting || requestCompletado}
                    />
                  </div>
                  <span className="text-red-500 text-md mb-5">
                    {errors.password && touched.password && errors.password}
                  </span>

                  <label
                    className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                    htmlFor="password"
                  >
                    Confirmar Nueva Contraseña
                  </label>
                  <div
                    className={
                      errors.confirmar_password && touched.confirmar_password
                        ? "ring ring-red-600 flex flex-wrap items-stretch w-full mb-1.5 relative h-15 bg-white items-center rounded shadow-lg"
                        : "flex flex-wrap items-stretch w-full mb-1.5 relative h-15 bg-white items-center rounded shadow-lg"
                    }
                  >
                    <div className="flex -mr-px justify-center w-15 px-1">
                      <span className="flex items-center leading-normal bg-white px-2 border-0 text-2xl">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-5 w-5 text-mainBlue"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                    <input
                      type="password"
                      name="confirmar_password"
                      id="confirmar_password"
                      className="flex-shrink flex-grow flex-auto leading-normal flex-1 border-0 rounded rounded-l-none h-12 px-3 self-center relative outline-none"
                      placeholder="Confirmar Nueva Contraseña"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmar_password}
                      disabled={isSubmitting || requestCompletado}
                    />
                  </div>

                  <span className="text-red-500 text-md mb-5">
                    {errors.confirmar_password &&
                      touched.confirmar_password &&
                      errors.confirmar_password}
                  </span>

                  <button
                    type="submit"
                    disabled={isSubmitting || requestCompletado}
                    className="bg-mainBlueLight flex items-center justify-center shadow-lg uppercase font-medium py-4 text-center px-17 md:px-12 md:py-4 text-white rounded leading-tight text-lg mt-4 mb-20 transition duration-500 ease-in-out hover:bg-indigo-700"
                  >
                    Cambiar Contraseña
                    {isSubmitting ? (
                      <svg
                        className="animate-spin ml-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : null}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <div></div>;
  }
}

export default RecuperarPass;
