import React, { useEffect, useContext, useState, Fragment } from "react";

import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import DepositosContext from "../../context/depositos/depositosContext";
import Loading from "../Loading";

import axiosClient from "../../config/axios";

function DepositosPunto() {
  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const {
    formatDate,
    formatImporte
  } = cobrosContext;

  const depositosContext = useContext(DepositosContext);
  const {
    getCierresCaja,
    cierresPunto,
    loadingState,
    addDeposito,
    filtrarCierres,
    seleccionadosDepositar
  } = depositosContext;

  const [rendicionRecibos, setRendicionRecibos] = useState([]);
  const [recibosOpen, setRecibosOpen] = useState(false);
  const [rendicionID, setRendicionID] = useState(0);

  useEffect(() => {
    getCierresCaja();
    filtrarCierres();
  }, []);

  const getReciboDetalle = async (reciboID) => {
    await axiosClient
      .get(`v2/rendiciones/${reciboID}`, header)
      .then((response) => {
        setRecibosOpen(!recibosOpen);
        setRendicionRecibos(response.data.data.rendicion.recibos);
        setRendicionID(response.data.data.rendicion.id);
      })
      .catch((err) => {});
  };

  return (
    <div>
      {loadingState ? (
        <Loading color="mainBlueLight" />
      ) : cierresPunto.length > 0 ? (
        cierresPunto.map((i) => (
          <Fragment key={i.id}>
            <div
              className={
                rendicionID === i.id && recibosOpen
                  ? "border rounded-t border-b p-4 lg:grid lg:grid-cols-5 lg:gap-4"
                  : "border rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
              }
            >
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">COBRADOR</p>
                <p className="text-sm text-gray-500">{i.cobrador.nombre}</p>
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">FECHA</p>
                <p className="text-sm text-gray-500">{formatDate(i.fecha)} <span className="text-xs font-medium text-gray-800">{i.hora}hs</span></p>
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                <p className="text-sm text-gray-500">
                  {formatImporte(i.importe)}
                </p>
              </div>
              <div className="lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">ID CIERRE</p>
                <p className="text-sm text-gray-500">{i.id}</p>
              </div>
              <div className="lg:flex lg:items-center lg:justify-end">
                <button
                  className="px-4 py-1 font-bold text-white bg-green-500 rounded flex items-center lg:mt-0 mt-5 mr-3"
                  onClick={() => addDeposito(i)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 mr-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Seleccionar
                </button>

                <button
                  className={
                    rendicionID === i.id && recibosOpen
                      ? "px-4 py-1 font-bold text-white bg-red-500 rounded flex items-center lg:m-0 mt-5"
                      : "px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center lg:m-0 mt-5"
                  }
                  onClick={() => getReciboDetalle([i.id])}
                >
                  {rendicionID === i.id && recibosOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5 mr-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 mr-3"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}

                  {rendicionID === i.id && recibosOpen ? "Cerrar" : "Detalle"}
                </button>
              </div>
            </div>
            {rendicionID === i.id && recibosOpen ? (
              <div className="border border-t-0 rounded-b p-4 mb-4">
                <h1 className="text-xl font-bold text-gray-800 mb-4">
                  Recibos del cierre de caja:
                </h1>
                {rendicionRecibos.map((r) => (
                  <div
                    className="p-4 border rounded mb-4 lg:grid lg:grid-cols-4 lg:gap-4"
                    key={r.id}
                  >
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NUMERO</p>
                      <p className="text-sm text-gray-500">{r.id}</p>
                    </div>
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NOMBRE</p>
                      <p className="text-sm text-gray-500">{r.nombre}</p>
                    </div>
                    <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                      <p className="text-sm text-gray-500">
                        {formatImporte(r.importe)}
                      </p>
                    </div>
                    <div className="lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">FECHA</p>
                      <p className="text-sm text-gray-500">
                        {formatDate(r.fecha)}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="lg:flex lg:items-center lg:justify-end">
                  <button
                    className={
                      rendicionID === i.id && recibosOpen
                        ? "px-4 py-1 font-bold text-white bg-red-500 rounded flex items-center lg:m-0 mt-5"
                        : "px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center lg:m-0 mt-5"
                    }
                    onClick={() => getReciboDetalle([i.id])}
                  >
                    {rendicionID === i.id && recibosOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-5 w-5 mr-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5 mr-3"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                    {rendicionID === i.id && recibosOpen ? "Cerrar" : "Detalle"}
                  </button>
                </div>
              </div>
            ) : null}
          </Fragment>
        ))
      ) : (
        <div className="rounded bg-green-400 p-4 shadow-lg">
          <p className="text-white text-md flex items-center justify-center font-bold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 mr-2"
            >
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
              <path
                fillRule="evenodd"
                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            No hay cierres de caja para mostrar.
          </p>
        </div>
      )}
    </div>
  );
}

export default DepositosPunto;
