import React, { useState } from "react";
import { Formik } from "formik";
import axios from "axios";

function WidgetForm({sistema, usuario}) {
  const data = [
    {
      id: 1,
      color: "bg-blue-500",
      nombre: "Consulta",
      value: "consulta",
    },
    {
      id: 2,
      color: "bg-green-500",
      nombre: "Sugerencia",
      value: "sugerencia",
    },
    {
      id: 3,
      color: "bg-red-500",
      nombre: "Error",
      value: "errores",
    },
  ];

  const [selected, setSelected] = useState(data[0]);
  const [message, setMessage] = useState("");
  const [envioExitoso, setEnvioExitoso] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectionData = (number) => {
    setSelected(data[number]);
    setOpenSelector(!openSelector);
  };

  return (
    <div className="px-4 pt-8">
      <Formik
        initialValues={{ email: "", asunto: "", mensaje: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "El email es obligatorio";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Ingrese un correo válido";
          }
          if (!values.asunto) {
            errors.asunto = "El asunto del mail es obligatorio";
          }
          if (!values.mensaje) {
            errors.mensaje = "El mensaje es importante para poder ayudarte";
          }
          console.log(values);
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setLoading(true);
          const data = {
            mailUsuario: values.email,
            sistema: sistema,
            tipoReporte: selected.value,
            mensaje: values.mensaje,
            asunto: values.asunto,
            idUsuario: usuario,
          };
          axios
            .post(`http://api.frontiniyasoc.com.ar/api/recibir_mensaje`, data)
            .then((response) => {
              setLoading(false);
              setMessage(response.data.data);
              setEnvioExitoso(true);
              setSubmitting(true);
              resetForm({})
              setTimeout(() => {
                setEnvioExitoso(false);
              }, 3000);
            })
            .catch((err) => {
              console.log(err);
              setSubmitting(false);
              setLoading(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="email"
              className={
                errors.email && touched.email
                  ? "font-bold text-sm text-red-400"
                  : "font-bold text-sm text-gray-500"
              }
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={
                errors.email && touched.email
                  ? "p-2 rounded-lg border-2 border-red-500 text-red-600 focus:outline-none w-full font-medium mb-2"
                  : "p-2 rounded-lg border-2 border-gray-300 text-gray-600 focus:outline-none w-full font-medium mb-4"
              }
            />
            {errors.email && touched.email ? (
              <div className="text-red-400 text-sm mb-3">
                {errors.email && touched.email && errors.email}
              </div>
            ) : null}
            <label
              htmlFor="asunto"
              className={
                errors.asunto && touched.asunto
                  ? "font-bold text-sm text-red-400"
                  : "font-bold text-sm text-gray-500"
              }
            >
              Asunto
            </label>
            <input
              type="text"
              name="asunto"
              id="asunto"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.asunto}
              className={
                errors.asunto && touched.asunto
                  ? "p-2 rounded-lg border-2 border-red-500 text-red-600 focus:outline-none w-full font-medium mb-2"
                  : "p-2 rounded-lg border-2 border-gray-300 text-gray-600 focus:outline-none w-full font-medium mb-4"
              }
            />
            {errors.asunto && touched.asunto ? (
              <div className="text-red-400 text-sm mb-3">
                {errors.asunto && touched.asunto && errors.asunto}
              </div>
            ) : null}

            <label className="font-bold text-sm text-gray-500">
              Tipo de reporte
            </label>
            <div className="relative">
              <div
                className="p-2 rounded-lg border-2 border-gray-300 text-gray-600 focus:outline-none w-full mb-4 cursor-pointer font-medium"
                onClick={() => setOpenSelector(!openSelector)}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <div
                      className={selected.color + " h-3 w-3 rounded-full mr-2"}
                    ></div>
                    {selected.nombre}
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div
                className={
                  openSelector
                    ? "transition ease-in duration-100 transform scale-y-100 opacity-100"
                    : "transition ease-in duration-100 transform scale-y-0 opacity-0"
                }
              >
                <div className="origin-top-right absolute right-0 w-full border-2 border-gray-200 -mt-3 rounded-md shadow-lg bg-white focus:outline-none py-1">
                  {data.map((s, index) => (
                    <div
                      className="flex items-center px-3 py-1 hover:bg-gray-200 cursor-pointer font-medium text-gray-600"
                      key={s.id}
                      onClick={() => selectionData(index)}
                    >
                      <div
                        className={s.color + " h-3 w-3 rounded-full mr-2"}
                      ></div>
                      {s.nombre}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <label
              htmlFor="mensaje"
              className={
                errors.mensaje && touched.mensaje
                  ? "font-bold text-sm text-red-400"
                  : "font-bold text-sm text-gray-500"
              }
            >
              Mensaje
            </label>
            <textarea
              name="mensaje"
              id="mensaje"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mensaje}
              className={
                errors.mensaje && touched.mensaje
                  ? "p-2 rounded-lg border-2 border-red-500 text-red-600 focus:outline-none w-full font-medium mb-2"
                  : "p-2 rounded-lg border-2 border-gray-300 text-gray-600 focus:outline-none w-full font-medium mb-4"
              }
            ></textarea>
            {errors.mensaje && touched.mensaje ? (
              <div className="text-red-400 text-sm mb-3">
                {errors.mensaje && touched.mensaje && errors.mensaje}
              </div>
            ) : null}
            {envioExitoso ? (
              <div className="rounded-lg bg-green-300 border-green-500 border-2 p-3 flex items-center">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-green-600 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div>
                  <p className="text-green-600 font-medium">{message}</p>
                </div>
              </div>
            ) : null}
            <button
              type="submit"
              disabled={isSubmitting}
              className="mt-4 transition flex items-center justify-center duration-500 ease-in-out w-full bg-blue-700 hover:bg-indigo-500 rounded-lg px-6 py-2 font-bold text-white focus:outline-none"
            >
              Enviar
              {loading ? (
                <svg
                  className="animate-spin ml-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
            </button>
          </form>
        )}
      </Formik>
      <div className="text-sm text-center text-gray-400 pt-4 pb-2">
        <p>
          Powered by{" "}
          <a
            className="font-bold"
            target="_blank"
            rel="noreferrer"
            href="https://frontiniyasoc.com.ar/"
          >
            Frontini y Asoc.
          </a>
        </p>
      </div>
    </div>
  );
}

export default WidgetForm;
