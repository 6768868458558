import React, { useReducer, useContext, useState } from "react";
import archivosContext from "./archivosContext";
import archivosReducer from "./archivosReducer";
import axiosClient from "../../config/axios";
import AlertContext from "../alerts/alertContext";
import AuthContext from "../auth/authContext";

const ArchivosState = (props) => {
  const initialState = {};
  const [state, dispatch] = useReducer(archivosReducer, initialState);

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const authContext = useContext(AuthContext);
  const { name, userID, fecha, hora, header } = authContext;

  const [loading, setLoading] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [archivoPreProcesado, setArchivoPreProcesado] = useState([]);
  const [subiendoArchivo, setSubiendoArchivo] = useState(false);
  const [aceptandoArchivo, setAceptandoArchivo] = useState(false);
  const [rechazandoArchivo, setRechazandoArchivo] = useState(false);
  const [mostrarModalAceptar, setMostrarModalAceptar] = useState(false);
  const [mostrarModalRechazar, setMostrarModalRechazar] = useState(false);
  const [observacionesArchivo, setObservacionesArchivo] = useState("");
  const [datosPaginado, setDatosPaginado] = useState({
    perPage: 5,
    page: 1,
    totalRows: 0,
    indexInit: 1,
    indexFinish: 5,
    totalPages: 0,
  });

  const resetState = () => {
    setArchivos([]);
  };

  const altaArchivo = (payload) => {
    setLoading(true);
    setSubiendoArchivo(true);
    payload.append("empresa", 2);
    payload.append("usuario", userID);
    axiosClient
      .post("alta_archivo", payload, header)
      .then((response) => {
        if (!response.data.error && response.data.data.estado !== 5) {
          setArchivoPreProcesado(response.data.data);
          setSubiendoArchivo(false);
          getArchivosProcesados();
          setAlert("Archivo subido exitosamente", "green-300");
        } else {
          setArchivoPreProcesado({});
          setSubiendoArchivo(false);
          setLoading(false);
          if (response.data.data.estado !== 5) {
            setAlert("Hubo un problema subiendo el archivo", "red-300");
          } else {
            setAlert("El archivo no posee el formato correcto", "red-300");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setArchivoPreProcesado([]);
        setSubiendoArchivo(false);
        setLoading(false);
        setAlert("Hubo un problema subiendo el archivo", "red-300");
      });
  };

  const getArchivosProcesados = () => {
    setLoading(true);
    const payload = {
      idEmpresa: userID,
      cantidad: datosPaginado.perPage,
      pagina: datosPaginado.page,
    };
    axiosClient
      .post("get_archivos_procesados", payload, header)
      .then((response) => {
        var newDatosPaginado = { ...datosPaginado };
        newDatosPaginado.totalRows = response.data.data.cantidad;
        newDatosPaginado.totalPages = Math.ceil(
          response.data.data.cantidad / newDatosPaginado.perPage
        );
        if (response.data.data.cantidad < newDatosPaginado.perPage) {
          newDatosPaginado.indexFinish = response.data.data.cantidad;
        }
        setDatosPaginado(newDatosPaginado);
        if (response.data.data.maestro !== undefined) {
          setArchivos(response.data.data.maestro);
        } else {
          setArchivos([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setAlert(
          "Hubo un error recuperando los archivos procesados",
          "red-300"
        );
      });
  };

  const aceptarArchivo = () => {
    setLoading(true);
    setAceptandoArchivo(true);
    const payload = {
      idArchivo: archivoPreProcesado.id,
      observaciones: observacionesArchivo,
    };
    axiosClient.post("aceptar_archivo", payload, header).then((response) => {
      if (!response.data.error) {
        setArchivoPreProcesado([]);
        setMostrarModalAceptar(false);
        setObservacionesArchivo("");
        setAceptandoArchivo(false);
        getArchivosProcesados();
        setAlert("Archivo aceptado exitosamente", "green-300");
      } else {
        setAlert("Hubo un error aceptando el archivo", "red-300");
        setMostrarModalAceptar(false);
        setObservacionesArchivo("");
        setAceptandoArchivo(false);
        setLoading(false);
      }
    });
  };

  const rechazarArchivo = () => {
    setLoading(true);
    setRechazandoArchivo(true);
    const payload = {
      idArchivo: archivoPreProcesado.id,
      observaciones: observacionesArchivo,
    };
    axiosClient.post("rechazar_archivo", payload, header).then((response) => {
      if (!response.data.error) {
        setArchivoPreProcesado([]);
        setMostrarModalRechazar(false);
        setObservacionesArchivo("");
        setRechazandoArchivo(false);
        getArchivosProcesados();
        setAlert("Archivo rechazado exitosamente", "green-300");
      } else {
        setAlert("Hubo un error rechazando el archivo", "red-300");
        setMostrarModalRechazar(false);
        setObservacionesArchivo("");
        setRechazandoArchivo(false);
        setLoading(false);
      }
    });
  };

  return (
    <archivosContext.Provider
      value={{
        loading,
        setLoading,
        getArchivosProcesados,
        archivos,
        setArchivos,
        resetState,
        archivoPreProcesado,
        setArchivoPreProcesado,
        altaArchivo,
        subiendoArchivo,
        setSubiendoArchivo,
        aceptarArchivo,
        rechazarArchivo,
        mostrarModalAceptar,
        mostrarModalRechazar,
        setMostrarModalAceptar,
        setMostrarModalRechazar,
        aceptandoArchivo,
        setAceptandoArchivo,
        rechazandoArchivo,
        setRechazandoArchivo,
        observacionesArchivo,
        setObservacionesArchivo,
        datosPaginado,
        setDatosPaginado,
      }}
    >
      {props.children}
    </archivosContext.Provider>
  );
};

export default ArchivosState;
