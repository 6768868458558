import { useState, useContext } from "react";
import Header from "../Header";
import Loading from "../Loading";
import axiosClient from "../../config/axios";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import AlertContext from "../../context/alerts/alertContext";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

function PuntosDeCobro() {
  const authContext = useContext(AuthContext);
  const { header, userID } = authContext;
  const cobrosContext = useContext(CobrosContext);
  const { formatImporte } = cobrosContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [facturas, setFacturas] = useState([]);
  const [loadingFacturas, setLoadingFacturas] = useState(false);
  const [datos, setDatos] = useState({
    saldo: 0,
    dni: "",
  });

  const buscarFacturas = (e) => {
    e.preventDefault();
    if (datos.saldo === "") {
      setAlert("Por favor complete el monto mínimo de deuda", "red-300");
      return false;
    }
    setLoadingFacturas(true);
    axiosClient
      .post(
        "/v2/get_facturas_adeudadas",
        {
          empresa: userID,
          saldo: datos.saldo,
          dni: datos.dni,
        },
        header
      )
      .then((response) => {
        setFacturas(response.data.data);
        setLoadingFacturas(false);
      })
      .catch((err) => {
        console.log(err);
        setAlert(
          "Hubo un error recuperando las facturas, por favor intentá más tarde",
          "red-300"
        );
        setLoadingFacturas(false);
      });
  };

  const exportarAExcel = () => {
    if (facturas.length > 0 && !loadingFacturas) {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      var data = [];
      facturas.map((f) =>
        data.push({
          DNI: f.nroDoc,
          NOMBRE: f.nombre,
          SALDO: parseFloat(f.saldo),
        })
      );
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = {
        Sheets: { FacturasAdeudadas: worksheet },
        SheetNames: ["FacturasAdeudadas"],
      };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const preFile = new Blob([excelBuffer], { type: EXCEL_TYPE });
      saveAs(preFile, "Reporte_Facturas_Adeudadas" + EXCEL_EXTENSION);
    } else {
      setAlert("Se deben buscar facturas primero antes de exportar", "red-300");
    }
  };

  const updateValue = (e) => {
    if (e.target.name === "saldo") {
      if (isNaN(e.target.value)) {
        return false;
      }
    }
    var newDatos = { ...datos };
    newDatos[e.target.name] = e.target.value;
    setDatos(newDatos);
  };

  return (
    <div className="p-4">
      <Header />
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 bg-white shadow-lg rounded">
          <div className="lg:flex lg:items-start lg:justify-between mb-2">
            <div>
              <h1 className="text-xl font-bold text-gray-800">
                Facturas Adeudadas
              </h1>
              <div className="mt-0 mb-3">
                <p className="text-sm text-gray-500 ">
                  Listado de las Facturas que se adeudan a la Empresa
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-3/12 flex-wrap bg-mainBlue p-4 rounded mb-4">
            <form className="flex flex-col gap-4" onSubmit={buscarFacturas}>
              <div className="flex flex-col justify-start">
                <label className="font-bold text-white text-md" htmlFor="saldo">
                  Monto mínimo de deuda *
                </label>
                <input
                  className="rounded px-2 py-2"
                  id="saldo"
                  name="saldo"
                  placeholder="$ Monto"
                  onChange={(e) => updateValue(e)}
                  value={datos.saldo}
                ></input>
              </div>
              <div className="flex flex-col justify-start">
                <label className="font-bold text-white text-md" htmlFor="saldo">
                  DNI del deudor
                </label>
                <input
                  className="rounded px-2 py-2"
                  id="dni"
                  name="dni"
                  placeholder="DNI (opcional)"
                  onChange={(e) => updateValue(e)}
                  value={datos.dni}
                ></input>
              </div>
              <div className="flex flex-col justify-start items-start">
                <label
                  className="font-bold text-white text-md"
                  htmlFor="saldo"
                ></label>
                <button
                  className="rounded bg-mainBlueLight px-7 font-medium text-white py-2 text-center transition duration-500 ease-in-out hover:bg-blue-600"
                  type="submit"
                >
                  Buscar Facturas
                </button>
              </div>
            </form>
          </div>
          {loadingFacturas ? <Loading color="mainBlueLight" /> : null}
          {facturas && facturas.length && !loadingFacturas > 0 ? (
            <div className="flex justify-end mb-4">
              <button
                onClick={() => exportarAExcel()}
                className="bg-green-500 flex items-center flex-row text-white font-medium px-3 py-1 rounded justify-end"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-1 mb-0.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                    clip-rule="evenodd"
                  />
                </svg>
                Exportar a Excel
              </button>
            </div>
          ) : null}
          {facturas && facturas.length > 0
            ? facturas.map((f) => (
                <div
                  className={
                    "border rounded p-3 mb-3 lg:grid lg:grid-cols-3 lg:gap-4"
                  }
                  key={f.nroDoc.toString() + f.nombre + f.saldo}
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      NRO. DE DOCUMENTO
                    </p>
                    <p className="text-sm text-gray-500">{f.nroDoc}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      NOMBRE DEUDOR
                    </p>
                    <p className="text-sm text-gray-500">{f.nombre}</p>
                  </div>
                  <div className="lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">SALDO</p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(f.saldo)}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default PuntosDeCobro;
