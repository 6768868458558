import React, { useContext, useState, useEffect } from "react";
import CobrosContext from "../../context/cobros/cobrosContext";
import DepositosContext from "../../context/depositos/depositosContext";
import ImgUploader from "./ImgUploader";
import { v4 as uuidv4 } from "uuid";

function DepositosModal() {
  const cobrosContext = useContext(CobrosContext);
  const { formatImporte } = cobrosContext;

  const depositosContext = useContext(DepositosContext);
  const {
    dataModalDeposito,
    modalDeposito,
    setModalDeposito,
    imageError,
    setDepositoObservaciones,
    setInputs,
    depositoObservaciones,
    generarDeposito,
    loadingSubida,
    montoDepositar,
    setMontoDepositar,
    setNroComprobante,
    nroComprobante,
  } = depositosContext;

  const [montoError, setMontoError] = useState(true);

  useEffect(() => {
    
    setMontoDepositar(dataModalDeposito.depositar);
  }, []);

  //envio las observaciones al context
  const handleObservacionesChange = (e) => {
    setDepositoObservaciones(e.target.value);
  };

  //cuando se cancela el modal resetea el state al inicial
  const handleCancelarModal = () => {
    setModalDeposito(!modalDeposito);
    setDepositoObservaciones("");
    setInputs([{ title: uuidv4(), file: "" }]);
  };

  const handleNroComprobante = (e) => {
    const { value } = e.target;
    setNroComprobante(value);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h1 className="text-xl font-bold text-gray-800 mb-4">
              Confirmar Depósito
            </h1>
            <div className="p-4 rounded text-white bg-mainBlueLight mb-4">
              <div className="mb-2">
                <p className="text-sm font-bold">EMPRESA</p>
                <p className="text-sm">{dataModalDeposito.empresa}</p>
              </div>
              <div className="mb-2">
                <p className="text-sm font-bold">TOTAL</p>
                <p className="text-sm">
                  {formatImporte(dataModalDeposito.importeTotal)}
                </p>
              </div>
              <div className="mb-2">
                <p className="text-sm font-bold">COMISION</p>
                <p className="text-sm">
                  {formatImporte(dataModalDeposito.importeComision)}
                </p>
              </div>
              <div>
                <p className="text-sm font-bold">A DEPOSITAR</p>
                <p className="text-sm">
                  {formatImporte(dataModalDeposito.importeDepositado)}
                </p>
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="entrega" className="uppercase font-bold text-xs">
                Número de comprobante
              </label>
              <input
                type="text"
                name="nro"
                id="nro"
                min="0"
                className="block w-full border rounded bg-white text-gray-500 p-2 mb-4 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Ingresar número de comprobante"
                onChange={handleNroComprobante}
                value={nroComprobante}
              />
            </div>
            {/* <div className="mb-2">
              <label htmlFor="entrega" className="uppercase font-bold text-xs">
                Monto depositado
              </label>
              <input
                type="number"
                name="monto"
                id="monto"
                className="block w-full border rounded bg-white text-gray-500 p-2 mb-4 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Ingresar el monto total depositado"
                value={montoDepositar}
                onChange={handleMontoDepositar}
              />
              {!montoError ? (
                <p className="text-red-500 text-sm">
                  El monto se encuentra fuera del rango permitido por la
                  empresa.
                </p>
              ) : null}
            </div> */}
            <div className="mb-2">
              <label htmlFor="entrega" className="uppercase font-bold text-xs">
                Observaciones
              </label>
              <textarea
                name="observaciones"
                id="observaciones"
                className="block w-full border rounded bg-white text-gray-500 p-2 mb-4 mt-1 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Escribir las observaciones del deposito"
                onChange={handleObservacionesChange}
                value={depositoObservaciones}
              />
            </div>
            <div className="mb-2">
              <ImgUploader />
            </div>
            {imageError ? (
              <p className=" text-center text-sm font-light text-gray-500 mt-5">
                Debe adjuntar al menos un comprobante de depósito
              </p>
            ) : null}
          </div>
          <div className="bg-gray-50 lg:flex lg:items-center p-4">
            {!imageError && montoError ? (
              <button
                type="button"
                className={
                  loadingSubida
                    ? "px-4 mb-3 py-1 font-bold text-white bg-gray-400 rounded flex items-center mr-2 lg:mb-0 cursor-not-allowed"
                    : "px-4 mb-3 py-1 font-bold text-white bg-green-500 rounded flex items-center mr-2 lg:mb-0"
                }
                onClick={generarDeposito}
                disabled={loadingSubida}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 mr-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                Depositar
                {loadingSubida ? (
                  <svg
                    className="animate-spin ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : null}
              </button>
            ) : (
              <button
                type="button"
                className="px-4 mb-3 py-1 font-bold text-white bg-gray-500 rounded flex items-center mr-2 lg:mb-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 mr-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                Depositar
              </button>
            )}

            <button
              type="button"
              className="px-4 py-1 mb-3 font-bold text-white bg-red-500 rounded flex items-center mr-2 lg:mb-0"
              onClick={handleCancelarModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositosModal;
