import React, { useEffect, useState, useContext } from "react";
import Header from "../../Header";
import Loading from "../../Loading";
import AltaUsuario from "./ModalAlta/AltaUsuario";
import ModalReset from "./ModalResetPass";
import ModalModificar from "./ModalModificar";
import ModalInfo from "./ModalInfo";
import AdminContext from "../../../context/admin/usuarios/adminContext";

function Listado() {
  const adminContext = useContext(AdminContext);
  const {
    mainLoading,
    checkSelf,
    mostrarModalAlta,
    setMostrarModalAlta,
    mostrarModalReset,
    setMostrarModalReset,
    getUsuarios,
    usuarios,
    loadingUsuarios,
    setDatosUsuario,
    datosUsuario,
    mostrarModalModificar,
    setMostrarModalModificar,
    mostrarModalInfo,
    setMostrarModalInfo,
  } = adminContext;

  useEffect(() => {
    checkSelf().then(() => {
      getUsuarios();
    });
  }, []);

  useEffect(() => {}, [usuarios]);

  const [actionsDropdown, setActionsDropdown] = useState({});

  const handleActionsDropdown = (id) => {
    var newActionsDropdown = { ...actionsDropdown };
    newActionsDropdown[id] = !newActionsDropdown[id];
    for (var [key, value] of Object.entries(newActionsDropdown)) {
      if (key !== id) {
        newActionsDropdown[key] = false;
      }
    }
    setActionsDropdown(newActionsDropdown);
  };

  const mostrarModalDeReset = (usuario) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.idUsuario = usuario.id;
    newDatosUsuario.nombreUsuario = usuario.username;
    newDatosUsuario.email = usuario.email;
    setDatosUsuario(newDatosUsuario);
    setMostrarModalReset(true);
  };

  const mostrarModalDeModificar = (usuario) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.idUsuario = usuario.id;
    newDatosUsuario.nombreUsuario = usuario.username;
    setDatosUsuario(newDatosUsuario);
    setMostrarModalModificar(true);
  };

  const mostrarModalDeInfo = (userID) => {
    var newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.idUsuario = userID;
    setDatosUsuario(newDatosUsuario);
    setMostrarModalInfo(true);
  };

  if (!mainLoading && !loadingUsuarios) {
    return (
      <div className="p-4">
        <Header />
        <div className="grid grid-cols-1 gap-4">
          <div className="p-4 bg-white shadow-lg rounded">
            <div className="lg:flex lg:items-start lg:justify-between lg:mb-0 mb-4">
              <div>
                <h1 className="text-xl font-bold text-gray-800">Usuarios</h1>
                <p className="text-sm text-gray-500 mb-8">
                  Listado de todos los usuarios registrados
                </p>
              </div>
              <div className="mb-5 mt-0 lg:mb-0 lg:mt-2">
                <button
                  className={
                    false
                      ? "bg-gray-500 rounded py-1 px-6 font-bold text-white cursor-wait"
                      : "bg-mainBlueLight rounded py-1 px-6 font-bold text-white"
                  }
                  onClick={() => setMostrarModalAlta(true)}
                >
                  Nuevo Usuario
                </button>
              </div>
            </div>
            {loadingUsuarios ? <Loading color="mainBlueLight" /> : null}
            {!loadingUsuarios && usuarios.length > 0
              ? usuarios.map((u) => (
                  <div key={u.id}>
                    <div
                      key={u.id}
                      className={
                        "border rounded p-4 mb-3 lg:grid lg:grid-cols-5 lg:gap-4"
                      }
                    >
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800 ">
                          NOMBRE DE USUARIO
                        </p>
                        <p className="text-sm text-gray-500 overflow-ellipsis overflow-hidden">
                          {u.username}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">EMAIL</p>
                        <p className="text-sm text-gray-500">{u.email}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">GRUPO</p>
                        <p className="text-sm text-gray-500">{u.grupo}</p>
                      </div>
                      <div className="lg:border-r  lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          ESTADO
                        </p>
                        <p className="text-sm text-white">
                          <span className="px-4 py-0 bg-green-400 rounded">
                            Habilitado
                          </span>
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 flex items-center xl:justify-center">
                        <div className="relative">
                          <button
                            className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center lg:m-0 mt-5"
                            onClick={() => handleActionsDropdown(String(u.id))}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-6 w-6 mr-2 "
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Acciones
                          </button>
                          <div
                            className={
                              actionsDropdown[u.id]
                                ? "transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-5 w-48 rounded-md shadow-lg py-1 bg-white z-10"
                                : "transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute mt-5 w-48 rounded-md shadow-lg py-1 bg-white z-10"
                            }
                          >
                            <button
                              type="button"
                              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                              role="menuitem"
                              onClick={() => mostrarModalDeInfo(u.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 mr-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              Ver Información
                            </button>
                            {u.grupo === "COBRADOR" ? (
                              <button
                                type="button"
                                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                                role="menuitem"
                                onClick={() => mostrarModalDeModificar(u)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 mr-4"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                                Editar
                              </button>
                            ) : null}

                            <button
                              type="button"
                              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                              role="menuitem"
                              onClick={() => mostrarModalDeReset(u)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 mr-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              Reset Contraseña
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
        {mostrarModalAlta ? <AltaUsuario /> : null}
        {mostrarModalReset ? <ModalReset /> : null}
        {mostrarModalModificar ? <ModalModificar /> : null}
        {mostrarModalInfo ? <ModalInfo /> : null}
      </div>
    );
  } else {
    return (
      <div className="p-4">
        <Header />
        <div className="rounded bg-white shadow-xl flex justify-center items-center p-4 m-4 mt-20">
          <Loading color="mainBlueLight" />
        </div>
      </div>
    );
  }
}

export default Listado;
