import React, { useContext, useState } from "react";
import CobrosContext from "../../context/cobros/cobrosContext";
import AlertContext from "../../context/alerts/alertContext";

function BuscadorCobros() {
  const cobrosContext = useContext(CobrosContext);
  const {
    getPersona,
    selectPersona,
    buscadorActivo,
    clearFacturas,
    tipoBusqueda,
    selectPersonaRecibos,
    setShowAlertRecibos,
  } = cobrosContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [busquedaNYAP, setBusquedaNYAP] = useState({
    nyap: "",
  });

  const [busquedaDNIoSocio, setbusquedaDNIoSocio] = useState(0);
  const [selectValue, setSelectValue] = useState("DNI");

  const formBuscarNYAP = (e) => {
    setBusquedaNYAP({
      ...busquedaNYAP,
      [e.target.name]: e.target.value,
    });
  };

  const buscarNYAP = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    if (busquedaNYAP.nyap.trim() === "" || busquedaNYAP.nyap.trim() === null) {
      setAlert("Ingresar nombre y apellido...", "red-300");
      return;
    }
    setShowAlertRecibos(false);
    getPersona(busquedaNYAP);
    setBusquedaNYAP({
      nyap: "",
    });
  };

  const formBuscarDniOsocio = (e) => {
    setbusquedaDNIoSocio(parseInt(e.target.value));
  };

  const buscarDniOsocio = (e) => {
    e.preventDefault();
    if (busquedaDNIoSocio === "" || busquedaDNIoSocio === 0) {
      setAlert("Ingresar un número válido...", "red-300");
      return;
    }
    setShowAlertRecibos(false);
    clearFacturas();
    if (tipoBusqueda === "cobros") {
      selectPersona(busquedaDNIoSocio);
    } else {
      selectPersonaRecibos(busquedaDNIoSocio);
    }
  };

  const getSelectValue = (e) => {
    setSelectValue(e.target.value);
    setbusquedaDNIoSocio(0);
  };

  if (buscadorActivo) {
    return (
      <div className="p-4 bg-mainBlue text-white rounded shadow-lg mb-3">
        <h1 className="text-xl font-bold mb-3">Buscar Persona</h1>

        <select
          id="Currency"
          name="currency"
          className="block w-full rounded text-gray-600 p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          onChange={getSelectValue}
        >
          <option value="DNI">DNI</option>
          <option value="nyap">Nombre y Apellido</option>
          <option value="nrosoc">Número de Socio</option>
        </select>

        {selectValue === "DNI" ? (
          <form onSubmit={buscarDniOsocio}>
            <input
              type="number"
              name="dni"
              className="block w-full rounded text-gray-600 p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="DNI de la persona"
              onChange={formBuscarDniOsocio}
            />
            <button
              type="submit"
              className="bg-mainBlueLight shadow-lg font-medium py-2 text-center px-7 text-white rounded leading-tight text-lg transition duration-500 ease-in-out hover:bg-blue-600"
            >
              Buscar
            </button>
          </form>
        ) : null}

        {selectValue === "nyap" ? (
          <form onSubmit={buscarNYAP}>
            <input
              type="text"
              name="nyap"
              value={busquedaNYAP.nyap}
              className="block w-full rounded text-gray-600 p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              onChange={formBuscarNYAP}
              placeholder="Nombre y Apellido"
            />
            <button
              type="submit"
              className="bg-mainBlueLight shadow-lg font-medium py-2 text-center px-7 text-white rounded leading-tight text-lg transition duration-500 ease-in-out hover:bg-blue-600"
            >
              Buscar
            </button>
          </form>
        ) : null}

        {selectValue === "nrosoc" ? (
          <form onSubmit={buscarDniOsocio}>
            <input
              type="number"
              name="dni"
              className="block w-full rounded text-gray-600 p-2 shadow mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Número de Socio"
              onChange={formBuscarDniOsocio}
            />
            <button
              type="submit"
              className="bg-mainBlueLight shadow-lg font-medium py-2 text-center px-7 text-white rounded leading-tight text-lg transition duration-500 ease-in-out hover:bg-blue-600"
            >
              Buscar
            </button>
          </form>
        ) : null}

        {/* {montoPorCliente.length > 0 && !esPagoParcial ? (
          <div className="my-6">
            <p className="font-bold mb-3">IMPORTE POR CLIENTE</p>
            {montoPorCliente.map((m) => (
              <div className="mb-2 rounded bg-mainBlueLight p-2" key={m.nombre}>
                <p className="font-bold text-sm">{m.nombre}</p>
                <p className="font-light">{formatImporte(m.importe)}</p>
              </div>
            ))}
            <div className="border-b border-white mt-3 mb-2"></div>
            <p className="text-sm uppercase font-light">TOTAL</p>
            <h3 className="text-2xl">{formatImporte(importeTotal)}</h3>
          </div>
        ) : null} */}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default BuscadorCobros;
