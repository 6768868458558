function WidgetHeader({sistema}) {
  return (
    <div className="pattern-bg p-5 rounded-t-lg ">
      <p className="text-white font-bold text-sm mb-3">
        <span className="bg-yellow-500 px-2 rounded">{sistema}</span>
      </p>
      <h3 className="text-white font-medium text-xl mb-2">
        En Frontini y asoc. te escuchamos
      </h3>
      <p className="text-white text-sm">
        Con tu ayuda podemos mejorar tu experiencia de trabajo con nuestras
        herramientas.
      </p>
    </div>
  );
}

export default WidgetHeader;
