import React, { useContext, useState, useEffect } from "react";
import AdminContext from "../../../../context/admin/usuarios/adminContext";
//Pasos
import SelectorTipo from "./SelectorTipo";
import AsociadoA from "./AsociadoA";
import Formulario from "./Formulario";
import Confirmar from "./Confirmar";

function ModalAceptar() {
  const adminContext = useContext(AdminContext);
  const {
    datosUsuario,
    setDatosUsuario,
    dandoDeAlta,
    altaUsuario,
    setMostrarModalAlta,
  } = adminContext;
  const estados = [
    {
      id: 0,
      nombre: "tipo",
      anterior: null,
      siguiente: "asociado_a",
    },
    {
      id: 1,
      nombre: "asociado_a",
      anterior: "tipo",
      siguiente: "formulario",
    },
    {
      id: 2,
      nombre: "formulario",
      anterior: "asociado_a",
      siguiente: "confirmar",
    },
    {
      id: 3,
      nombre: "confirmar",
      anterior: "formulario",
      siguiente: null,
    },
  ];
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [textoAlerta, setTextoAlerta] = useState("");
  const [esPosibleSiguiente, setEsPosibleSiguiente] = useState(false);
  const [estadoActual, setEstadoActual] = useState(estados[0]);

  useEffect(
    () => setEsPosibleSiguiente(verificadorGeneral()),
    [
      datosUsuario.tipoUsuario,
      datosUsuario.asociadoA,
      datosUsuario.username,
      datosUsuario.password,
      datosUsuario.email,
      datosUsuario.canEditDate,
      datosUsuario.descripcion,
      datosUsuario.nombreUsuario,
      estadoActual.nombre,
    ]
  );

  const verificadorTipo = () => {
    if (datosUsuario.tipoUsuario === "") {
      setTextoAlerta("Por favor elegí un tipo de Usuario");
      setMostrarAlerta(true);
      return false;
    } else {
      return true;
    }
  };

  const verificadorAsociadoA = () => {
    if (datosUsuario.asociadoA === "") {
      var asociado_a =
        datosUsuario.setDatosUsuario === "COBRADOR"
          ? "Punto de Cobro"
          : "Empresa";
      setTextoAlerta(`Por favor, elegí a cuál ${asociado_a} está asociado`);
      setMostrarAlerta(true);
      return false;
    } else {
      return true;
    }
  };

  const verificadorFormulario = () => {
    var EsPosibleSiguiente = true;
    if (datosUsuario.tipoUsuario !== "EMPRESA") {
      var datosAChequear = [
        "username",
        "password",
        "email",
        "canEditDate",
        "descripcion",
        "nombreUsuario",
      ];
      datosAChequear.forEach((dato) => {
        if (datosUsuario[dato] === "") {
          EsPosibleSiguiente = false;
        }
      });
      return EsPosibleSiguiente;
    } else {
      console.log("chequear datos de empresa");
      return true;
    }
  };

  const verificadorGeneral = () => {
    var esPosibleSiguiente = false;
    switch (estadoActual.nombre) {
      case "tipo":
        esPosibleSiguiente = verificadorTipo();
        break;
      case "asociado_a":
        esPosibleSiguiente = verificadorAsociadoA();
        break;
      case "formulario":
        esPosibleSiguiente = verificadorFormulario();
        break;
      default:
        esPosibleSiguiente = true;
        break;
    }
    return esPosibleSiguiente;
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-end p-2 pb-0">
            <div className="bg-red-500 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-white m-1 cursor-pointer"
                onClick={() => {
                  setDatosUsuario({
                    tipoUsuario: "",
                    asociadoA: "",
                    asociadoANombre: "",
                    //formulario
                    username: "",
                    password: "",
                    email: "",
                    canEditDate: "1",
                    descripcion: "",
                  });
                  setMostrarModalAlta(false);
                }}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div className="w-full pt-0 pb-2 border border-gray-400 border-b-2 border-t-0 border-r-0 border-l-0">
            <div className="flex">
              <div className="w-1/4">
                <div className="relative mb-2">
                  <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                    <span className="text-center text-white w-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-full"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="text-xs text-center md:text-base">Tipo</div>
              </div>

              <div className="w-1/4">
                <div className="relative mb-2">
                  <div
                    className="absolute flex align-center items-center align-middle content-center"
                    style={{
                      width: "calc(100% - 2.5rem - 1rem)",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                      <div
                        className={
                          estadoActual.nombre !== "tipo"
                            ? "w-full bg-green-300 py-1 rounded"
                            : "w-0 bg-green-300 py-1 rounded"
                        }
                      ></div>
                    </div>
                  </div>

                  <div
                    className={
                      estadoActual.nombre !== "tipo"
                        ? "w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center"
                        : "w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                    }
                  >
                    <span
                      className={
                        estadoActual.nombre !== "tipo"
                          ? "text-center text-white w-full"
                          : "text-center text-black w-full"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-full"
                        fill="none"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="text-xs text-center md:text-base">
                  Asociado a
                </div>
              </div>

              <div className="w-1/4">
                <div className="relative mb-2">
                  <div
                    className="absolute flex align-center items-center align-middle content-center"
                    style={{
                      width: "calc(100% - 2.5rem - 1rem)",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                      <div
                        className={
                          estadoActual.nombre === "formulario" ||
                          estadoActual.nombre === "confirmar"
                            ? "w-full bg-green-300 py-1 rounded"
                            : "w-0 bg-green-300 py-1 rounded"
                        }
                      ></div>
                    </div>
                  </div>

                  <div
                    className={
                      estadoActual.nombre === "formulario" ||
                      estadoActual.nombre === "confirmar"
                        ? "w-10 h-10 mx-auto bg-green-500 border-2 border-green-500 rounded-full text-lg text-white flex items-center"
                        : "w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                    }
                  >
                    <span
                      className={
                        estadoActual.nombre === "formulario" ||
                        estadoActual.nombre === "confirmar"
                          ? "text-center text-white w-full"
                          : "text-center text-gray-600 w-full"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-full"
                        fill="none"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="text-xs text-center md:text-base">
                  Formulario
                </div>
              </div>

              <div className="w-1/4">
                <div className="relative mb-2">
                  <div
                    className="absolute flex align-center items-center align-middle content-center"
                    style={{
                      width: "calc(100% - 2.5rem - 1rem)",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                      <div
                        className={
                          estadoActual.nombre === "confirmar"
                            ? "w-full bg-green-300 py-1 rounded"
                            : "w-0 bg-green-300 py-1 rounded"
                        }
                      ></div>
                    </div>
                  </div>

                  <div
                    className={
                      estadoActual.nombre === "confirmar"
                        ? "w-10 h-10 mx-auto bg-green-500 border-2 border-green-500 rounded-full text-lg text-white flex items-center"
                        : "w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                    }
                  >
                    <span
                      className={
                        estadoActual.nombre === "confirmar"
                          ? "text-center text-white w-full"
                          : "text-center text-gray-600 w-full"
                      }
                    >
                      <svg
                        className="w-full fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path
                          className="heroicon-ui"
                          d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="text-xs text-center md:text-base">
                  Confirmar
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
            {estadoActual.nombre === "tipo" ? <SelectorTipo /> : null}
            {estadoActual.nombre === "asociado_a" ? <AsociadoA /> : null}
            {estadoActual.nombre === "formulario" ? <Formulario /> : null}
            {estadoActual.nombre === "confirmar" ? <Confirmar /> : null}
          </div>
          <div className="bg-gray-50 flex justify-between p-4">
            <button
              className={
                estadoActual.anterior === null
                  ? "px-6 py-1 text-white bg-gray-500 rounded flex items-center lg:m-0 cursor-not-allowed"
                  : "px-7 py-1 text-white bg-mainBlueLight rounded flex items-center lg:m-0"
              }
              //onClick={() => aceptarArchivo()}
              onClick={() => setEstadoActual(estados[estadoActual.id - 1])}
              disabled={estadoActual.anterior === null}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>{" "}
              Atrás
              {/* Aceptando Archivo */}
            </button>
            <button
              type="button"
              className={
                !esPosibleSiguiente
                  ? "px-4 py-1 text-white bg-gray-500 rounded flex items-center lg:mb-0 cursor-not-allowed"
                  : estadoActual.nombre !== "confirmar"
                  ? "px-4 py-1 text-white bg-mainBlueLight rounded flex items-center lg:mb-0"
                  : dandoDeAlta
                  ? "px-4 py-1 font-bold text-white bg-gray-500 rounded flex items-center lg:mb-0"
                  : "px-4 py-1 font-bold text-white bg-green-400 rounded flex items-center lg:mb-0"
              }
              onClick={() => {
                if (estadoActual.nombre !== "confirmar") {
                  setEstadoActual(estados[estadoActual.id + 1]);
                } else {
                  altaUsuario();
                }
              }}
              disabled={!esPosibleSiguiente || dandoDeAlta}
            >
              {estadoActual.nombre !== "confirmar"
                ? "Siguiente"
                : dandoDeAlta
                ? "Confirmando"
                : "Confirmar"}
              {estadoActual.nombre !== "confirmar" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : dandoDeAlta ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-3 animate-spin"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAceptar;
