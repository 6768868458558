import React, { useContext, useEffect, useState } from "react";
import DepositosContext from "../../context/depositos/depositosContext";
import CobrosContext from "../../context/cobros/cobrosContext";

import { useHistory } from "react-router-dom";

function SeleccionadosDepositar() {
  const cobrosContext = useContext(CobrosContext);
  const { formatDate, formatImporte } = cobrosContext;

  let history = useHistory();

  const depositosContext = useContext(DepositosContext);
  const {
    seleccionadosDepositar,
    eliminarCierre,
    filtrarCierresEliminados,
    previosDepositar,
    imprimirPreviaDeposito,
    setModalDeposito,
    modalDeposito,
    setDataModalDeposito,
    generarDepositoPrevio,
  } = depositosContext;

  useEffect(() => {
    filtrarCierresEliminados();
  }, [seleccionadosDepositar]);

  const abrirModalDeposito = (data) => {
    setDataModalDeposito(data);
    setModalDeposito(!modalDeposito);
  };

  const [loadingInformar, setLoadingInformar] = useState(false);

  return (
    <div className="p-4 bg-mainBlue rounded shadow-lg mb-3">
      <div>
        <h1 className="text-xl font-bold text-white">Informar Depósito</h1>
        <p className="text-sm text-gray-400 mb-8">
          Cierres de caja seleccionados para informar depósitos a las empresas.
        </p>
      </div>
      {seleccionadosDepositar.map((cierre) => (
        <div className="shadow-xl" key={cierre.id}>
          <div className="bg-gray-200 p-4 rounded-t flex items-center justify-between">
            <div>
              <p className="mb-1 font-bold text-gray-800">Cobrador</p>
              <p className="text-xs font-bold text-white">
                <span className="bg-mainBlueLight rounded px-4 py-1 mr-1">
                  {cierre.cobrador.nombre}
                </span>
              </p>
            </div>
            <div>
              <button
                onClick={() => eliminarCierre(cierre)}
                className="bg-red-500 rounded-lg p-2 font-bold text-sm text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="bg-white rounded-b p-4 mb-3" key={cierre.id}>
            <div className="lg:grid lg:grid-cols-5 lg:gap-4">
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">FECHA</p>
                <p className="text-sm text-gray-500">
                  {formatDate(cierre.fecha)}{" "}
                  <span className="text-xs font-medium text-gray-800">
                    {cierre.hora}hs
                  </span>
                </p>
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                <p className="text-sm text-gray-500">
                  {formatImporte(cierre.importe)}
                </p>
              </div>
              <div className="lg:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">ID CIERRE</p>
                <p className="text-sm text-gray-500">{cierre.id}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
      {previosDepositar.map((p, index) => (
        <div
          key={index}
          className="bg-mainBlueLight rounded p-4 shadow-xl text-white mb-3"
        >
          <div className="lg:grid lg:grid-cols-5 lg:gap-4">
            <div className="lg:border-r lg:border-white lg:m-0 mb-2">
              <p className="text-sm font-bold">EMPRESA</p>
              <p className="text-sm">{p.empresa}</p>
            </div>
            <div className="lg:border-r lg:border-white lg:m-0 mb-2">
              <p className="text-sm font-bold">TOTAL</p>
              <p className="text-sm">{formatImporte(p.total)}</p>
            </div>
            <div className="lg:border-r lg:border-white lg:m-0 mb-2">
              <p className="text-sm font-bold">COMISION</p>
              <p className="text-sm">{formatImporte(p.comision)}</p>
            </div>
            <div className="lg:border-r lg:border-white lg:m-0 mb-2">
              <p className="text-sm font-bold">A DEPOSITAR</p>
              <p className="text-sm">{formatImporte(p.depositar)}</p>
            </div>
            <div className="lg:flex lg:items-center lg:justify-end lg:m-0 mb-2 ">
              <button
                className={
                  loadingInformar
                    ? "px-4 py-1 font-bold text-white bg-gray-400 rounded flex items-center cursor-not-allowed"
                    : "px-4 py-1 font-bold text-white bg-green-400 rounded flex items-center"
                }
                onClick={() => {
                  setLoadingInformar(true);
                  generarDepositoPrevio(p).then(() => {
                    setLoadingInformar(false);
                    window.scrollTo(0, 0);
                    imprimirPreviaDeposito();
                    history.push("/depositos");
                  });
                }}
                disabled={loadingInformar}
              >
                {loadingInformar ? (
                  <svg
                    className="animate-spin mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 mr-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                Informar Depósito
              </button>
            </div>
          </div>
        </div>
      ))}
      {/*       <div className="lg:flex lg:items-center mt-6">
        <button
          className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center mr-2 mb-3"
          onClick={() => imprimirPreviaDeposito()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5 mr-3"
          >
            <path
              fillRule="evenodd"
              d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
              clipRule="evenodd"
            />
          </svg>
          Imprimir Previa
        </button>
      </div> */}
    </div>
  );
}

export default SeleccionadosDepositar;
