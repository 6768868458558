import {
  SELECCIONAR_DEPOSITO,
  DEPOSITOS_SELECCIONADOS,
  DEPOSITOS_PUNTO,
  TOTAL_CIERRES,
  RESET_STATE,
} from "../../types/index.js";

export default (state, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        cierresPunto: [],
        cierreSeleccionado: {},
        seleccionadosDepositar: [],
        totalCierresPunto: [],
      };
    case DEPOSITOS_PUNTO:
      return {
        ...state,
        cierresPunto: action.payload,
      };
    case SELECCIONAR_DEPOSITO:
      return {
        ...state,
        cierreSeleccionado: action.payload,
      };
    case DEPOSITOS_SELECCIONADOS:
      return {
        ...state,
        seleccionadosDepositar: action.payload,
      };
    case TOTAL_CIERRES:
      return {
        ...state,
        totalCierresPunto: action.payload,
      };
    default:
      return state;
  }
};
