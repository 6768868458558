import React, { useReducer, useContext, useEffect, useState } from "react";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";

import depositosContext from "./depositosContext";
import depositosReducer from "./depositosReducer";

import CobrosContext from "../cobros/cobrosContext";
import AuthContext from "../auth/authContext";
import AlertContext from "../alerts/alertContext";

import axiosClient from "../../config/axios";

import jsPDF from "jspdf";

import { useHistory } from "react-router-dom";

import {
  SELECCIONAR_DEPOSITO,
  DEPOSITOS_SELECCIONADOS,
  DEPOSITOS_PUNTO,
  TOTAL_CIERRES,
  RESET_STATE,
} from "../../types/index.js";

const DepositosState = (props) => {
  const authContext = useContext(AuthContext);
  const { name, userID, fecha, hora, header } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const { formatImporte, formatDate } = cobrosContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const initialState = {
    cierresPunto: [],
    cierreSeleccionado: {},
    seleccionadosDepositar: [],
    totalCierresPunto: [],
  };

  const [state, dispatch] = useReducer(depositosReducer, initialState);

  const [loadingState, setLoadingState] = useState(false);
  const [previosDepositar, setPreviosDepositar] = useState([]);
  const [modalDeposito, setModalDeposito] = useState(false);
  const [modalAltaDeposito, setModalAltaDeposito] = useState(false);
  const [dataAltaModalDeposito, setDataAltaModalDeposito] = useState(0);
  const [dataModalDeposito, setDataModalDeposito] = useState({});
  const [modalVerRecibos, setModalVerRecibos] = useState(false);
  const [modalVerImagen, setModalVerImagen] = useState(false);
  const [dataImagen, setDataImagen] = useState([]);
  const [dataRecibos, setDataRecibos] = useState([]);
  const [idDeposito, setIdDeposito] = useState(0);
  const [idsDeposito, setIdsDeposito] = useState([]);

  //state para la subida de imagenes y deposito.
  const [inputs, setInputs] = useState([{ title: "", file: "" }]);
  const [imageError, setImageError] = useState(false);
  const [depositoObservaciones, setDepositoObservaciones] = useState("");
  const [loadingSubida, setLoadingSubida] = useState(false);
  const [montoDepositar, setMontoDepositar] = useState(null);
  const [nroComprobante, setNroComprobante] = useState("");
  const [awsResponse, setAwsResponse] = useState(false);
  const [confirmarSubida, setConfirmarSubida] = useState(true);

  //array de nombres de las imagenes para el back.
  const [imagesNames, setImagesNames] = useState([]);

  useEffect(() => {
    getDepositosID();
    filtrarCierresEliminados();
  }, [state.seleccionadosDepositar]);

  /*   useEffect(() => {
    if (awsResponse) {
      altaDeposito();
    }
  }, [awsResponse]); */

  //Limpia el state inicial cuando se cambia de componente.
  const resetState = () => {
    dispatch({
      type: RESET_STATE,
    });
  };

  //Filtra los cierres que se eliminan de la selección a depositar.
  //Esta funcion se ejecuta en el componente "Seleccionados Depositar"
  const filtrarCierresEliminados = () => {
    if (state.totalCierresPunto.length > 0) {
      let filtrar = state.totalCierresPunto.filter(
        (item) =>
          !state.seleccionadosDepositar.some((other) => item.id === other.id)
      );
      dispatch({
        type: DEPOSITOS_PUNTO,
        payload: filtrar,
      });
    }
  };

  //Filtra de la lista los cierres que se seleccionan para depositar.
  const filtrarCierres = () => {
    if (state.cierresPunto.length > 0) {
      let filtrar = state.cierresPunto.filter(
        (f) => f.id !== state.cierreSeleccionado.id
      );
      dispatch({
        type: DEPOSITOS_PUNTO,
        payload: filtrar,
      });
      dispatch({
        type: DEPOSITOS_SELECCIONADOS,
        payload: [...state.seleccionadosDepositar, state.cierreSeleccionado],
      });
    }
  };

  //Eliminar Facturas seleccionadas
  const eliminarCierresSeleccionados = () => {
    dispatch({
      type: DEPOSITOS_SELECCIONADOS,
      payload: [],
    });
  };

  //Eliminar Factura seleccionada especifica
  const eliminarCierre = (deposito) => {
    let filtrar = state.seleccionadosDepositar.filter(
      (f) => f.id !== deposito.id
    );
    dispatch({
      type: DEPOSITOS_SELECCIONADOS,
      payload: filtrar,
    });
  };

  //Obtengo los datos los cierres de caja disponibles para depositar
  const getCierresCaja = async () => {
    setLoadingState(true);
    const data = {
      cobrador: userID,
    };
    await axiosClient
      .post(`v2/rendiciones_rendidas`, data, header)
      .then((response) => {
        setLoadingState(false);
        dispatch({
          type: DEPOSITOS_PUNTO,
          payload: response.data.data,
        });
        dispatch({
          type: TOTAL_CIERRES,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        setLoadingState(false);
      });
  };

  //Agrega la deposito seleccionado al state y calcula el importe.
  const addDeposito = (data) => {
    dispatch({
      type: SELECCIONAR_DEPOSITO,
      payload: data,
    });
  };

  //imprime la rendición con todos los recibos.
  const imprimirPreviaDeposito = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [190, 700],
    });

    doc.addFont("courier", "courier", "normal");

    //Debug para saber las fuentes disponibles en el sistema.
    //console.log(doc.getFontList());

    doc.setFont("courier", "bold");
    doc.setFontSize(12);
    doc.text(2, 5, "Cardinal Puntos de Pago");

    doc.setFont("courier", "italic");
    doc.setFontSize(9);
    doc.text(2, 10, "Vista Previa del Depósito");

    doc.setFontSize(9);
    doc.setFont("courier", "normal");
    doc.text(2, 20, "Cierres: " + idsDeposito);
    doc.setFont("courier", "bold");
    doc.text(2, 25, "Rendiciones a Depositar:");

    if (previosDepositar.length > 0) {
      var linea = 35;
      for (var i = 0; i < previosDepositar.length; i++) {
        doc.setFont("courier", "bold");
        doc.setFontSize(9);
        doc.text(2, linea, previosDepositar[i].empresa);

        doc.setFont("courier", "normal");
        doc.setFontSize(9);
        doc.text(
          2,
          linea + 5,
          "Total: .................." + formatImporte(previosDepositar[i].total)
        );
        doc.text(
          2,
          linea + 10,
          "Comision: ..............." +
            formatImporte(previosDepositar[i].comision)
        );
        doc.setFont("courier", "bold");
        doc.text(
          2,
          linea + 15,
          "TOTAL DEPOSITAR: ........" +
            formatImporte(previosDepositar[i].depositar)
        );
        linea += 25;
      }
    }

    doc.autoPrint();
    doc.output("dataurlnewwindow", {
      filename: `Rendiciones-a-Depositar.pdf`,
    });
  };

  const getDepositosID = async () => {
    //agrupo los ID de los seleccionados en un array
    var ids = [];
    for (var i = 0; i < state.seleccionadosDepositar.length; ++i) {
      ids.push(state.seleccionadosDepositar[i]["id"]);
    }
    setIdsDeposito(ids);
    //hago la consulta a la api cada vez que se selecciona uno

    if (ids.length > 0) {
      await axiosClient
        .post(
          `montosADepositar`,
          {
            rendiciones: ids,
          },
          header
        )
        .then((response) => {
          setPreviosDepositar(response.data.data);
        })
        .catch((err) => {});
    }
  };

  //Tomo los nombres de las imagenes y los agrupo en un array
  //para enviar al backend.
  const getImagesName = () => {
    //agrupo los ID de los seleccionados en un array
    var nombres = [];
    for (var i = 0; i < inputs.length; ++i) {
      if (inputs[i].file) {
        nombres.push(
          name + "-" + inputs[i].title + "." + inputs[i].file.name.split(".")[1]
        );
      }
    }
    setImagesNames(nombres);
  };

  //AWS S3 Config
  Storage.configure({
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET, //REQUIRED -  Amazon S3 bucket
      region: process.env.REACT_APP_AWS_S3_REGION, //OPTIONAL -  Amazon service region
    },
  });

  Auth.configure({
    region: process.env.REACT_APP_AWS_S3_REGION, // REQUIRED - Amazon Cognito Region
    identityPoolId: process.env.REACT_APP_AWS_S3_POOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    userPoolId: "", //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: "", //OPTIONAL - Amazon Cognito Web Client ID
  });

  //Generar el depósito y ejecutar la subida de imágenes.
  const generarDeposito = () => {
    subirComprobantesS3();
  };

  // Genera el deposito sin NRO COMPROBANTE, IMAGENES u OBSERVACIONES
  // Lo crea con estado PROVISIORIO
  const generarDepositoPrevio = async (data) => {
    await axiosClient
      .post(
        "v2/alta_deposito_provisorio",
        {
          empresa: data.empresa,
          recibos: data.recibos,
          total: data.total,
          depositar: data.depositar,
          comision: data.comision,
          encargado: userID,
          fecha: fecha,
        },
        header
      )
      .then((response) => {
        setMontoDepositar(0);
        setNroComprobante("");
        resetState();
      })
      .catch((err) => {
        setMontoDepositar(0);
        setNroComprobante("");
      });
  };

  const altaDeposito = async () => {
    setConfirmarSubida(false);
    await axiosClient
      .post(
        `v2/enviar_deposito`,
        {
          idDeposito: dataModalDeposito.id,
          nroComprobante: nroComprobante,
          observaciones: depositoObservaciones,
          imagenes: imagesNames,
        },
        header
      )
      .then((response) => {
        setLoadingSubida(false);
        setModalDeposito(false);
        setAlert("Depósito cargado correctamente", "green-300");
        setMontoDepositar(0);
        setNroComprobante("");
        setDepositoObservaciones("");
        setConfirmarSubida(true);
        setAwsResponse(false);
        resetState();
      })
      .catch((err) => {
        setLoadingSubida(false);
        setDepositoObservaciones("");
        setMontoDepositar(0);
        setNroComprobante("");
        setModalDeposito(false);
        if (err.data !== undefined) {
          setAlert("Error: " + err.data, "red-300");
        } else {
          setAlert(
            "No se pudo enviar el depósito, por favor intentá más tarde",
            "red-300"
          );
        }
        setAwsResponse(false);
        resetState();
      });
  };

  const subirImagenAS3 = async (input) => {
    
    await Storage.put(
      name + "-" + input.title + "." + input.file.name.split(".")[1],
      input.file,
      {
        contentType: input.file.type,
        acl: "public-read",
        customPrefix: {
          public: "depositos/",
        },
      }
    ) 
      .then((result) => {
        setAwsResponse(true);
      })
      .catch((err) => {
        //setLoadingSubida(false);
        setAlert(
          "Hubo un error cargando el depósito, intente mas tarde",
          "red-300"
        );
        setAwsResponse(false);
      });
  };

  //sube todas las imágenes seleccionadas a AWS S3
  const subirComprobantesS3 = async () => {
    
    setLoadingSubida(true);
    Promise.all(
      inputs.map(async (input) => {
        await subirImagenAS3(input);
      })
    ).then(() => altaDeposito());
  };

  return (
    <depositosContext.Provider
      value={{
        loadingState,
        resetState,
        seleccionadosDepositar: state.seleccionadosDepositar,
        cierresPunto: state.cierresPunto,
        cierreSeleccionado: state.cierreSeleccionado,
        addDeposito,
        getCierresCaja,
        eliminarCierre,
        filtrarCierres,
        filtrarCierresEliminados,
        eliminarCierresSeleccionados,
        previosDepositar: previosDepositar,
        imprimirPreviaDeposito,
        modalDeposito,
        setModalDeposito,
        dataModalDeposito,
        setDataModalDeposito,
        modalVerRecibos,
        setModalVerRecibos,
        dataRecibos,
        setDataRecibos,
        idDeposito,
        setIdDeposito,
        dataImagen,
        setDataImagen,
        modalVerImagen,
        setModalVerImagen,
        inputs: inputs,
        setInputs,
        imageError: imageError,
        setImageError,
        depositoObservaciones: depositoObservaciones,
        setDepositoObservaciones,
        getImagesName,
        generarDeposito,
        loadingSubida,
        montoDepositar,
        setMontoDepositar,
        setNroComprobante,
        nroComprobante,
        setModalAltaDeposito,
        modalAltaDeposito,
        setDataAltaModalDeposito,
        dataAltaModalDeposito,
        generarDepositoPrevio,
        confirmarSubida,
      }}
    >
      {props.children}
    </depositosContext.Provider>
  );
};

export default DepositosState;
