import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";

import axiosClient from "../../config/axios";

import Header from "../Header";
import RendicionesDiarias from "./RendicionesDiarias";
import Loading from "../Loading";

function CierreCaja() {
  const authContext = useContext(AuthContext);
  const { getUser, userID, header, fecha } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const {
    formatDate,
    formatImporte,
    resetState,
    consultarImprimirCierreDetalle,
    consultarImprimirCierre,
  } = cobrosContext;

  const [homeData, setHomeData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      getHomeData();
      resetState();
    }
    return () => {
      mounted = false;
    };
  }, []);

  //obtengo los datos de la página principal
  const getHomeData = async () => {
    setLoading(true);
    const data = {
      cobrador: userID,
      cantidad: 10,
      fecha: fecha,
    };
    await axiosClient
      .post(`v2/rendiciones_rendidas`, data, header)
      .then((response) => {
        setHomeData(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="p-4">
      <Header />
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 bg-white shadow-lg rounded">
          <div className="lg:flex lg:items-start lg:justify-between lg:mb-0 mb-4">
            <div>
              <h1 className="text-xl font-bold text-gray-800">
                Cierres de Caja realizados
              </h1>
              <p className="text-sm text-gray-500 mb-8">
                Listado de los últimos cinco cierres de caja realizados.
              </p>
            </div>
            <div className="mb-5 mt-0 lg:mb-0 lg:mt-2">
              <Link
                to={"/generar-cierre"}
                className="bg-mainBlueLight rounded py-1 px-6 font-bold text-white"
              >
                Generar Cierre
              </Link>
            </div>
          </div>
          {loading ? <Loading color="mainBlueLight" /> : null}
          {homeData && homeData.length > 0
            ? homeData.map((i) => (
                <div
                  key={i.id}
                  className="border rounded p-4 mb-3 lg:grid lg:grid-cols-4 lg:gap-4"
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">COBRADOR</p>
                    <p className="text-sm text-gray-500">{i.cobrador.nombre}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">FECHA</p>
                    <p className="text-sm text-gray-500">
                      {formatDate(i.fecha)}{" "}
                      <span className="text-xs font-medium text-gray-800">
                        {i.hora}hs
                      </span>
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">IMPORTE</p>
                    <p className="text-sm text-gray-500">
                      {formatImporte(i.importe)}
                    </p>
                  </div>

                  <div className="lg:flex items-center lg:justify-end">
                    <button
                      className="px-4 py-1 font-bold text-white bg-green-400 rounded flex items-center lg:m-0 mt-5"
                      onClick={() => consultarImprimirCierreDetalle(i.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5 mr-3"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Imprimir Detalle
                    </button>
                    <button
                      className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center lg:m-0 mt-5 lg:ml-2"
                      onClick={() => consultarImprimirCierre(i.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5 mr-3"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Imprimir
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <RendicionesDiarias />
    </div>
  );
}

export default CierreCaja;
