import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './App';

/*
   * Agregar estilos si los borra el PurgeCSS en el build:
   * bg-red-300
   * bg-yellow-300
   * bg-green-300
   * bg-yellow-400
   * bg-purple-400
   * bg-green-400
   * bg-mainBlueLight
   * text-mainBlueLight
   * text-purple-700
*/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

