import React, { useContext } from "react";
import AuthContext from "../context/auth/authContext";

function Home() {
  const authContext = useContext(AuthContext);
  const { name } = authContext;

  let month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ];
  let days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado"
  ];

  let date = new Date();
  let dateResult = days[date.getDay()] + " " + date.getDate() + " de " + month[date.getMonth()] + ", " + date.getFullYear();

  return (
    <div className="pb-6 px-4">
      <h1 className="text-xl lg:text-xl text-gray-800 font-bold">Hola, <span className="font-light">{name}</span></h1>
      <p className="text-gray-500 font-medium">{dateResult}</p>
    </div>
  );
}

export default Home;
